import React from "react";

function SendIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill="#fff"
        d="M16 8.286l.354-.354L16 7.58l-.353.353.353.354zm-.5 15.428a.5.5 0 101 0h-1zm5.997-10.64l-5.143-5.142-.707.707 5.143 5.143.707-.707zm-5.85-5.142l-5.143 5.143.707.707 5.143-5.143-.707-.707zm-.147.354v15.428h1V8.286h-1z"
      ></path>
    </svg>
  );
}

export default SendIcon;
