import React from "react";

function ImageIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="38"
      fill="none"
      viewBox="0 0 39 38"
    >
      <path
        fill="#65677D"
        fillOpacity="0.9"
        fillRule="evenodd"
        d="M4.838 4.338C3.667 5.51 3.667 7.395 3.667 11.167v15.666c0 3.772 0 5.657 1.171 6.829 1.172 1.171 3.057 1.171 6.829 1.171h15.666c3.772 0 5.657 0 6.829-1.171 1.171-1.172 1.171-3.057 1.171-6.829V11.167c0-3.31 0-5.167-.791-6.364v22.114c-.707 0-1.06 0-1.396-.058a4 4 0 01-1.63-.676c-.28-.197-.53-.446-1.029-.946l-1.825-1.825c-1.334-1.334-2-2-2.829-2-.828 0-1.495.666-2.828 2l-1.416 1.416c-.947.946-1.42 1.42-1.94 1.32-.522-.101-.785-.716-1.313-1.947l-1.259-2.937c-1.054-2.46-1.581-3.69-2.623-3.892-1.043-.2-1.989.746-3.882 2.64l-4.53 4.53V3.595c-.478.17-.87.41-1.204.743z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#65677D"
        strokeOpacity="0.9"
        strokeWidth="2"
        d="M4.667 11.167c0-1.914.002-3.249.137-4.256.132-.978.372-1.496.741-1.865.37-.37.887-.61 1.866-.741 1.007-.136 2.342-.138 4.256-.138h15.666c1.914 0 3.249.002 4.256.138.979.131 1.497.372 1.866.74.369.37.61.888.74 1.866.136 1.007.138 2.342.138 4.256v15.667c0 1.914-.002 3.248-.137 4.256-.132.978-.372 1.496-.741 1.865-.37.369-.887.61-1.866.741-1.007.136-2.342.138-4.256.138H11.667c-1.914 0-3.249-.003-4.256-.138-.979-.131-1.497-.372-1.866-.741s-.61-.887-.74-1.865c-.136-1.008-.138-2.343-.138-4.256V11.167z"
      ></path>
      <circle
        cx="24.25"
        cy="14.25"
        r="3.167"
        fill="#65677D"
        fillOpacity="0.9"
      ></circle>
    </svg>
  );
}

export default ImageIcon;
