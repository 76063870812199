import React from "react";

function Arrow1Icon({height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="166"
      height={height||"117"}
      fill="none"
      viewBox="0 0 166 117"
    >
      <path
        fill="#432C5B"
        d="M163.08 115.499a2.5 2.5 0 002.419-2.579l-.716-22.488a2.5 2.5 0 10-4.998.159l.637 19.99-19.99.636a2.5 2.5 0 10.159 4.998l22.489-.716zM.931 5.26C69.216 37.553 104.22 61.274 161.29 114.823l3.422-3.646C107.266 57.277 71.831 33.258 3.069.74L.93 5.26z"
      ></path>
    </svg>
  );
}

export default Arrow1Icon;