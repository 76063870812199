import React from 'react';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';

const MapDisplay = ({ height, width, latitude, longitude,borderRadius}) => {
  const defaultLatitude = 59.365232174631686;
  const defaultLongitude = 18.002397276475886;

  const mapLatitude = latitude || defaultLatitude;
  const mapLongitude = longitude || defaultLongitude;
const position = {lat:mapLatitude, lng:mapLongitude}
  return (
    <APIProvider apiKey='AIzaSyAY_acUQDuW7rIT5I8v1jPksNvZm4kITLY'>
      <div style={{ height, width,borderRadius }}>
        <Map
          width='100%'
          height='100%'
          center={{ lat: mapLatitude, lng: mapLongitude }}
          zoom={14}
        >
     <Marker
          position={position}
          />
        </Map>
      </div>
    </APIProvider>
  );
};

export default MapDisplay;
