import { useEffect, useState } from 'react'
import TextTransition, { presets } from 'react-text-transition'
import style from './AboutChimp.module.css'

const TEXTS2 = ['Growth?', 'Opportunities?', 'Insights?']

const AlternatingTwo = () => {
  const [index2, setIndex2] = useState(0)

  useEffect(() => {
    const intervalId2 = setInterval(() => setIndex2((index2) => index2 +1), 4000) // every 2 seconds
    return () => clearInterval(intervalId2)
  }, [])

  return (
    <span className={style.highlight}>
      <TextTransition springConfig={presets.gentle}>
        {TEXTS2[index2 % TEXTS2.length]}
      </TextTransition>
    </span>
  )
}

export default AlternatingTwo
