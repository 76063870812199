import ArrowUpIcon from '../../../../assets/svgs/ArrowUp'
import CheckMark2Icon from '../../../../assets/svgs/CheckMark2'
import ErrorIcon from '../../../../assets/svgs/Error'
import PlusCircleIcon from '../../../../assets/svgs/PlusCircle'
import style from './NewEvent.module.css'

const EventTitle = ({
  openEventTitle,
  errors,
  handleFormData,
  formData,
  setOpenEventTitle
}) => {
  const toggleEventTitle = () => {
    setOpenEventTitle(!openEventTitle)
  }
  return (
    <div
      className={`${style.eventTitleContainer} ${
        openEventTitle ? style.expanded : style.closed
      }`}
    >
      {openEventTitle ? (
        <>
          <p className={style.openTitle}>Event Overview</p>
          <p className={style.text}>Event title</p>

          <p className={style.subtext}>
            Be clear and descriptive with a title that tells people what your
            event is about.
          </p>

          <div style={{ position: 'relative' ,width:'93%'}}>
            {errors.title && openEventTitle && (
              <div className={style.errorContainer}>
                <ErrorIcon />
              </div>
            )}
            <input
              onChange={handleFormData}
              value={formData.title}
              name="title"
              placeholder="Event title"
              className={`${style.input} ${errors.title ? style.error : null}`}
            />
          </div>

          <p className={style.text}>Summary</p>
          <p className={style.subtext}>
            Grab people’s attention with a short description about your event.
            Attendees will see this at the top of your event page. (140
            characters max)
          </p>
          <div style={{ position: 'relative',width:'93%' }}>
            {errors.summary && openEventTitle && (
              <div className={style.errorContainer}>
                <ErrorIcon />
              </div>
            )}
            <input
              onChange={handleFormData}
              value={formData.summary}
              name="summary"
              maxLength={140}
              placeholder="Summary"
              className={`${style.input} ${errors.title ? style.error : null}`}
            />{' '}
          </div>
          <p className={style.chars}>{formData.summary.length}/140</p>
        </>
      ) : (
        <>
          <p className={style.title}>
            {formData.title === '' ? 'Event title' : formData.title}
          </p>
          <p className={style.subtext}>
            {formData.summary === ''
              ? 'A short and sweet sentence about your event.'
              : formData.summary}
          </p>
        </>
      )}

      <div
        className={`${style.plus} ${
          formData.title !== '' && formData.summary !== '' && !openEventTitle
            ? style.green
            : null
        }`}
        onClick={toggleEventTitle}
      >
        {openEventTitle ? (
          <ArrowUpIcon color={'black'} stroke={'2'} />
        ) : formData.title !== '' &&
          formData.summary !== '' &&
          !openEventTitle ? (
          <CheckMark2Icon />
        ) : (
          <PlusCircleIcon />
        )}
      </div>
    </div>
  )
}
export default EventTitle
