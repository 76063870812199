import { createContext, useContext, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAuthContext } from './AuthContext';

// Create the HistoryContext to hold history-related state and functions
const HistoryContext = createContext();

// Custom hook to use the HistoryContext
export const useHistoryContext = () => useContext(HistoryContext);

// HistoryProvider component to provide the HistoryContext to its children
export const HistoryProvider = ({ children }) => {
  // State to hold the history of requests and responses
  const [history, setHistory] = useState([]);
  // State to hold the current session ID
  const [sessionId, setSessionId] = useState('');
  // Get authentication status and cookie getter from AuthContext
  const { isAuthenticated, getCookie } = useAuthContext();

  // Set a unique session ID when the component mounts
  useEffect(() => {
    setSessionId(uuidv4());
  }, []);

  // URL for the API endpoint, defaulting to an empty string if not defined
  const url = process.env.REACT_APP_API_URL || '';

  // Function to add a request to the history
  const addRequest = (message, item) => {
    const newEntry = { type: 'request', content: message, sessionId };
    const oldEntry = { type: 'request', content: message, sessionId };

    // If there's no existing item, add the new entry to the history
    if (!item) {
      setHistory((prevHistory) => [...prevHistory, newEntry]);
    } else {
      // If there is an existing item, add the old entry and update session ID
      setHistory((prevHistory) => [...prevHistory, oldEntry]);
      setSessionId(item.session_id);
    }

    // If the user is authenticated and there's no existing item, save to database
    if (isAuthenticated && !item) {
      saveHistoryToDatabase(newEntry);
    }
  };

  // Function to add a response to the history
  const addResponse = (message, item) => {
    const newEntry = { type: 'response', content: message, sessionId };
    const oldEntry = { type: 'response', content: message, sessionId };

    // If there's no existing item, add the new entry to the history
    if (!item) {
      setHistory((prevHistory) => [...prevHistory, newEntry]);
    } else {
      // If there is an existing item, add the old entry and update session ID
      setHistory((prevHistory) => [...prevHistory, oldEntry]);
      setSessionId(item.session_id);
    }

    // If the user is authenticated and there's no existing item, save to database
    if (isAuthenticated && !item) {
      saveHistoryToDatabase(newEntry);
    }
  };

  // Function to save history entry to the database
  const saveHistoryToDatabase = async (entry) => {
    const token = getCookie('token');
    const formData = {
      sessionId: entry.sessionId,
      type: entry.type,
      content: entry.content,
    };

    try {
      const response = await fetch(`${url}/users/history`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to save history to database');
      }
    } catch (error) {
      console.error('Error saving history to database:', error);
    }
  };

  // Provide the history-related state and functions to children components
  return (
    <HistoryContext.Provider
      value={{ history, sessionId, addRequest, addResponse, setHistory }}
    >
      {children}
    </HistoryContext.Provider>
  );
};
