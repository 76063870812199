import { useState } from 'react'
import style from './Login.module.css'
import ErrorIcon from '../../../assets/svgs/Error'
import Icon from '../../../assets/svgs/Icon'
import ShowPassIcon from '../../../assets/svgs/ShowPass'
import CloseBubbleIcon from '../../../assets/svgs/CloseBubble'
import { useAuthContext } from '../../../contexts/AuthContext'
import validateLoginForm from '../../../utils/LoginValidation'
import { useNavigate } from 'react-router-dom'

const Login = ({ handleLogin, setOpenAuth }) => {
  const [error, setError] = useState({})
  const navigate= useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const { login } = useAuthContext()
  const [formData, setFormData] = useState({ email: '', password: '' })
  const { errors, isValid } = validateLoginForm(formData)
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
    if (error[name]) {
      setError((prevError) => {
        const newError = { ...prevError }
        delete newError[name]
        return newError
      })
    }
  }
  const url = process.env.REACT_APP_API_URL || '';
  
  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!isValid) {
      setError(errors)
      return
    }
    try {
      const response = await fetch(`${url}/users/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
        credentials: 'include'
      })

      if (response.ok) {
        const responseData = await response.json()
        handleLogin()
        navigate('/dashboard/home')
        login(responseData)
        document.documentElement.classList.remove('disable-scroll')
      } else {
        const errorResponseData = await response.json()
        console.log("errors:", errorResponseData)
        setError({
          email: errorResponseData.emailError,
          password: errorResponseData.passwordError
        })
      }
    } catch (error) {
      console.error('Error submitting form:', error)
    }
  }

  const renderErrorMessage = (message) => (
    <p className={style.errorMessage}>{message}</p>
  )

  return (
    <div className={style.mainContainer}>
      <div className={style.container}>
        <div className={style.closeBubble} onClick={handleLogin}>
          <CloseBubbleIcon />
        </div>
        <div>
          <p className={style.welcomeTitle}>Welcome back!</p>
          <h2 className={style.title}>Log in</h2>
          <div className={style.loginIcon}>
            <Icon width="100px" height="100px" color="#9E7AC3" />
          </div>
        </div>
        <form className={style.form} onSubmit={handleSubmit}>
          <div className={style.inputContainer}>
            {error.email && (
              <div className={style.errorIconContainer}>
                <ErrorIcon />
              </div>
            )}
            <input
              className={error.email ? style.inputFieldError : style.inputField}
              placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
              name="email"
            />
            <p style={{ margin: 0, height: '20px' }}>
              {error.email && renderErrorMessage(error.email)}
            </p>
          </div>
          <div className={style.inputContainer}>
            {error.password && (
              <div className={style.errorIconContainer}>
                <ErrorIcon />
              </div>
            )}
            <input
              className={
                error.password ? style.inputFieldError : style.inputField
              }
              placeholder="Password"
              type={showPassword ? 'text' : 'password'}
              value={formData.password}
              onChange={handleInputChange}
              name="password"
            />
           
              <div
                onClick={() => setShowPassword(!showPassword)}
                className={style.showPassword}
              >
                <ShowPassIcon show={showPassword} />
              </div>
            
            <p style={{ margin: 0, height: '20px' }}>
              {error.password && renderErrorMessage(error.password)}
            </p>
          </div>
          <p className={style.forgotPassword}  onClick={() => setOpenAuth('forgot')}>Forgot password?</p>
          <button className={style.button}>Log in</button>
          <div className={style.divider}>
            <div className={style.hr}></div>
            <p className={style.dividerText}> Or</p>
          </div>
          <button
            type="button"
            className={style.mobileButton}
            onClick={() => setOpenAuth('register')}
          >
            Sign up
          </button>
        </form>
      </div>
    </div>
  )
}

export default Login
