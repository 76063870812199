import React from "react";

function EventIcon({color}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <rect
        width="18"
        height="15"
        x="3"
        y="6"
        stroke={color||"#0B3D2B"}
        strokeWidth="2"
        rx="2"
      ></rect>
      <path
        fill={color||"#0B3D2B"}
        d="M3 10c0-1.886 0-2.828.586-3.414C4.172 6 5.114 6 7 6h10c1.886 0 2.828 0 3.414.586C21 7.172 21 8.114 21 10H3z"
      ></path>
      <path
        stroke={color||"#0B3D2B"}
        strokeLinecap="round"
        strokeWidth="2"
        d="M7 3v3M17 3v3"
      ></path>
    </svg>
  );
}

export default EventIcon;