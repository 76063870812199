import PasswordCheckIcon from "../assets/svgs/PasswordCheck";
import style from '../../src/components/home/register/Register.module.css'
const checkPasswordValidation = (password) => {
    let hasError = false;
  
    const minLengthMessage =
      password.length >= 10 ? (
        <p className={style.passwordCheckPassed}>
          <PasswordCheckIcon passed={true} />
          At least 10 characters
        </p>
      ) : (
        <p className={style.passwordCheckNotPassed}>
          <PasswordCheckIcon passed={false} />
          At least 10 characters
        </p>
      );
  
    const hasNumber = /\d/.test(password);
    const hasNumberMessage = hasNumber ? (
      <p className={style.passwordCheckPassed}>
        <PasswordCheckIcon passed={true} />
        At least 1 number
      </p>
    ) : (
      <p className={style.passwordCheckNotPassed}>
        <PasswordCheckIcon passed={false} />
        At least 1 number
      </p>
    );
  
    const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasSpecialCharacterMessage = hasSpecialCharacter ? (
      <p className={style.passwordCheckPassed}>
        <PasswordCheckIcon passed={true} />
        At least 1 special character
      </p>
    ) : (
      <p className={style.passwordCheckNotPassed}>
        <PasswordCheckIcon passed={false} />
        At least 1 special character
      </p>
    );
  
    if (!minLengthMessage || !hasNumber || !hasSpecialCharacter) {
      hasError = true;
    }
  
    return {
      hasError: hasError,
      minLength: minLengthMessage,
      hasNumber: hasNumberMessage,
      hasSpecialCharacter: hasSpecialCharacterMessage,
    };
  };
  export default checkPasswordValidation;
  