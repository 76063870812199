import CloseBubbleIcon from '../../../../assets/svgs/CloseBubble'
import EditIcon from '../../../../assets/svgs/EditIcon'
import PlusCircleIcon from '../../../../assets/svgs/PlusCircle'
import style from './MainProfile.module.css'
const Skills = ({ userProfile, toggleModal, owner ,editMode, removeComponent}) => {
  return (
<>{userProfile.skills.length > 0 &&

 <div className={style.skillsWrapper}>
      
      <div className={style.experienceActions}>
        <p className={style.title}>Skills</p>
        {owner && editMode &&(
          <div style={{ display: 'flex',justifyContent:'center', alignContent:'center' }}>
            <div className={style.plus}  onClick={()=>toggleModal('skill', userProfile, 'create')}>
              <PlusCircleIcon color={'white'} />
            </div>
            <div onClick={()=> removeComponent('4')}>
            <CloseBubbleIcon color="#393D63" height={20} width={20} />
          </div>
          </div>
        )}
      </div>
      <div className={style.cardsContainer}>
        {userProfile &&
          userProfile.skills.map((item, index) => {
            return (
              <div className={style.skill} key={index}>
                <p className={style.skillName}>{item.skill_name}</p>
                <p className={style.subtext}> · {item.proficiency}</p>
                <p className={style.subtext}>
                  {' '}
                  · {item.years_of_experience} experience
                </p>{' '}
                {owner &&editMode&&<div
                  onClick={() => toggleModal('skill', item)}
                  className={style.edit}
                >
                  <EditIcon />
                </div>
                }
                
              </div>
            )
          })}
      </div>
    </div>
}</>
   
  )
}
export default Skills
