import React from "react";

function ChartPieIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
    >
      <path
        fill="#393D63"
        fillRule="evenodd"
        d="M5.307 16.882a8 8 0 107.14-12.37l.556 2.073a6 6 0 11-5.624 9.742l-2.072.555z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#393D63"
        strokeWidth="2"
        d="M5.912 4.566A10 10 0 018.46 3.147c.42-.158.63-.238.827-.136.197.1.26.332.384.796l2.07 7.727c.122.455.183.683.08.862-.104.18-.332.24-.787.363l-7.727 2.07c-.464.124-.695.187-.882.066-.186-.12-.222-.341-.294-.784a10 10 0 013.781-9.545z"
      ></path>
    </svg>
  );
}

export default ChartPieIcon;