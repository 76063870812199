import React, { useEffect, useState } from 'react';
import AboutIcon from '../../../../assets/svgs/About';
import BagIcon from '../../../../assets/svgs/Bag';
import EducationIcon from '../../../../assets/svgs/Education';
import LanguagesIcon from '../../../../assets/svgs/Languages';
import SkillsIcon from '../../../../assets/svgs/Skills';
import style from './MainProfile.module.css';
import { useLayoutContext } from '../../../../contexts/LayoutContext';
import { useLocation } from 'react-router-dom';

const AddComponent = ({ userProfile, toggleModal, owner }) => {
  const { layout, saveLayout, fetchProfileLayout, addComponent,setLayout } = useLayoutContext();
  const { about, skills, languages, experience, education } = userProfile;
  const location = useLocation()

  const userId = new URLSearchParams(location.search).get('id')
  useEffect(() => {
    if (layout) {
      setLayout(layout);
    }
  }, [layout]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     await fetchProfileLayout(userId);
  //   };
  //   fetchData();
  // }, []);

  const checkExisting = (id) => {
    return layout?.some((item) => item.id === id);
  };

  const handleClick = (id, modalType) => {
    if (!checkExisting(id)) {
      switch (modalType) {
        case 'about':
          if (userProfile.about && userProfile.about[0].content) {
            addComponent('1', userProfile.user.user_id);
          } else {
            toggleModal(modalType, userProfile, 'create');
          }
          break;
        case 'skill':
          if (userProfile.skills && userProfile.skills.length > 0) {
            addComponent('4', userProfile.user.user_id);
          } else {
            toggleModal(modalType, userProfile, 'create');
          }
          break;
        case 'language':
          if (userProfile.languages && userProfile.languages.length > 0) {
            addComponent('5', userProfile.user.user_id);
          } else {
            toggleModal(modalType, userProfile, 'create');
          }
          break;
        case 'experience':
          if (userProfile.experience && userProfile.experience.length > 0) {
            addComponent('2', userProfile.user.user_id);
          } else {
            toggleModal(modalType, userProfile, 'create');
          }
          break;
        case 'education':
          if (userProfile.education && userProfile.education.length > 0) {
            addComponent('3', userProfile.user.user_id);
          } else {
            toggleModal(modalType, userProfile, 'create');
          }
          break;
        default:
          console.log('Invalid modal type');
      }
    }
  };
  
  
  return (
    <div className={style.wrapper}>
      <p className={style.title}>Add Component</p>
      <div className={style.components}>
        <div className={`${style.component} ${checkExisting('2') ? style.existingComponent : ''}`} onClick={() => handleClick('2', 'experience')}>
          <BagIcon stroke={2} />
          <p className={style.componentTitle}>Experience</p>
        </div>
        <div className={`${style.component} ${checkExisting('3') ? style.existingComponent : ''}`} onClick={() => handleClick('3', 'education')}>
          <EducationIcon />
          <p className={style.componentTitle}>Education</p>
        </div>
        <div className={`${style.component} ${checkExisting('4') ? style.existingComponent : ''}`} onClick={() => handleClick('4', 'skill')}>
          <SkillsIcon />
          <p className={style.componentTitle}>Skills</p>
        </div>
        <div className={`${style.component} ${checkExisting('5') ? style.existingComponent : ''}`} onClick={() => handleClick('5', 'language')}>
          <LanguagesIcon />
          <p className={style.componentTitle}>Languages</p>
        </div>
        <div className={`${style.component} ${checkExisting('1') ? style.existingComponent : ''}`} onClick={() => handleClick('1', 'about')}>
          <AboutIcon />
          <p className={style.componentTitle}>About</p>
        </div>
      </div>
    </div>
  );
};

export default AddComponent;
