import React, { createContext, useState, useContext } from 'react';

// Create the DragContext to hold dragging state and functions
const DragContext = createContext();

// DragProvider component to provide the DragContext to its children
export const DragProvider = ({ children }) => {
  // State to track if an item is being dragged
  const [isDragging, setIsDragging] = useState(false);

  // Provide the dragging state and function to children components
  return (
    <DragContext.Provider value={{ isDragging, setIsDragging }}>
      {children}
    </DragContext.Provider>
  );
};

// Custom hook to use the DragContext
export const useDragContext = () => {
  return useContext(DragContext);
};
