import style from './History.module.css'
import BagIcon from '../../../assets/svgs/Bag'
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useHistoryContext } from '../../../contexts/HistoryContext';

const History = () => {
  const { isAuthenticated, setIsAuthenticated ,getCookie} = useAuthContext()
  const {addRequest, addResponse,history, setHistory}= useHistoryContext()
  const [historySessions,setHistorySessions]=useState([])
  const token = getCookie('token');
  const url = process.env.REACT_LOCAL || ''
  useEffect(() => {
    if (isAuthenticated) {
      console.log('Fetching user data...');
      fetch(`${url}/users/history/all`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        console.log('Response:', response);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Data from history:', data);
        setHistorySessions(data);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
    }else{
      setHistorySessions(history)
    }
  }, [getCookie, history, isAuthenticated, token]);
  const fetchHistory = (sessionId) => {
    console.log('Fetching history for session ID:', sessionId);
    
    setHistory([]);
  
    fetch(`${url}/users/history?sessionId=${sessionId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => {
      console.log('Response:', response);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log('Data from history by session id:', data);
      

data.map(item => {
        if (item.type === 'request') {
          addRequest(item.content,item);
        } else if (item.type === 'response') {
          addResponse(item.content,item);
        }
      });
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
    });
  }
  
  return (
    <div className={style.container}>
      <p className={style.title}>History</p>
      <div className={style.cardsContainer}>
        <div className={style.cards}>
          <p className={style.subTitle}>Today</p>
          {historySessions.map((item, index)=> {
            return(
               <div className={style.card} key={index} onClick={()=> fetchHistory(item.session_id)}>
            <p className={style.cardTitle}>{item.content}</p>
            <div className={style.icons}>
              <div className={style.icon}>
                <BagIcon />
              </div>
            </div>
          </div>
            )
           
          })}
          
        </div>
        
   
      </div>
      <p className={style.scrollText}>Scroll to see more</p>
    </div>
  )
}
export default History
