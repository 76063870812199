import React from "react";

function ChatIcon({stroke}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#292929"
        strokeWidth={stroke||"1"}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.326 5.778C20 6.787 20 8.19 20 11c0 2.809 0 4.213-.674 5.222a4.003 4.003 0 01-1.104 1.104c-.881.589-2.064.663-4.222.673V18l-1.106 2.211a1 1 0 01-1.788 0L10 18v-.001c-2.158-.01-3.34-.084-4.222-.673a4.002 4.002 0 01-1.104-1.104C4 15.213 4 13.81 4 11c0-2.809 0-4.213.674-5.222a4 4 0 011.104-1.104C6.787 4 8.19 4 11 4h2c2.809 0 4.213 0 5.222.674.437.292.812.667 1.104 1.104zM9 9h6M9 13h3"
      ></path>
    </svg>
  );
}

export default ChatIcon;
