import React from "react";

function MinusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="9" stroke="#251436"></circle>
      <path stroke="#251436" d="M7.5 12h9"></path>
    </svg>
  );
}

export default MinusIcon;
