import Footer from '../components/home/footer/Footer'
import Header from '../components/home/header/Header'

const ComingSoon = () => {
  return (
    <div className='comingsoon'>
      <Header />
      <div className='comingsoonContainer'>
        
        <div className='comingsoonBox'>Coming soon<div className='comingsoonTitle'>Dashboard</div></div>
      </div>
      <Footer />
    </div>
  )
}
export default ComingSoon