import React from "react";

function FileUploadedIcon({noBubble, height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height={height||"20"}
      fill="none"
      viewBox="0 0 20 20"
    >
        {!noBubble && <rect width="20" height="20" fill="#432C5B" rx="10"></rect>}
     
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="2"
        d="M6.5 11l2 1.5L13 7"
      ></path>
    </svg>
  );
}

export default FileUploadedIcon;