import style from './UploadModal.module.css'
import { useState, useRef } from 'react'
import ImageIcon from '../../../assets/svgs/ImageIcon'
import URLIcon from '../../../assets/svgs/UrlIcon'
import useWindowWidth from '../../../hooks/useWindowWidth'

const Upload = ({
  openUpload,
  setOpenUpload,
  files,
  addFile,
  formData,
  handleFormData
}) => {
  const innerWidth = useWindowWidth()
  const [isDragOver, setIsDragOver] = useState(false)
  const fileTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
  const handleDrop = (e) => {
    e.preventDefault()
    const selectedFiles = Array.from(e.dataTransfer.files).filter((file) =>
      fileTypes.includes(file.type)
    )
    addFile(selectedFiles)
    setIsDragOver(false)
  }
  const handleDragLeave = () => {
    setIsDragOver(false)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    setIsDragOver(true)
  }

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files).filter((file) =>
      fileTypes.includes(file.type)
    )
    addFile(selectedFiles)
  }

  return (
    <div className={style.openUploadContainer}>
    
      <p className={style.openTitle}>Upload your resume</p>
      {innerWidth < 768 ? (
        <div    style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'start',
          flexDirection:'column'
        }}>
          
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <ImageIcon />

                  <div className={style.fileInputContainer}>
            <input
              type="file"
              id="fileInput"
              className={style.hiddenInput}
              onChange={handleFileChange}
              multiple
            />
            <label htmlFor="fileInput" className={style.customFileInput}>
              Choose File
            </label>
            
          </div>
          </div>
    
          {files.length > 0 && files.map((file, index) => (
            <p key={index} className={style.subtext}>
              {file[index].name}
            </p>
          ))}
        </div>
        
      ) : (
        <div
          className={`${style.dropZone} ${isDragOver ? style.dragOver : ''}`}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
        >
          <ImageIcon />
          <p className={style.dropTitle}>Drag and drop your resume</p>
          {files.length > 0 && files.map((file, index) => (
            <p key={index} className={style.subtext}>
              {file[index].name}
            </p>
          ))}
        </div>
      )}
      {!isDragOver && files.length < 1 && (
        <div className={style.videoContainer}>
          <p className={style.subtext}>Or add a link to your resume</p>
          <div className={style.inputContainer}>
            <URLIcon />
            <input
              type="text"
              className={style.urlInput}
              placeholder="URL"
              value={formData.video}
              onChange={handleFormData}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default Upload
