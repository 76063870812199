import { useLocation, useNavigate } from 'react-router-dom'
import style from './UploadModal.module.css'
import { useEffect, useState } from 'react'
import useWindowWidth from '../../../hooks/useWindowWidth'
import SuccessfullIcon from '../../../assets/svgs/Successfull'
import ArrowLeftIcon from '../../../assets/svgs/ArrowLeft'
import Upload from './Upload'
import CVDetails from './CvDetails'
import { useFilesContext } from '../../../contexts/FilesContext'
import { useAuthContext } from '../../../contexts/AuthContext'

const UploadModal = () => {
  const innerWidth = useWindowWidth()
  const { addFile, files } = useFilesContext()
  const [option, setOption] = useState('Upload')
  const [openUpload, setOpenUpload] = useState(false)
  const [openCVDetails, setOpenCVDetails] = useState(false)
  const [value, setValue] = useState('')
  const [successfull, setSuccessfull] = useState(false)
  const { getCookie } = useAuthContext()
  const token = getCookie('token')
    const url = process.env.REACT_APP_API_URL || ''
  const [errors, setErrors] = useState({
    experience: false,
    summary: false,
  })
  const [formData, setFormData] = useState({
    experience: '',
    summary: '',
  })



  const handleFormData = (e) => {
    const { name, value } = e.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
    setErrors((prev) => ({ ...prev, [name]: false }))
  }

  const navigate = useNavigate()
  const backToDashboard = () => {
    setTimeout(() => {
      navigate('/dashboard/home')
      setValue('')
      setFormData({
        experience: '',
        summary: ''
      })
    }, 3000)
  }

  const validateForm = () => {
    return {
      experience: !formData.experience,
      summary: !formData.summary,
    }
  }
console.log(files)
const handleSubmit = async (e) => {
  e.preventDefault();
  console.log('Submitting form');

  // Check authentication status (example)
  const isAuthenticated = token !== undefined && token !== null; // You might need a more sophisticated check

  if (!isAuthenticated) {
    console.log('User is not authenticated');
    alert('You must be logged in to upload files.');
    return;
  }

  const isFileUploaded = files.length > 0;

  if (!isFileUploaded) {
    console.log('No file uploaded, validating form');
    const validationErrors = validateForm();
    setErrors(validationErrors);
    const hasErrors = Object.values(validationErrors).some((error) => error);

    const isFormValid = formData.summary && formData.experience;

    if (hasErrors || !isFormValid) {
      console.log('Validation errors:', validationErrors);
      return;
    }
  }

  if (isFileUploaded) {
    console.log('File is uploaded, preparing form data');
    const file = files[0]; // Assuming you are uploading one file
    const data = new FormData();
    data.append('file', file);

    try {
      console.log('Sending request to backend');
      const response = await fetch(`${url}/users/upload`, {
        method: 'POST',
        body: data, // No need to set Content-Type header
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`, // Auth token if needed
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.log('Response error:', errorData);
        throw new Error('Error uploading file');
      }

      const responseData = await response.json();
      console.log('File uploaded successfully:', responseData);
      setSuccessfull(true);
      backToDashboard();
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }
};



  const toggleOptions = (value) => {
    setOption(value)
  }

  return (
    <>
      {successfull ? (
        <div
          className={` ${
            innerWidth < 768
              ? style.newEventContainerSuccessfullMobile
              : style.newEventContainerSuccessfull
          }`}
        >
          <SuccessfullIcon width={'200px'} height={'200px'} />
          <p className={style.successfullText}>
            Your resume has been uploaded successfully
          </p>
        </div>
      ) : (
        <>
          <div
            className={` ${
              innerWidth < 768
                ? style.newEventContainerMobile
                : style.newEventContainer
            }`}
          >
            <p
              className={style.back}
              onClick={() => navigate('/dashboard/home')}
            >
              <ArrowLeftIcon /> Back to Dashboard
            </p>
            <p className={style.title}>Upload or fill out your resume</p>
            <p className={style.subTitle}>
              Add all of your resume details and let Chimp do the rest
            </p>
            <div className={style.mobileOptions}>
              <p
                className={`${style.option} ${
                  option === 'Upload' ? style.highlight : null
                }`}
                onClick={() => toggleOptions('Upload')}
              >
                Upload
              </p>
              <p
                className={`${style.option} ${
                  option === 'Fill' ? style.highlight : null
                }`}
                onClick={() => toggleOptions('Fill')}
              >
                Fill out
              </p>
            </div>
            {innerWidth > 768 ? (
              <div className={style.scrollable}>
                <Upload
                  openUpload={openUpload}
                  setOpenUpload={setOpenUpload}
                  files={files}
                  addFile={addFile}
                  formData={formData}
                  handleFormData={handleFormData}
                />
                <CVDetails
                  openCVDetails={openCVDetails}
                  errors={errors}
                  handleFormData={handleFormData}
                  formData={formData}
                  setOpenCVDetails={setOpenCVDetails}
                />
                <div>
                  <button
                    className={style.createButtonMobile}
                    onClick={handleSubmit}
                  >
                    Upload
                  </button>
                </div>
              </div>
            ) : (
              <>
                {option === 'Fill' && (
                  <CVDetails
                    openCVDetails={openCVDetails}
                    errors={errors}
                    handleFormData={handleFormData}
                    formData={formData}
                    setOpenCVDetails={setOpenCVDetails}
                  />
                )}
                {option === 'Upload' && (
                  <Upload
                    openUpload={openUpload}
                    setOpenUpload={setOpenUpload}
                    files={files}
                    addFile={addFile}
                    formData={formData}
                    handleFormData={handleFormData}
                  />
                )}
                <div>
                  <button
                    className={style.createButtonMobile}
                    onClick={handleSubmit}
                  >
                    Upload
                  </button>
                </div>
              </>
            )}
          </div>
          <button className={style.createButton} onClick={handleSubmit}>
            Upload
          </button>
        </>
      )}
    </>
  )
}

export default UploadModal
