import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css'
import Login from './components/home/login/Login'
import HomePage from './pages/HomePage'
import Register from './components/home/register/Register'
import Dashboard from './pages/Dashboard'
import ComingSoon from './pages/ComingSoon'
import NotFoundPage from './pages/404'
import { useState } from 'react'
import UploadModal from './components/dashboard/uploadModal/UploadModal'
import ForgotPassword from './components/home/forgotPassword/ForgotPassword'
import Team from './pages/Team'

function App() {
  const [openReg, setOpenReg] = useState(false)
  const [openAuth, setOpenAuth]= useState('')
  const handleAuth = (value) =>{
    setOpenReg(!openReg)
    setOpenAuth(value)
    if (!openReg) {
      document.documentElement.classList.add('disable-scroll')
    } else {
      document.documentElement.classList.remove('disable-scroll')
    }
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage openReg={openReg}setOpenReg={setOpenReg} openAuth={openAuth}setOpenAuth={setOpenAuth} handleAuth={handleAuth}/>} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/dashboard/*" element={<Dashboard openReg={openReg}setOpenReg={setOpenReg} openAuth={openAuth}setOpenAuth={setOpenAuth} handleAuth={handleAuth}/>} />
        <Route path="/comingsoon" element={<ComingSoon />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/team" element={<Team openReg={openReg}setOpenReg={setOpenReg} openAuth={openAuth}setOpenAuth={setOpenAuth} handleAuth={handleAuth}/>} />

      </Routes>
    </BrowserRouter>
  )
}

export default App
