import React from "react";

function ArrowLeftIcon({height,width}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width||"16"}
      height={height||"16"}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#65677D"
        d="M10.967 1.667L12 2.7 6.7 8l5.3 5.3-1.033 1.034L4.667 8l6.3-6.333z"
      ></path>
    </svg>
  );
}

export default ArrowLeftIcon;
