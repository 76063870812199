import Chat from '../chat/Chat'
import Feed from '../feed/Feed'
import News from '../news/News'
import Summary from '../summary/Summary'

const DashHome = () => {
  return (
    <div className="mainDashboard">
      <div className="left">
        <Summary />

        <News />
      </div>
      <div className="main">
        <Feed />
      </div>
      <div className="right">
        <Chat />
      </div>
    </div>
  )
}
export default DashHome