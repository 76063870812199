import ArrowRightIcon from '../../../../assets/svgs/ArrowRight'
import ArrowLeftIcon from '../../../../assets/svgs/ArrowLeft'
import Graph from './Graph'
import style from './Profile.module.css'
import { GraphsData } from '../../../../dummyData'
const EventStatistics = ({ startIndex, nextSlide, prevSlide, mobile }) => {
  return (
    <div className={style.choicesContainer}>
      <p className={style.choicesTitle}>Event Statistics</p>
      <div
        className={style.statistics}
        style={{ padding: mobile ? '15px 15px 15px 15px' : null }}
      >
        <div className={style.graphs}>
          <div onClick={prevSlide} className={style.arrowLeft}>
            <ArrowLeftIcon />
          </div>
          <div onClick={nextSlide} className={style.arrowRight}>
            <ArrowRightIcon />
          </div>
          {GraphsData.map((graph, index) => (
            <div
              key={index}
              className={`${style.graph} ${
                startIndex === index ? style.display : style.hide
              }`}
            >
              <Graph
                title={graph.title}
                percentage={graph.percentage}
                participants={graph.participants}
                mobile={mobile}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default EventStatistics
