import { useEffect, useRef, useState } from 'react'
import style from './Register.module.css'
import CheckBoxIcon from '../../../assets/svgs/CheckBox'
import CheckBoxEmptyIcon from '../../../assets/svgs/CheckBoxEmpty'
import ErrorIcon from '../../../assets/svgs/Error'
import PasswordCheckIcon from '../../../assets/svgs/PasswordCheck'
import ShowPassIcon from '../../../assets/svgs/ShowPass'
import checkPasswordValidation from '../../../utils/PasswordValidation'
import { useFilesContext } from '../../../contexts/FilesContext'
import HandleUpload from '../../../utils/UploadFile'
import { useAuthContext } from '../../../contexts/AuthContext'
const PersonalForm = ({ setOpenAuth }) => {
  const [message, setMessage] = useState('');
  const { isAuthenticated } = useAuthContext();
  const {file, setFile} = useFilesContext()
  const [checkBox1, setCheckBox1] = useState(true)
  const [checkBox2, setCheckBox2] = useState(false)
  const [error, setError] = useState(false)
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    accountType: 'Student'
  })
  const [passwordValidationMessages, setPasswordValidationMessages] = useState({
    minLength: '',
    hasNumber: '',
    hasSpecialCharacter: ''
  })
  const [
    confrimPasswordValidationMessages,
    setConfirmPasswordValidationMessages
  ] = useState({
    minLength: '',
    hasNumber: '',
    hasSpecialCharacter: ''
  })
  const [isPasswordFocused, setIsPasswordFocused] = useState(false)
  const [isConfirmPasswordFocused, setIsConfrimPasswordFocused] =
    useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const passwordRef = useRef(null)
  const confirmPasswordRef = useRef(null)

  const handleCheckBox1Change = () => {
    setCheckBox1(true)
    setCheckBox2(false)
    setFormData({ ...formData, accountType: 'Student' })
  }

  const handleCheckBox2Change = () => {
    setCheckBox1(false)
    setCheckBox2(true)
    setFormData({ ...formData, accountType: 'Professional' })
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
    setError((prevError) => {
      const newError = { ...prevError }
      delete newError[name]
      return newError
    })
    if (name === 'password') {
      const passwordValidationMessages = checkPasswordValidation(value)
      setPasswordValidationMessages(passwordValidationMessages)
    } else if (name === 'confirmPassword') {
      const confirmPasswordValidationMessages = checkPasswordValidation(value)
      setConfirmPasswordValidationMessages(confirmPasswordValidationMessages)
    }

    setIsPasswordFocused(name === 'password' && value !== '')
    setIsConfrimPasswordFocused(name === 'confirmPassword' && value !== '')
  }
  const url = process.env.REACT_APP_API_URL || '';
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (passwordRef.current && !passwordRef.current.contains(event.target)) {
        setIsPasswordFocused(false)
      }
      if (
        confirmPasswordRef.current &&
        !confirmPasswordRef.current.contains(event.target)
      ) {
        setIsConfrimPasswordFocused(false)
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasError = false;
  
    const requiredFields = [
      'firstName',
      'lastName',
      'email',
      'password',
      'confirmPassword',
    ];
    const errors = {};
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    requiredFields.forEach((fieldName) => {
      if (!formData[fieldName]) {
        errors[fieldName] = 'Please fill out all the fields';
        hasError = true;
      } else if (fieldName === 'email' && !emailRegex.test(formData[fieldName])) {
        errors[fieldName] = 'Not a valid email';
        hasError = true;
      }
    });
  
    if (formData['password'] && formData['confirmPassword']) {
      if (formData['password'] !== formData['confirmPassword']) {
        errors['password'] = 'Passwords do not match';
        errors['confirmPassword'] = 'Passwords do not match';
        hasError = true;
      }
      const passwordValidationResult = checkPasswordValidation(formData['password']);
      const confirmPasswordValidationResult = checkPasswordValidation(formData['confirmPassword']);
      if (passwordValidationResult.hasError || confirmPasswordValidationResult.hasError) {
        errors['password'] = 'Password does not meet requirements';
        errors['confirmPassword'] = 'Password does not meet requirements';
        hasError = true;
      }
    }
  
    if (hasError) {
      setError(errors);
    } else {
      try {
        const response = await fetch(`${url}/users/create`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
  
        const data = await response.json();
  
        if (response.ok) {
          console.log('User created successfully:', data.message);
          setOpenAuth('login');
          
          if(file){
            await HandleUpload(file, data.userId, isAuthenticated, setMessage);          }
       
        } else {
          errors.email = data.errors;
          setError(errors);
          throw new Error('Network response was not ok');
        }
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    }
  };
  
  
  return (
    <div className={style.personalFormContainer}>
      <div className={style.personalFormOptionsContainer}>
        <p className={style.personalOptionsTitle}>Choose features:</p>
        <div className={style.checkBoxConatainer}>
          <div className={style.checkbox} onClick={handleCheckBox1Change}>
            {checkBox1 ? <CheckBoxIcon /> : <CheckBoxEmptyIcon />}
            <div className={style.checkBoxOption}>Student</div>
          </div>

          <div className={style.checkbox} onClick={handleCheckBox2Change}>
            {checkBox2 ? <CheckBoxIcon /> : <CheckBoxEmptyIcon />}
            <div className={style.checkBoxOption}>Professional</div>
          </div>
        </div>
        <form className={style.form} onSubmit={handleSubmit}>
          <div className={style.inputContainer}>
            <div className={style.inputContainerSub}>
              {error && error.firstName !== undefined ? (
                <div className={style.errorIconContainer}>
                  <ErrorIcon />
                </div>
              ) : null}

              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                className={
                  error.firstName ? style.inputFieldError : style.inputField
                }
                placeholder="First name"
              />
              <p className={style.errorMessage}>{error.firstName}</p>
            </div>
            <div className={style.inputContainerSub}>
              {error && error.lastName !== undefined ? (
                <div className={style.errorIconContainer}>
                  <ErrorIcon />
                </div>
              ) : null}

              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                className={
                  error.lastName ? style.inputFieldError : style.inputField
                }
                placeholder="Last name"
              />
              <p className={style.errorMessage}>{error.lastName}</p>
            </div>
          </div>
          <div className={style.inputContainerSub}>
            {error && error.email !== undefined ? (
              <div className={style.errorIconContainer}>
                <ErrorIcon />
              </div>
            ) : null}

            <input
              type="text"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className={error.email ? style.inputFieldError : style.inputField}
              placeholder="Email"
            />
            <p className={style.errorMessage}>{error.email}</p>
          </div>
          <div className={style.inputContainerSub}>
            {error && error.password !== undefined ? (
              <div className={style.errorIconContainer}>
                <ErrorIcon />
              </div>
            ) : null}

            <input
              ref={passwordRef}
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              className={
                error.password ? style.inputFieldError : style.inputField
              }
              placeholder="Password"
            />
            
              <div
                onClick={() => setShowPassword(!showPassword)}
                className={style.showPassword}
              >
                <ShowPassIcon show={showPassword ? true : false} />
              </div>
          

            <div
              className={`${style.passwordCheck} ${
                isPasswordFocused ? style.showPasswordCheck : ''
              }`}
            >
              {passwordValidationMessages.minLength}
              {passwordValidationMessages.hasNumber}
              {passwordValidationMessages.hasSpecialCharacter}
            </div>

            <p className={style.errorMessage}>{error.password}</p>
          </div>
          <div className={style.inputContainerSub}>
            {error && error.confirmPassword !== undefined ? (
              <div className={style.errorIconContainer}>
                <ErrorIcon />
              </div>
            ) : null}

            <input
              ref={confirmPasswordRef}
              type={showConfirmPassword ? 'text' : 'password'}
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              className={
                error.confirmPassword ? style.inputFieldError : style.inputField
              }
              placeholder="Confirm password"
            />
      
              <div
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className={style.showPassword}
              >
                <ShowPassIcon show={showConfirmPassword ? true : false} />
              </div>
            
            <div
              className={`${style.passwordCheck} ${
                isConfirmPasswordFocused ? style.showPasswordCheck : ''
              }`}
            >
              {confrimPasswordValidationMessages.minLength}
              {confrimPasswordValidationMessages.hasNumber}
              {confrimPasswordValidationMessages.hasSpecialCharacter}
            </div>

            <p className={style.errorMessage}>{error.confirmPassword}</p>
          </div>
          <button className={style.button}>Sign up</button>
          <div className={style.divider}>
            <div className={style.hr}></div>
            <p className={style.dividerText}> Or</p>
          </div>
          <button
            className={style.mobileButton}
            onClick={(e) => {
              setOpenAuth('login')
            }}
          >
            Log in
          </button>
        </form>
      </div>
    </div>
  )
}
export default PersonalForm
