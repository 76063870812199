import ArrowUpIcon from '../../../../assets/svgs/ArrowUp'
import CheckMark2Icon from '../../../../assets/svgs/CheckMark2'
import ImageIcon from '../../../../assets/svgs/ImageIcon'
import PlusCircleIcon from '../../../../assets/svgs/PlusCircle'
import UploadIcon from '../../../../assets/svgs/UploadIcon'
import URLIcon from '../../../../assets/svgs/UrlIcon'
import style from './NewEvent.module.css'
import image from '../../../../assets/images/upload.png'
import { useRef, useState } from 'react'
import useWindowWidth from '../../../../hooks/useWindowWidth'
const ImagesUpload = ({
  openUpload,
  setOpenUpload,
  files,
  setFiles,
  formData,
  handleFormData
}) => {
  const innerWidth = useWindowWidth()
  const [isDragOver, setIsDragOver] = useState(false)
  const fileInputRef = useRef(null)
  const fileTypes = ['image/jpg', 'image/png', 'image/gif']
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files)
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles])
  }
  const handleDrop = (e) => {
    e.preventDefault()
    const selectedFiles = Array.from(e.dataTransfer.files).filter((file) =>
      fileTypes.includes(file.type)
    )
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles])
    setIsDragOver(false)
  }
  const handleDragLeave = () => {
    setIsDragOver(false)
  }
  const handleDragOver = (e) => {
    e.preventDefault()
    setIsDragOver(true)
  }
  const toggleUpload = () => {
    setOpenUpload(!openUpload)
  }
  return (
    <>
      {openUpload ? (
        <div className={style.openUploadContainer}>
          <p className={style.openTitle}>Add images and videos</p>
          <p className={style.text}>Images</p>
          <p className={style.subtext}>
            Add photos to show what the event will be about. You can upload up
            to 10 images.
          </p>
          {innerWidth < 768 ? (
           <div> 
            <div style={{display:'flex', justifyContent:'center',alignItems:'center'}
          }> 
              <ImageIcon/>
              <ul className={style.ul}>
                <li>Recommended image size: 2160 x 1080 px</li>
                <li>Maximum file size: 10 MB</li>
                <li>Supported image files: 10 JPEG or PNG</li>
              </ul>
            </div>
            <div className={style.fileInputContainer}>
      <input type="file" id="fileInput" className={style.hiddenInput} />
      <label htmlFor="fileInput" className={style.customFileInput}>
        Choose File
      </label>
    </div>           </div>
          ) : (
            <>
              <div
                className={`${style.dropZone} ${
                  isDragOver ? style.dragOver : ''
                }`}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
              >
                <ImageIcon />
                <p className={style.dropTitle}>Drag and an image</p>
                {files.length > 0 &&
                  files.map((item, index) => (
                    <p key={index} className={style.subtext}>
                      {item.name}
                    </p>
                  ))}
              </div>
              <ul className={style.ul}>
                <li>Recommended image size: 2160 x 1080 px</li>
                <li>Maximum file size: 10 MB</li>
                <li>Supported image files: 10 JPEG or PNG</li>
              </ul>
            </>
          )}

          <div className={style.videoContainer}>
            <p className={style.text}>Video</p>
            <p className={style.subtext}>
              Add a video link from Youtube or Vimeo to show your event’s vibe.
              The video appear with your event images.
            </p>
            <div className={style.inputContainer}>
              {' '}
              <URLIcon />{' '}
              <input
                type="text"
                className={style.urlInput}
                placeholder="URL"
                value={formData.video}
                onChange={handleFormData}
              />
            </div>
            <div
              className={`${style.plus} ${
                files.length > 0 && !openUpload ? style.green : null
              }`}
              onClick={toggleUpload}
            >
              {openUpload ? (
                <ArrowUpIcon color={'black'} stroke={'2'} />
              ) : files.length > 0 && !openUpload ? (
                <CheckMark2Icon />
              ) : (
                <PlusCircleIcon />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className={style.imageContainer}>
          <div className={style.uploadContainer}>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
              multiple
              accept={fileTypes.join(', ')}
            />
            <div className={style.uploadCircle} onClick={toggleUpload}>
              <UploadIcon />
            </div>
            <p className={style.uploadText}>Upload photos and video</p>
          </div>
          <img src={image} className={style.image} alt="cover" />
          <div className={style.cover} onClick={toggleUpload}></div>
          <div
            className={`${style.plus} ${
              files.length > 0 && !openUpload ? style.green : null
            }`}
            onClick={toggleUpload}
          >
            {openUpload ? (
              <ArrowUpIcon color={'black'} stroke={'2'} />
            ) : files.length > 0 && openUpload === false ? (
              <CheckMark2Icon />
            ) : (
              <PlusCircleIcon />
            )}
          </div>
        </div>
      )}
    </>
  )
}
export default ImagesUpload
