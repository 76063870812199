import Footer from '../components/home/footer/Footer'
import Header from '../components/home/header/Header'

const NotFoundPage = () => {
  return (
    <div className="comingsoon">
      <Header />
      <div className="comingsoonContainer">
        <div className="comingsoonBox">
          <p>404</p>
          <p>Not found</p>
        </div>
      </div>
      <Footer />
    </div>
  )
}
export default NotFoundPage
