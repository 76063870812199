import React from "react";

function NewsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <rect
        width="14"
        height="14"
        x="5"
        y="5"
        stroke="#630F0F"
        strokeWidth="2"
        rx="3"
      ></rect>
      <path
        stroke="#630F0F"
        strokeLinecap="round"
        strokeWidth="2"
        d="M5 10h14"
      ></path>
    </svg>
  );
}

export default NewsIcon;