import style from './Main.module.css'
import Connections from './Connections'
import Events from './Events'
const Main = ({ selectedProfile, setSelectedTab }) => {
  return (
    <div className={style.mainContainer}>
      <Connections />
      <Events
        selectedProfile={selectedProfile}
        setSelectedTab={setSelectedTab}
      />
    </div>
  )
}
export default Main
