import { useState, useEffect } from 'react';
import useWindowWidth from './useWindowWidth'; // Import custom hook to get window width

const useScrollMenu = (manual) => {
  // State to track the vertical scroll position
  const [yAxis, setYAxis] = useState(0);
  // State to control the visibility of the menu
  const [openMenu, setOpenMenu] = useState(false);
  // Get the current window width using the custom hook
  const innerWidth = useWindowWidth();

  useEffect(() => {
    // Function to handle the scroll event
    const handleScroll = () => {
      // Get the current vertical scroll position
      const y = window.scrollY;
      setYAxis(y);
      
      // If the window width is greater than 900px
      if (innerWidth > 900) {
        // Show the menu if scrolled more than 1px and manual flag is true
        setOpenMenu(y > 1 && manual);
      } else {
        // Otherwise, hide the menu
        setOpenMenu(false);
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);
    
    // Clean up the event listener on component unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, [manual, innerWidth]); // Re-run the effect when `manual` or `innerWidth` changes

  // Return the current scroll position, menu state, and state setter
  return { yAxis, openMenu, setOpenMenu };
};

export default useScrollMenu;
