import { useNavigate } from 'react-router-dom'
import style from './ConnectionTabMobile.module.css'
import ArrowLeftIcon from '../../../../assets/svgs/ArrowLeft'
import ConnectionsChoices from '../Profile/ConnectionsChoices'
import { useState } from 'react'
import EventStatistics from '../Profile/EventStatistics'
const MentorCharts = () => {
  const navigate = useNavigate()
  const [startIndex, setStartIndex] = useState(0)

  const [openCard, setOpenCard] = useState()
  const cardToggle = (value) => {
    if (openCard === value) {
      setOpenCard(null)
    } else {
      setOpenCard(value)
    }
  }
  const totalProducts = 3
  const nextSlide = () => {
    setStartIndex((startIndex + 1) % totalProducts)
  }

  const prevSlide = () => {
    setStartIndex((startIndex - 1 + totalProducts) % totalProducts)
  }
  return (
    <div className={style.personalChartContainer}>
      <p className={style.back} onClick={() => navigate('/dashboard/connect')}>
        <ArrowLeftIcon /> Back to Connect
      </p>
      <div className={style.chartCardsContainer}>
        <div className={style.chartSingleCard}>
          <p className={style.chartNumber}>20</p>
          <p className={style.chartText}>events organized</p>
        </div>
        <div className={style.chartSingleCard}>
          <p className={style.chartNumber}>150</p>
          <p className={style.chartText}>atendees</p>
        </div>
        <div className={style.chartSingleCard}>
          <p className={style.chartNumber}>300</p>
          <p className={style.chartText}>impressions</p>
        </div>
        <div className={style.chartSingleCard}>
          <p className={style.chartNumber}>100</p>
          <p className={style.chartText}>new visitors</p>
        </div>
      </div>
      <div className={style.connectionsContainer}>
        <EventStatistics
          startIndex={startIndex}
          nextSlide={nextSlide}
          prevSlide={prevSlide}
          mobile={true}
        />
      </div>
    </div>
  )
}
export default MentorCharts
