import style from './PartnersBanner.module.css'
import Marquee from 'react-fast-marquee'
const PartnersBanner = () => {
  return (
    <div className={style.container}>
      <Marquee autoFill={true} pauseOnHover={true}>
        <div className={style.partner}>DesignWorks</div>
        <div className={style.partner}>Vinnova</div>
        <div className={style.partner}>ALMI</div>
        <div className={style.partner}>Uppsala Innovation Centre(UIC)</div>
      </Marquee>
    </div>
  )
}
export default PartnersBanner
