import React from "react";

function ArrowRightIcon({height,width}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width||"16"}
      height={height||"16"}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#65677D"
        d="M5.033 1.667L4 2.7 9.3 8 4 13.3l1.033 1.034L11.333 8l-6.3-6.333z"
      ></path>
    </svg>
  );
}

export default ArrowRightIcon;