import React from 'react';
import styles from './HamburgerMenu.module.css'; 

const HamburgerMenu = ({ isOpen, toggleMenu}) => {
  return (
    <div className={`${styles['hamburger-menu']} ${isOpen ? styles.open : ''}`} onClick={toggleMenu}>
      <div className={styles.bar}></div>
      <div className={styles.bar}></div>
      <div className={styles.bar}></div>
    </div>
  );
};

export default HamburgerMenu;
