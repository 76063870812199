import { useState } from 'react'
import DashHeaderChat from '../header/DashHeaderChat'
import MainMobile from './MainMobile/MainMobile'
import { useUserProfileContext } from '../../../contexts/UserProfileContext';

const ConnectTabMobile = ({user,setSelectedTab}) => {
  const { selectedProfile, setSelectedProfile } = useUserProfileContext();       
  return (
    <div className="mainDashboard">
      <DashHeaderChat />

      <div className="main">
        <MainMobile selectedProfile={selectedProfile} setSelectedTab={setSelectedTab} setSelectedProfile={setSelectedProfile} user={user}/>
      </div>
    </div>
  )
}
export default ConnectTabMobile
