import React from 'react'

function EnvelopeIcon2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
    >
      <rect
        x="7.16699"
        y="10"
        width="26.6667"
        height="20"
        rx="2"
        stroke="#393D63"
        strokeWidth="2"
      />
      <path
        d="M7.16699 15L19.6059 21.2195C20.169 21.501 20.8317 21.501 21.3948 21.2195L33.8337 15"
        stroke="#393D63"
        strokeWidth="2"
      />
    </svg>
  )
}

export default EnvelopeIcon2
