import React from 'react'

function LinkedinIconGrad() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      className="linkedin-gradient-svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.53128 12.9507H8.48895C6.3723 12.9507 5 11.5659 5 9.81057C5 8.02107 6.41286 6.66528 8.57185 6.66528C10.7291 6.66528 12.0555 8.01766 12.0978 9.80545C12.0978 11.5608 10.7291 12.9507 8.53128 12.9507ZM35.0004 33.3315H28.37V23.9571C28.37 21.5034 27.3329 19.8283 25.0522 19.8283C23.3077 19.8283 22.3376 20.9587 21.886 22.0481C21.7369 22.3924 21.7396 22.8558 21.7424 23.3335V23.3337C21.7428 23.3985 21.7432 23.4635 21.7432 23.5286V33.3315H15.1745C15.1745 33.3315 15.2591 16.7257 15.1745 15.2162H21.7432V18.0593C22.1312 16.8162 24.2302 15.0421 27.5798 15.0421C31.7355 15.0421 35.0004 17.6478 35.0004 23.2588V33.3315ZM11.6038 15.2172H5.75651V33.3325H11.6038V15.2172Z"
        fill="url(#paint0_linear_1688_5601)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1688_5601"
          x1="20.0002"
          y1="6.66528"
          x2="20.0002"
          y2="33.3325"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8EBC5" />
          <stop offset="0.33" stopColor="#FAD1CB" />
          <stop offset="0.67" stopColor="#EDC4E4" />
          <stop offset="1" stopColor="#DDCAF1" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default LinkedinIconGrad
