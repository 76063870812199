import React from "react";

function InfoIcon({color,height,width}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width||"24"}
      height={height||"24"}
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="9" stroke="#393D63"></circle>
      <circle cx="12" cy="18" r="0.5" fill="#393D63"></circle>
      <path
        stroke="#393D63"
        d="M12 16v-.857c0-.714.357-1.381.951-1.777l.599-.4a3.257 3.257 0 001.45-2.71V10a3 3 0 00-3-3v0a3 3 0 00-3 3v0"
      ></path>
    </svg>
  );
}

export default InfoIcon;