import React from "react";

function InfoIcon({color,height,width}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width||"12"}
      height={height||"12"}
      fill="none"
      viewBox="0 0 12 12"
    >
      <rect width="12" height="12" fill="#fff" rx="6"></rect>
      <path
        stroke="#FBBF31"
        d="M6.868 10.924a5 5 0 10-1.736-9.847 5 5 0 001.736 9.847h0z"
      ></path>
      <path stroke="#FBBF31" strokeLinecap="square" d="M6 6v3M6 3.5V3"></path>
    </svg>
  );
}

export default InfoIcon;
