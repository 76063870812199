import React from "react";

function SkillsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="#000"
      version="1.1"
      viewBox="0 0 232.688 232.688"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path d="M97.688 61.344h120c8.284 0 15-6.716 15-15s-6.716-15-15-15h-120c-8.284 0-15 6.716-15 15s6.715 15 15 15z"></path>
        </g>
        <g>
          <path d="M217.688 101.344h-120c-8.284 0-15 6.716-15 15s6.716 15 15 15h120c8.284 0 15-6.716 15-15s-6.716-15-15-15z"></path>
        </g>
        <g>
          <path d="M217.688 171.344h-120c-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15h120c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15z"></path>
        </g>
        <g>
          <path d="M48.785 104.408l-9.989-1.452-4.467-9.052a7.5 7.5 0 00-13.452 0l-4.468 9.052-9.988 1.452A7.5 7.5 0 002.263 117.2l7.228 7.045-1.707 9.949a7.5 7.5 0 0010.881 7.908l8.936-4.698 8.936 4.698a7.493 7.493 0 003.489.861h.021a7.5 7.5 0 007.276-9.325l-1.612-9.393 7.228-7.045a7.499 7.499 0 00-4.154-12.792z"></path>
        </g>
        <g>
          <path d="M48.785 34.408l-9.989-1.452-4.467-9.052a7.5 7.5 0 00-13.452 0l-4.468 9.052-9.988 1.452A7.5 7.5 0 002.263 47.2l7.228 7.045-1.707 9.949a7.5 7.5 0 0010.881 7.908l8.936-4.698 8.936 4.698a7.493 7.493 0 003.489.861h.021a7.5 7.5 0 007.499-7.5 7.52 7.52 0 00-.223-1.825l-1.612-9.393 7.228-7.045a7.499 7.499 0 00-4.154-12.792z"></path>
        </g>
        <g>
          <path d="M48.785 174.408l-9.989-1.452-4.467-9.052a7.5 7.5 0 00-13.452 0l-4.468 9.052-9.988 1.452A7.5 7.5 0 002.263 187.2l7.228 7.045-1.707 9.949a7.5 7.5 0 0010.881 7.908l8.936-4.698 8.936 4.698a7.493 7.493 0 003.489.861h.021a7.5 7.5 0 007.276-9.325l-1.612-9.393 7.228-7.045a7.499 7.499 0 00-4.154-12.792z"></path>
        </g>
      </g>
    </svg>
  );
}

export default SkillsIcon;
