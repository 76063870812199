import React from "react";

function DropZoneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      fill="none"
      viewBox="0 0 41 40"
    >
      <path
        fill="#61487C"
        d="M20.5 23.334l-1.178 1.178L20.5 25.69l1.178-1.178-1.178-1.178zm1.667-15a1.667 1.667 0 00-3.334 0h3.334zm-11.179 7.845l8.334 8.333 2.357-2.357-8.334-8.333-2.357 2.357zm10.69 8.333l8.334-8.333-2.357-2.357-8.334 8.333 2.357 2.357zm.489-1.178v-15h-3.334v15h3.334z"
      ></path>
      <path
        stroke="#61487C"
        strokeWidth="3.333"
        d="M8.833 26.666v1.667a3.333 3.333 0 003.334 3.334h16.666a3.333 3.333 0 003.334-3.334v-1.666"
      ></path>
    </svg>
  );
}

export default DropZoneIcon;