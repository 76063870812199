import React from 'react'

function LocationIcon2({width}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width||"40"}
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M32.5 20C32.5 28.6975 22.6073 34.4509 20.4233 35.6151C20.1533 35.759 19.8467 35.759 19.5767 35.6151C17.3927 34.4509 7.5 28.6975 7.5 20C7.5 12.5 13.5567 7.5 20 7.5C26.6667 7.5 32.5 12.5 32.5 20Z"
        stroke="#393D63"
        strokeWidth="2"
      />
      <circle
        cx="19.9997"
        cy="19.9999"
        r="5.66667"
        stroke="#393D63"
        strokeWidth="2"
      />
    </svg>
  )
}

export default LocationIcon2
