import React from 'react'

function SmallUserIcon({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '16'}
      height={height || '16'}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill={color || '#65677D'}
        d="M13.101 13.604c.369-.077.588-.463.405-.792-.404-.725-1.04-1.362-1.853-1.848C10.605 10.34 9.32 10 8 10c-1.32 0-2.605.339-3.652.964-.814.486-1.45 1.123-1.854 1.848-.183.33.037.715.405.792 3.365.7 6.838.7 10.202 0z"
      ></path>
      <ellipse
        cx="8"
        cy="5.333"
        fill={color || '#65677D'}
        rx="3.333"
        ry="3.333"
      ></ellipse>
    </svg>
  )
}

export default SmallUserIcon
