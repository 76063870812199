import React, { useEffect, useState } from 'react'
import MainProfile from './main/MainProfile'
import { useLocation } from 'react-router-dom'
import ProfileModal from './main/modal'
import AddComponent from './main/AddComponent'
import IconWhite from '../../../assets/svgs/IconWhite'
import style from './main/MainProfile.module.css'
import Profiles from './main/Profiles'
import Suggestions from './main/Suggestions'
import QuickChat from './main/QuickChat'
import useWindowWidth from '../../../hooks/useWindowWidth'
import ExpandIcon from '../../../assets/svgs/Expand'
const ProfileTab = ({ user }) => {
  const [userProfile, setUserProfile] = useState(null)
  const [modalTitle, setModalTitle] = useState('')
  const [selectedId, setSelectedId] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [openSideModal, setOpenSideModal] = useState(false)
  const [create, setCreate] = useState(false)
  const location = useLocation()
  const userId = new URLSearchParams(location.search).get('id')
  const [owner, setOwner] = useState(false)
  const innerWidth = useWindowWidth()
  const toggleModal = (value, item, create) => {
    setOpenModal(!openModal)
    setModalTitle(value)
    setSelectedId(item)
    if (create !== undefined) {
      setCreate(true)
    }
  }
  const toggleSideModal = () => {
    setOpenSideModal(!openSideModal)
  }
  const url = process.env.REACT_APP_API_URL || ''
  useEffect(() => {
    if (user?.user?.user_id === userId) {
      setOwner(true)
    }
    console.log('Fetching user data...')
    if (!userId) return

    fetch(`${url}/users/user-profile?id=${userId}`, {
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.json()
      })
      .then((data) => {
        setUserProfile(data)
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error)
      })
  }, [user?.user?.user_id, userId])

  if (userProfile === null) {
    return (
      <div className={style.loading}>
        <IconWhite color={'#432c5b'} width={100} stroke={0.5} />
      </div>
    )
  }

  return (
    <div className="mainDashboard">
      <div className="left profile">
        <div className={style.leftSide}>
          {owner && (
            <>
              <Profiles
                userProfile={userProfile}
                toggleModal={toggleModal}
                openModal={openModal}
                owner={owner}
              />
              <AddComponent
                userProfile={userProfile}
                toggleModal={toggleModal}
                openModal={openModal}
                owner={owner}
              />
            </>
          )}
        </div>
      </div>
      <div className="main">
        <MainProfile
          userProfile={userProfile}
          toggleModal={toggleModal}
          openModal={openModal}
          owner={owner}
        />

        <ProfileModal
          toggleModal={toggleModal}
          openModal={openModal}
          modalTitle={modalTitle}
          userProfile={userProfile}
          setUserProfile={setUserProfile}
          selectedId={selectedId}
          create={create}
        />
      </div>
      <div className="right profile">
        <div className={style.leftSide}>
          {owner && (
            <Suggestions
              toggleModal={toggleModal}
              openModal={openModal}
              modalTitle={modalTitle}
              userProfile={userProfile}
              setUserProfile={setUserProfile}
              selectedId={selectedId}
              create={create}
            />
          )}
        </div>
      </div>
      {innerWidth < 768 && owner && (
        <div className={style.expandMenu} onClick={toggleSideModal}>
          {' '}
          <ExpandIcon />
        </div>
      )}
      {innerWidth < 768 && owner && openSideModal && (
        <div className={style.sideModal}>
          {' '}
          <Profiles
            userProfile={userProfile}
            toggleModal={toggleModal}
            openModal={openModal}
            owner={owner}
          />
          <AddComponent
            userProfile={userProfile}
            toggleModal={toggleModal}
            openModal={openModal}
            owner={owner}
          />
      {innerWidth < 768 && owner && (
        <div className={style.expandMenuBack} onClick={toggleSideModal}>
          {' '}
          <ExpandIcon />
        </div>
      )}
        </div>
      )}
    </div>
  )
}

export default ProfileTab
