import DragIcon from '../../../assets/svgs/Drag'
import FileIcon from '../../../assets/svgs/FileIcon'
import FileUploadedIcon from '../../../assets/svgs/FileUploaded'
import { useFilesContext } from '../../../contexts/FilesContext'
import useDraggable from '../../../hooks/useDraggable'
import style from './Files.module.css'
const SingleCard = ({ item, uploaded }) => {
  const { draggable, onDragStart, onDragEnd, isDragging } = useDraggable()
  const { addFile, files } = useFilesContext()

  const exsistingFile = files.some((founditem) => founditem.id === item.id)

  return (
    <div
      className={`${style.singleCard} ${isDragging ? style.dragging : ''}`}
      draggable
      onDragStart={(e) => onDragStart(e, item)}
      onDragEnd={onDragEnd}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className={`${style.icon} ${uploaded ? style.uploaded : ''}`}>
          <FileIcon color={uploaded ? '' : 'white'} />
        </div>
        <p className={style.singleCardTitle}>{item.title}</p>
      </div>
      {exsistingFile ? (
        <div className={style.uploadedIcon}>
          <FileUploadedIcon />
        </div>
      ) : (
        <div className={style.drag}>
          <DragIcon />
        </div>
      )}
    </div>
  )
}
export default SingleCard
