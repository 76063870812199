/*File upload */
import { useRef, useState } from 'react'
import AlertIcon from '../assets/svgs/Alert'
import FullArrowRightIcon from '../assets/svgs/FullArrowRight'
import { useAuthContext } from '../contexts/AuthContext'
import style from '../components/dashboard/header/DashHeader.module.css'
import SuccessfulMessage from './SuccessfullMessage'
import { useFilesContext } from '../contexts/FilesContext'
import { useLocation, useNavigate } from 'react-router-dom'
const StartYourJourrney = ({ handleAuth, openReg, setOpenAuth, user }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const fileInputRef = useRef(null)
  const { file, setFile, addFile, files } = useFilesContext()
  const [message, setMessage] = useState('')
  const { isAuthenticated } = useAuthContext()
  const url = process.env.REACT_APP_API_URL || ''
  const { getCookie } = useAuthContext()
  const token = getCookie('token')

  const handleDivClick = () => {
    if (!file) {
      navigate('/dashboard/upload')
    } else {
      handleAuth(!openReg)
      setOpenAuth('register')
    }
  }

  
  return (
    <>
      <SuccessfulMessage message={message} setMessage={setMessage} />{' '}
      <div
        className={style.uploadContainer}
        style={{ margin: location.pathname === '/dashboard/home' && '0 10px' }}
      >
        <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
          <AlertIcon />
          <p className={style.uploadText}>
            {files.length > 0 && !isAuthenticated
              ? 'To save your progress '
              : 'Start your personalized journey.'}
          </p>
        </div>

        <div className={style.uploadSubText} onClick={handleDivClick}>
          {' '}
          {files.length > 0 && !isAuthenticated ? 'Sign Up' : 'Upload CV'}{' '}
          <FullArrowRightIcon />
        </div>
       
      </div>
    </>
  )
}
export default StartYourJourrney
