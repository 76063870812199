import { useEffect, useState } from 'react'
import TextTransition, { presets } from 'react-text-transition'
import style from './AboutChimp.module.css'

const TEXTS = ['Student', 'Professional', 'Enterprise']

const AlternatingOne = () => {
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => setIndex((index) => index + 1), 3000) // every 3 seconds
    return () => clearInterval(intervalId)
  }, [])

  return (
    <>
      <div className={style.articleContainer}>
        {TEXTS[index % TEXTS.length] === 'Enterprise' ? (
          <span className={style.article}>an&nbsp;</span>
        ) : (
          <span className={style.article}>a&nbsp;</span>
        )}
      </div>
      <TextTransition springConfig={presets.gentle} translateValue={'100%'}>
        <div className={style.highlight}>
          {TEXTS[index % TEXTS.length]}&nbsp;
        </div>
      </TextTransition>
    </>
  )
}

export default AlternatingOne
