import React from "react";

function AlertIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 30 30"
    >
      <circle cx="15" cy="15" r="15" fill="#61487C"></circle>
      <path
        fill="#fff"
        d="M16.62 6.75l-.312 11.64h-2.28l-.312-11.64h2.904zm-1.368 16.848c-.496 0-.912-.168-1.248-.504a1.695 1.695 0 01-.504-1.248c0-.496.168-.912.504-1.248a1.695 1.695 0 011.248-.504c.48 0 .888.168 1.224.504.336.336.504.752.504 1.248s-.168.912-.504 1.248a1.666 1.666 0 01-1.224.504z"
      ></path>
    </svg>
  );
}

export default AlertIcon;
