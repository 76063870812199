import { createContext, useContext, useState } from 'react';

// Create the UserProfileContext to hold user profile-related state and functions
const UserProfileContext = createContext();

// Custom hook to use the UserProfileContext
export const useUserProfileContext = () => useContext(UserProfileContext);

// UserProfileProvider component to provide the UserProfileContext to its children
export const UserProfileProvider = ({ children }) => {
  // State to hold the currently selected profile, defaulting to 'Personal'
  const [selectedProfile, setSelectedProfile] = useState('Personal');

  // Function to update the selected profile
  const selectProfile = (profile) => {
    setSelectedProfile(profile);
  };

  // Provide the user profile-related state and functions to children components
  return (
    <UserProfileContext.Provider
      value={{ selectedProfile, setSelectedProfile, selectProfile }}
    >
      {children}
    </UserProfileContext.Provider>
  );
};
