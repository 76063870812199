import { useState } from 'react'
import style from './WhyChimp.module.css'
import CheckMarkIcon from '../../../assets/svgs/Checkmark'
import CheckMarkFalseIcon from '../../../assets/svgs/CheckMarkFalse'
import { dataEnterprises,dataProfessionals } from '../../../dummyData'
const WhyChimp = () => {
  const [selected, setSelected] = useState('Personal')
  const toggle = (value) => {
    setSelected(value)
  }

  return (
    <div className={style.mainContainer}>
      <div className={style.container}>
        <p className={style.title}>Why Chimp?</p>
        <div className={style.selection}>
          <div
            className={selected === 'Personal' ? style.selected : style.select}
            onClick={() => toggle('Personal')}
          >
            Personal
          </div>
          <div
            className={
              selected === 'Enterprises' ? style.selected1 : style.select1
            }
            onClick={() => toggle('Enterprises')}
          >
            Enterprises
          </div>
        </div>
        <div className={style.tableContainer}>
          <table className={style.table}>
            <thead className={style.tableHeaders}>
              <tr style={{ display: 'flex', flexDirection: 'row' }}>
                <th className={style.tableHeader}>Features and Functions</th>
                <th className={style.tableHeader1}>Chimp</th>
                <th className={style.tableHeader2}>Others</th>
              </tr>
            </thead>
            <thead className={style.tableHeadersMobile}>
              <tr>
                <th className={style.tableHeader}>Features and Functions</th>
                <th className={style.tableHeader1}>Chimp</th>
                <th className={style.tableHeader1}>Others</th>
              </tr>
            </thead>
            <tbody>
              {selected === 'Personal' &&
                dataProfessionals.map((eachInfo, index) => (
                  <tr key={index}>
                    <td
                      className={`${style.tableItem} ${
                        index % 2 === 0 && style.highlighted
                      }`}
                    >
                      {eachInfo.title}
                    </td>
                    <td
                      className={`${style.tableItem1} ${
                        index % 2 === 0 && style.highlighted
                      }`}
                    >
                      {eachInfo.chimp ? (
                        <CheckMarkIcon />
                      ) : (
                        <CheckMarkFalseIcon />
                      )}
                    </td>
                    <td
                      className={`${style.tableItem2} ${
                        index % 2 === 0 && style.highlighted
                      }`}
                    >
                      {eachInfo.others === 'limited' ? (
                        'Limited'
                      ) : (
                        <CheckMarkFalseIcon />
                      )}
                    </td>
                  </tr>
                ))}
              {selected === 'Enterprises' &&
                dataEnterprises.map((eachInfo, index) => (
                  <tr key={index}>
                    <td
                      className={`${style.tableItem} ${
                        index % 2 === 0 && style.highlighted
                      }`}
                    >
                      {eachInfo.title}
                    </td>
                    <td
                      className={`${style.tableItem1} ${
                        index % 2 === 0 && style.highlighted
                      }`}
                    >
                      {eachInfo.chimp ? (
                        <CheckMarkIcon />
                      ) : (
                        <CheckMarkFalseIcon />
                      )}
                    </td>
                    <td
                      className={`${style.tableItem2} ${
                        index % 2 === 0 && style.highlighted
                      }`}
                    >
                      {eachInfo.others === 'limited' ? (
                        'Limited'
                      ) : (
                        <CheckMarkFalseIcon />
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default WhyChimp
