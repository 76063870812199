import React from "react";

function EnterpriseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      fill="none"
      viewBox="0 0 60 60"
    >
      <g
        stroke="#fff"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0_1252_5377)"
      >
        <path strokeLinecap="round" d="M5 52.5h50"></path>
        <path d="M22.5 27.5h-10A2.5 2.5 0 0010 30v20a2.5 2.5 0 002.5 2.5h10A2.5 2.5 0 0025 50V30a2.5 2.5 0 00-2.5-2.5zM47.5 5h-20A2.5 2.5 0 0025 7.5V50a2.5 2.5 0 002.5 2.5h20A2.5 2.5 0 0050 50V7.5A2.5 2.5 0 0047.5 5z"></path>
        <path
          strokeLinecap="round"
          d="M35 40.01h5M15 40.01h5M35 28.76h5M35 17.51h5"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1252_5377">
          <path fill="#fff" d="M0 0H60V60H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default EnterpriseIcon;
