import React from 'react'

function LayersIcon({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="44"
      viewBox="0 0 40 44"
      fill="none"
    >
      <path
        d="M19.9997 7.47229L8.33301 12.9071L19.9997 18.3419L31.6663 12.9071L19.9997 7.47229Z"
        stroke="#393D63"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3189 18.5392L7.91074 21.0585C7.55833 21.2227 7.33301 21.5762 7.33301 21.965C7.33301 22.3538 7.55833 22.7073 7.91074 22.8715L19.5774 28.3062C19.8451 28.431 20.1542 28.431 20.4219 28.3062L32.0886 22.8715C32.441 22.7073 32.6663 22.3538 32.6663 21.965C32.6663 21.5762 32.441 21.2227 32.0886 21.0585L26.6804 18.5392L24.3123 19.6424L29.2982 21.965L19.9997 26.2966L10.7012 21.965L15.6871 19.6424L13.3189 18.5392Z"
        fill="#393D63"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3189 27.5972L7.91074 30.1165C7.55833 30.2807 7.33301 30.6342 7.33301 31.023C7.33301 31.4117 7.55833 31.7653 7.91074 31.9294L19.5774 37.3642C19.8451 37.4889 20.1542 37.4889 20.4219 37.3642L32.0886 31.9294C32.441 31.7653 32.6663 31.4117 32.6663 31.023C32.6663 30.6342 32.441 30.2807 32.0886 30.1165L26.6804 27.5972L24.3123 28.7003L29.2982 31.023L19.9997 35.3546L10.7012 31.023L15.6871 28.7003L13.3189 27.5972Z"
        fill="#393D63"
      />
    </svg>
  )
}

export default LayersIcon
