import { useCallback } from 'react';

// Custom hook to manage drop operations for a draggable list
const useDroppable = (feed, setFeed) => {
  // Callback function to handle the drop event
  const handleDrop = useCallback((e, newIndex) => {
    // Retrieve the index of the dragged item from the dataTransfer object
    const draggedIndex = e.dataTransfer.getData('text/plain');
    // Check if the drop position is different from the dragged item's original position
    if (draggedIndex !== newIndex) {
      // Create a copy of the feed array
      const newFeed = [...feed];
      // Remove the dragged item from its original position
      const [draggedItem] = newFeed.splice(draggedIndex, 1);
      // Insert the dragged item at the new index
      newFeed.splice(newIndex, 0, draggedItem);
      // Update the state with the new feed order
      setFeed(newFeed);
    }
  }, [feed, setFeed]);

  // Callback function to allow dropping by preventing the default behavior
  const allowDrop = useCallback((e) => {
    e.preventDefault();
  }, []);

  // Return the event handlers for drop and drag-over events
  return {
    onDrop: handleDrop,
    onDragOver: allowDrop,
  };
};

export default useDroppable;
