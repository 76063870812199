import { useState } from 'react'
import ArrowDownIcon from '../../../../assets/svgs/ArrowDown'
import ChartPieIcon from '../../../../assets/svgs/ChartPie'
import ChartsIcon from '../../../../assets/svgs/Charts'
import style from './ConnectionTabMobile.module.css'
import ArrowUpIcon from '../../../../assets/svgs/ArrowUp'
import FilterIcon from '../../../../assets/svgs/Filter'
import SingleCard from './SingleCard'
import CardSwiper from './Swiper'
import Events from './Events'
import { useNavigate } from 'react-router-dom'
const MainMobile = ({
  selectedProfile,
  setSelectedProfile,
  setSelectedTab
}) => {
  const navigate= useNavigate()
  const [openDropDown, setOpenDropdown] = useState(false)
  const [openFilter, setOpenFilter] = useState(false)
  const [selectedFilter, setFilter] = useState('All')
  const filters = ['All', 'Mentor', 'Personal']
  const [connectTab, setConnectTab] = useState('connect')
  const profileToggle = (value) => {
    setSelectedProfile(value)
  }
  const toggleDropdown = () => {
    setOpenDropdown(!openDropDown)
  }
  const toggleConnetTab = (value) => {
    setConnectTab(value)
    setOpenDropdown(false)
  }
  const toggleFilter = (value) => {
    setFilter(value)
  }
  const toggleOpenFilter = () => {
    setOpenFilter(!openFilter)
  }
  return (
    <div className={style.mobileContainer}>
      <div className={style.wrapper}>
        <div className={style.profiles}>
          <div
            className={`${style.profile} ${
              selectedProfile === 'Personal' ? style.selected : null
            }`}
            onClick={() => profileToggle('Personal')}
          >
            Personal Profile
          </div>
          <div
            className={`${style.profile} ${
              selectedProfile === 'Mentor' ? style.selected : null
            }`}
            onClick={() => profileToggle('Mentor')}
          >
            Mentor Profile
          </div>
        </div>
        <div className={style.iconContainer} onClick={()=> navigate(selectedProfile === 'Personal' ?'personal-chart':'mentor-chart')}>
          {selectedProfile === 'Personal' ? <ChartsIcon /> : <ChartPieIcon />}
        </div>
      </div>
      <div className={style.wrapper}>
        <div>
          <div className={style.dropdownWrapper}onClick={toggleDropdown} >
            {connectTab === 'connect' ? 'Connect':'Requests'}
            <div style={{display:'flex',alignItems:'center'}}>
              {openDropDown ? (
                <ArrowUpIcon color={'#393D63'} width={'16px'} height={'17px'} />
              ) : (
                <ArrowDownIcon
                  color={'#393D63'}
                  width={'16px'}
                  height={'17px'}
                />
              )}
            </div>
            {openDropDown && (
              <div
                className={`${style.dropdown} ${
                  openDropDown ? style.expandDropdown : null
                }`}
              >
                <p
                  className={style.listItem}
                  onClick={() => toggleConnetTab('connect')}
                >
                  Connect
                </p>
                <p
                  className={style.listItem}
                  onClick={() => toggleConnetTab('requests')}
                >
                  {' '}
                  Requests
                </p>
              </div>
            )}
          </div>
        </div>
     
          <div
            onClick={toggleOpenFilter}
            style={{ display: 'flex', alignItems: 'center', height: '20px' }}
          ><p className={style.filtertext}>Filter</p>
            <FilterIcon selected={openFilter} />
          </div>
        
      </div>
        {openFilter && (  <div className={`${style.filtersWrapper}`}>
            
              <div className={style.filterContainer}>
                {filters.map((filter, index) => (
                  <div
                    key={index}
                    className={`${
                      selectedFilter === filter
                        ? style.selectedFilter
                        : style.filter
                    }`}
                    onClick={() => toggleFilter(filter)}
                  >
                    {filter}
                  </div>
                ))}
              </div>
        
          </div>    )}
      <div style={{display:'flex',width:'100%', justifyContent:'center'}}>
<CardSwiper selectedFilter={selectedFilter} connectTab={connectTab} selectedProfile={selectedProfile}/>
      </div>
      <Events selectedProfile={selectedProfile} setSelectedTab={setSelectedTab}/>
      
    </div>
  )
}
export default MainMobile
