import React from 'react'

function PhoneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M17.7071 14.689L20.3552 17.3372C20.7113 17.6933 20.7113 18.2706 20.3552 18.6267C18.43 20.5519 15.3821 20.7685 13.204 19.1349L11.6286 17.9534C9.88504 16.6457 8.33622 15.0969 7.02857 13.3534L5.84701 11.7779C4.21341 9.59981 4.43001 6.55192 6.35523 4.6267C6.71133 4.27061 7.28867 4.27061 7.64477 4.6267L10.2929 7.27483C10.6834 7.66535 10.6834 8.29852 10.2929 8.68904L9.27175 9.71019C9.10946 9.87247 9.06923 10.1204 9.17187 10.3257C10.3585 12.699 12.2829 14.6234 14.6563 15.8101C14.8615 15.9127 15.1095 15.8725 15.2717 15.7102L16.2929 14.689C16.6834 14.2985 17.3166 14.2985 17.7071 14.689Z"
        stroke="white"
      />
    </svg>
  )
}

export default PhoneIcon
