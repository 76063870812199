import { useContext, useState } from 'react'
import BagIcon from '../../../assets/svgs/Bag'
import ChatIcon from '../../../assets/svgs/ChatIcon'
import HomeIcon from '../../../assets/svgs/Home'
import Icon from '../../../assets/svgs/Icon'
import SearchIcon from '../../../assets/svgs/Search'
import ShareIcon from '../../../assets/svgs/Share'
import UserIcon from '../../../assets/svgs/User'
import UserMobileIcon from '../../../assets/svgs/UserMobile'
import style from './DashHeader.module.css'
import ChatInput from '../chat/ChatInput'
import UploadModal from '../files/UploadModal'
import { useLocation } from 'react-router-dom'
import GuestContext from '../../../contexts/GuestContext'
import InfoIcon from '../../../assets/svgs/Info'
import useWindowWidth from '../../../hooks/useWindowWidth'
import { useAuthContext } from '../../../contexts/AuthContext'
import StartYourJourrney from '../../../utils/StartJourney'
const DashHeader = ({
  selectedTab,
  handleSelectTab,
  openGuestInfo,
  setOpenGuestInfo,
  openReg,
  setOpenAuth,
  handleAuth,user
}) => {
  const location = useLocation()
  const [openUpload, setOpenUpload] = useState(false)
  const { guestUser, setGuestUser } = useContext(GuestContext)
  const { isAuthenticated } = useAuthContext()

  const innerWidth = useWindowWidth()
  const toggleModal = () => {

      setOpenGuestInfo(!openGuestInfo)
  
  }
  return (
    <>
      <div
        className={`${style.dashHeader} ${
          selectedTab === 'Chat' ? style.hidden : null
        } ${
          location.pathname === '/dashboard/connect/create-new-event' &&
          style.hiddenHeader
        }`}
      >
        <div className={style.icon}>
          <Icon color={'#432C5B'} />
        </div>
        {innerWidth > 768 && (
          <StartYourJourrney
            handleAuth={handleAuth}
            openReg={openReg}
            setOpenAuth={setOpenAuth}
            user={user}
          />
        )}
        <div className={style.menuItemsContainer}>
          <div
            className={`${style.menuItem} ${
              selectedTab === 'Home' ? style.highlightedTab : ''
            }`}
            onClick={() => handleSelectTab('Home')}
          >
            Home
          </div>
          <div
            className={`${style.menuItem} ${
              selectedTab === 'Chat' ? style.highlightedTab : ''
            }`}
            onClick={() => handleSelectTab('Chat')}
          >
            Chat
          </div>
          <div
            className={`${style.menuItem} ${
              selectedTab === 'Jobs' ? style.highlightedTab : ''
            } ${!isAuthenticated && style.disabled}`}
            onClick={() => {
              if (isAuthenticated) {
                handleSelectTab('Jobs')
              } else {
                handleAuth(!openReg)
                setOpenAuth('register')
              }
            }}
          >
            Jobs
          </div>
          <div
            className={`${style.menuItem} ${
              selectedTab === 'Connect' ||
              location.pathname.startsWith('/dashboard/connect')
                ? style.highlightedTab
                : ''
            } ${!isAuthenticated && style.disabled}`}
            onClick={() => {
              if (isAuthenticated) {
                handleSelectTab('Connect')
              } else {
                handleAuth(!openReg)
                setOpenAuth('register')
              }
            }}
          >
            Connect
          </div>
          <div
            className={`${style.menuItem} ${
              selectedTab === 'Roadmaps' ? style.highlightedTab : ''
            } ${!isAuthenticated && style.disabled}`}
            onClick={() => {
              if (isAuthenticated) {
                handleSelectTab('Roadmaps')
              } else {
                handleAuth(!openReg)
                setOpenAuth('register')
              }
            }}
          >
            Roadmaps
          </div>
          <div
            className={`${style.menuItem} ${
              selectedTab === 'Profile' ? style.highlightedTab : ''
            } ${!isAuthenticated && style.disabled}`}
            onClick={() => {
              if (isAuthenticated) {
                handleSelectTab('Profile')
              } else {
                handleAuth(!openReg)
                setOpenAuth('register')
              }
            }}
          >
            Profile
          </div>
          {!isAuthenticated && (
            <div onClick={toggleModal} className={style.menuItem}>
              <InfoIcon width={'30px'} height={'30px'} />
            </div>
          )}

          {isAuthenticated ? (
            <div className={style.iconContainer}>
              <UserIcon width={'20'} height={'20'} color={'#ffffff'} />{' '}
            </div>
          ) : (
            <>
              <div
                className={style.dashButton}
                onClick={() => {
                  setOpenGuestInfo(false)
                  handleAuth(!openReg)
                  setOpenAuth('login')
                }}
              >
                {' '}
                Log in
              </div>
              <div
                className={style.dashButton}
                onClick={() => {
                  setOpenGuestInfo(false)
                  handleAuth(!openReg)
                  setOpenAuth('register')
                }}
              >
                Sign up
              </div>
            </>
          )}
        </div>
        <div className={style.mobileMenuContainer}>
          <div className={style.searchContainerMobile}>
            <SearchIcon />
            <input
              placeholder="Search for something..."
              className={style.searchInputMobile}
            />
          </div>
          {!isAuthenticated && (
            <div onClick={toggleModal}>
              <InfoIcon width={'30px'} height={'30px'} />
            </div>
          )}
          {isAuthenticated ? (
            <div className={style.iconContainer}>
              <UserIcon width={'20'} height={'20'} color={'#ffffff'} />{' '}
            </div>
          ) : (
            <>
              <div
                className={style.dashButton}
                onClick={() => {
                  setOpenGuestInfo(false)
                  handleAuth(!openReg)
                  setOpenAuth('login')
                }}
              >
                {' '}
                Log in
              </div>
              <div
                className={style.dashButton}
                onClick={() => {
                  setOpenGuestInfo(false)
                  handleAuth(!openReg)
                  setOpenAuth('register')
                }}
              >
                Sign up
              </div>
            </>
          )}
        </div>
      </div>
      {innerWidth < 768 && !location.pathname.startsWith('/dashboard/chat') &&!location.pathname.startsWith('/dashboard/connect/create-new-event') && (
        <StartYourJourrney
          handleAuth={handleAuth}
          openReg={openReg}
          setOpenAuth={setOpenAuth}
        />
      )}
      <div className={style.mobileMenu}>
        <div
          className={`${style.mobileMenuItem} ${
            selectedTab === 'Home' ? style.highlighted : ''
          }`}
          onClick={() => handleSelectTab('Home')}
        >
          <HomeIcon stroke={selectedTab === 'Home' ? '2' : '1'} />
        </div>
        <div
          className={`${style.mobileMenuItem} ${
            selectedTab === 'Chat' ? style.highlighted : ''
          }`}
          onClick={() => handleSelectTab('Chat')}
        >
          <ChatIcon stroke={selectedTab === 'Chat' ? '2' : '1'} />
        </div>
        <div
          className={`${style.mobileMenuItem} ${
            selectedTab === 'Jobs' ? style.highlighted : ''
          }`}
          onClick={() => {
            if (isAuthenticated) {
              handleSelectTab('Jobs')
            } else {
              handleAuth(!openReg)
              setOpenAuth('register')
            }
          }}
        >
          <BagIcon
            stroke={selectedTab === 'Jobs' ? '2' : '1'}
            color={isAuthenticated ? null : '#0000001A'}
          />
        </div>
        <div
          className={`${style.mobileMenuItem} ${
            selectedTab === 'Connect' ? style.highlighted : ''
          }`}
          onClick={() => {
            if (isAuthenticated) {
              handleSelectTab('Connect')
            } else {
              handleAuth(!openReg)
              setOpenAuth('register')
            }
          }}
        >
          <ShareIcon
            stroke={selectedTab === 'Connect' ? '2' : '1'}
            color={isAuthenticated ? null : '#0000001A'}
          />
        </div>
        <div
          className={`${style.mobileMenuItem} ${
            selectedTab === 'Profile' ? style.highlighted : ''
          }`}
          onClick={() => {
            if (isAuthenticated) {
              handleSelectTab('Profile')
            } else {
              handleAuth(!openReg)
              setOpenAuth('register')
            }
          }}
        >
          <UserMobileIcon
            stroke={selectedTab === 'Profile' ? '2' : '1'}
            color={isAuthenticated ? null : '#0000001A'}
          />
        </div>
      </div>
      {location.pathname !== '/dashboard/connect/create-new-event' && location.pathname !== '/dashboard/upload' &&location.pathname !=='/dashboard/profile' && (
        <div className={style.searchComponent}>
          <div className={style.searchContainer}>
            <SearchIcon />
            <input
              placeholder="Search for something..."
              className={style.searchInput}
            />
          </div>
        </div>
      )}

      {openUpload && (
        <div className="cover">
          <UploadModal openUpload={openUpload} setOpenUpload={setOpenUpload} />
        </div>
      )}
      <div className="headerInput">
        <ChatInput setOpenUpload={setOpenUpload} openUpload={openUpload} />
      </div>
    </>
  )
}
export default DashHeader
