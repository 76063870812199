import React from "react";

function FileIcon({color , height, width}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width||"24"}
      height={height||"24"}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={color||"#393D63"}
        strokeWidth="2"
        d="M5.5 5.9c0-.84 0-1.26.163-1.581a1.5 1.5 0 01.656-.656c.32-.163.74-.163 1.581-.163h4.606c.367 0 .55 0 .723.041.153.037.3.098.433.18.152.093.281.223.54.482l3.595 3.594c.26.26.39.39.482.54.082.135.143.281.18.434.041.173.041.356.041.723V18.1c0 .84 0 1.26-.163 1.581a1.5 1.5 0 01-.656.655c-.32.164-.74.164-1.581.164H7.9c-.84 0-1.26 0-1.581-.163a1.5 1.5 0 01-.656-.656c-.163-.32-.163-.74-.163-1.581V5.9z"
      ></path>
      <path
        stroke={color||"#393D63"}
        strokeWidth="2"
        d="M12.5 3.5v3.6c0 .84 0 1.26.164 1.581a1.5 1.5 0 00.655.656c.32.163.74.163 1.581.163h3.6"
      ></path>
    </svg>
  );
}

export default FileIcon;
