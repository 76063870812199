import user1 from './assets/images/User1.png'
import user2 from './assets/images/User2.png'
let currentId = 1

function generateUniqueId() {
  return currentId++
}
export const QuickchatData = [
  { id: generateUniqueId(), title: 'What can Chimp do for me?' },
  { id: generateUniqueId(), title: 'Tell me about the Chimp dashboard.' },
  { id: generateUniqueId(), title: 'Why should I use Chimp?' },
  { id: generateUniqueId(), title: 'Tell me about suitable jobs for me.' }
]
export const NewsData = [
  {
    id: generateUniqueId(),
    title: 'Improved Job Matching Algorithm',
    subTitle:
      'Say hello to smarter job search experiences with our improved job matching algorithm!',
    text: 'Introducing enhancements delivering more relevant job opportunities tailored to your skills, experience, and career preferences.',
    dayPosted: '2024-04-05 08:33',
    seen: false
  },
  {
    id: generateUniqueId(),
    title: 'Enhanced Privacy and Security Measures',
    subTitle:
      'Say hello to smarter job search experiences with our improved job matching algorithm!',
    text: 'Introducing enhancements delivering more relevant job opportunities tailored to your skills, experience, and career preferences.',
    dayPosted: '2024-04-04 08:10',
    seen: false
  },
  {
    id: generateUniqueId(),
    title: 'Advanced Skill Development Resources',
    subTitle:
      'Elevate your skillset with our latest update featuring advanced skill development resources!',
    text: 'Introducing enhancements delivering more relevant job opportunities tailored to your skills, experience, and career preferences.',
    dayPosted: '2024-04-04 08:10',
    seen: true
  },
  {
    id: generateUniqueId(),
    title: 'Enhanced Networking Features',
    subTitle:
      "We're thrilled to announce the rollout of enhanced networking features on our professional platform!",
    text: 'Introducing enhancements delivering more relevant job opportunities tailored to your skills, experience, and career preferences.',
    dayPosted: '2024-04-04 08:10',
    seen: true
  },
  {
    id: generateUniqueId(),
    title: 'Enhanced Networking Features',
    subTitle:
      "We're thrilled to announce the rollout of enhanced networking features on our professional platform!",
    text: 'Introducing enhancements delivering more relevant job opportunities tailored to your skills, experience, and career preferences.',
    dayPosted: '2024-04-04 08:10',
    seen: true
  }
]
export const FeedData = [
  {
    id: generateUniqueId(),
    category: 'Jobs',
    title: 'Senior Developer',
    company: 'Google',
    datePosted: '2024-04-08 10:27',
    summary:
      'Description is longer and either wraps or continues on and on until it runs out of space.',
    description:
      'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sequi officiis impedit quaerat quod eaque aperiam dolorem, unde est tempore et quia qui voluptas sit placeat perferendis voluptatum obcaecati. Hic, animi?',
    seen: false
  },
  {
    id: generateUniqueId(),
    category: 'Courses',
    title: 'Senior Full Stack Developer',
    summary:
      'Description is longer and either wraps or continues on and on until it runs out of space.',
    datePosted: '2024-04-07 10:27',
    description:
      'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sequi officiis impedit quaerat quod eaque aperiam dolorem, unde est tempore et quia qui voluptas sit placeat perferendis voluptatum obcaecati. Hic, animi?',
    seen: false
  },
  {
    id: generateUniqueId(),
    category: 'News',
    title: 'Senior Full Stack Developer',
    summary:
      'Description is longer and either wraps or continues on and on until it runs out of space.',
    datePosted: '2024-05-06 10:27',
    description:
      'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sequi officiis impedit quaerat quod eaque aperiam dolorem, unde est tempore et quia qui voluptas sit placeat perferendis voluptatum obcaecati. Hic, animi?',
    seen: true
  },
  {
    id: generateUniqueId(),
    category: 'Chimp',
    title: 'Senior Full Stack Developer',
    summary:
      'Description is longer and either wraps or continues on and on until it runs out of space.',
    datePosted: '2024-04-06 10:27',
    description:
      'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sequi officiis impedit quaerat quod eaque aperiam dolorem, unde est tempore et quia qui voluptas sit placeat perferendis voluptatum obcaecati. Hic, animi?',
    seen: true
  },
  {
    id: generateUniqueId(),
    category: 'Contacts',
    title: 'Senior Full Stack Developer',
    summary:
      'Description is longer and either wraps or continues on and on until it runs out of space.',
    datePosted: '2024-04-06 10:27',
    description:
      'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sequi officiis impedit quaerat quod eaque aperiam dolorem, unde est tempore et quia qui voluptas sit placeat perferendis voluptatum obcaecati. Hic, animi?',
    seen: true
  },
  {
    id: generateUniqueId(),
    category: 'Events',
    title: 'Senior Full Stack Developer',
    summary:
      'Description is longer and either wraps or continues on and on until it runs out of space.',
    datePosted: '2024-04-06 10:27',
    description:
      'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sequi officiis impedit quaerat quod eaque aperiam dolorem, unde est tempore et quia qui voluptas sit placeat perferendis voluptatum obcaecati. Hic, animi?',
    seen: true
  },
  {
    id: generateUniqueId(),
    category: 'Jobs',
    title: 'Senior Full Stack Developer',
    company: 'Google',
    summary:
      'Description is longer and either wraps or continues on and on until it runs out of space.',
    datePosted: '2024-04-06 10:27',
    description:
      'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sequi officiis impedit quaerat quod eaque aperiam dolorem, unde est tempore et quia qui voluptas sit placeat perferendis voluptatum obcaecati. Hic, animi?',
    seen: true
  },
  {
    id: generateUniqueId(),
    category: 'Jobs',
    title: 'Senior Full Stack Developer',
    company: 'Google',
    summary:
      'Description is longer and either wraps or continues on and on until it runs out of space.',
    datePosted: '2024-04-06 10:27',
    description:
      'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sequi officiis impedit quaerat quod eaque aperiam dolorem, unde est tempore et quia qui voluptas sit placeat perferendis voluptatum obcaecati. Hic, animi?',
    seen: true
  },
  {
    id: generateUniqueId(),
    category: 'Jobs',
    title: 'Senior Full Stack Developer',
    company: 'Google',
    summary:
      'Description is longer and either wraps or continues on and on until it runs out of space.',
    datePosted: '2024-04-06 10:27',
    description:
      'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sequi officiis impedit quaerat quod eaque aperiam dolorem, unde est tempore et quia qui voluptas sit placeat perferendis voluptatum obcaecati. Hic, animi?',
    seen: true
  }
]
export const EventData = [
  {
    id: generateUniqueId(),
    title: 'Python for Data Science Workshop',
    organizer: 'DataCamp',
    description:
      'Learn how to harness the power of Python for data analysis, visualization, and machine learning.',
    datePosted: '2024-05-01',
    category: 'Events'
  },
  {
    id: generateUniqueId(),
    title: 'Agile Project Management Seminar',
    organizer: 'Agile Alliance',
    description:
      'Dive into agile methodologies, scrum practices, and effective project management techniques.',
    datePosted: '2024-05-03',
    category: 'Events'
  },
  {
    id: generateUniqueId(),
    title: 'AI & Machine Learning Conference',
    organizer: 'TechWorld',
    description:
      'Explore the latest advancements in AI and machine learning with industry experts and innovators.',
    datePosted: '2024-05-05',
    category: 'Events'
  },
  {
    id: generateUniqueId(),
    title: 'Full-Stack Development Bootcamp',
    organizer: 'Code Academy',
    description:
      'Gain hands-on experience in front-end and back-end development using modern technologies.',
    datePosted: '2024-05-07',
    category: 'Events'
  },
  {
    id: generateUniqueId(),
    title: 'Cloud Computing Symposium',
    organizer: 'CloudTech',
    description:
      'Discover cloud architecture, services, and deployment strategies from leading cloud providers.',
    datePosted: '2024-05-09',
    category: 'Events'
  },
  {
    id: generateUniqueId(),
    title: 'Cybersecurity Essentials Workshop',
    organizer: 'InfoSec Institute',
    description:
      'Understand the fundamentals of cybersecurity, threat detection, and risk management practices.',
    datePosted: '2024-05-11',
    category: 'Events'
  },
  {
    id: generateUniqueId(),
    title: 'UX/UI Design Fundamentals',
    organizer: 'DesignLab',
    description:
      'Enhance your skills in user experience and interface design with practical exercises and projects.',
    datePosted: '2024-05-13',
    category: 'Events'
  },
  {
    id: generateUniqueId(),
    title: 'Blockchain and Cryptocurrency Summit',
    organizer: 'CryptoCon',
    description:
      'Learn about blockchain technology, cryptocurrency trends, and the future of decentralized finance.',
    datePosted: '2024-05-15',
    category: 'Events'
  },
  {
    id: generateUniqueId(),
    title: 'Big Data Analytics Expo',
    organizer: 'DataViz',
    description:
      'Delve into big data technologies, tools, and techniques for data processing and analytics.',
    datePosted: '2024-05-17',
    category: 'Events'
  },
  {
    id: generateUniqueId(),
    title: 'DevOps Best Practices Meetup',
    organizer: 'DevOps Collective',
    description:
      'Collaborate with DevOps professionals to share best practices, tools, and workflows.',
    datePosted: '2024-05-19',
    category: 'Events'
  }
]
export const UsersData = [
  {
    id: generateUniqueId(),
    type: 'Mentor',
    firstName: 'Alex',
    lastName: 'Corn',
    occupation: 'Software Developer',
    location: 'London',
    CESscore: 8.5,
    MSscore: 3.4,
    description:
      'Experienced Technical Lead proficient in guiding teams, implementing solutions, and driving innovation for successful project outcomes.',
    image: user1
  },
  {
    id: generateUniqueId(),
    type: 'Personal',
    firstName: 'Jane',
    lastName: 'Doe',
    occupation: 'Graphic Designer',
    location: 'New York',
    CESscore: 7.8,
    MSscore: 4.1,
    description:
      'Creative designer with a passion for visual storytelling and a knack for creating engaging user experiences.',
    image: user2
  },
  {
    id: generateUniqueId(),
    type: 'Mentor',
    firstName: 'John',
    lastName: 'Smith',
    occupation: 'Data Scientist',
    location: 'San Francisco',
    CESscore: 9.0,
    MSscore: 4.8,
    description:
      'Expert in data analysis and machine learning, with a strong background in statistical modeling and algorithm development.',
    image: user1
  },
  {
    id: generateUniqueId(),
    type: 'Personal',
    firstName: 'Emily',
    lastName: 'Johnson',
    occupation: 'Marketing Specialist',
    location: 'Toronto',
    CESscore: 6.5,
    MSscore: 3.7,
    description:
      'Skilled marketer with experience in digital marketing strategies, content creation, and brand management.',
    image: user2
  },
  {
    id: generateUniqueId(),
    type: 'Mentor',
    firstName: 'Michael',
    lastName: 'Brown',
    occupation: 'Project Manager',
    location: 'Sydney',
    CESscore: 8.2,
    MSscore: 4.3,
    description:
      'Accomplished project manager with a track record of delivering projects on time and within budget while maintaining high quality standards.',
    image: user1
  },
  {
    id: generateUniqueId(),
    type: 'Personal',
    firstName: 'Sarah',
    lastName: 'Davis',
    occupation: 'UX Designer',
    location: 'Berlin',
    CESscore: 7.2,
    MSscore: 3.9,
    description:
      'User experience designer with a focus on creating intuitive and accessible interfaces for web and mobile applications.',
    image: user2
  },
  {
    id: generateUniqueId(),
    type: 'Mentor',
    firstName: 'David',
    lastName: 'Wilson',
    occupation: 'Software Engineer',
    location: 'Austin',
    CESscore: 8.9,
    MSscore: 4.6,
    description:
      'Proficient in developing scalable software solutions and leading development teams in Agile environments.',
    image: user1
  },
  {
    id: generateUniqueId(),
    type: 'Personal',
    firstName: 'Laura',
    lastName: 'Martinez',
    occupation: 'Content Writer',
    location: 'Barcelona',
    CESscore: 6.8,
    MSscore: 3.5,
    description:
      'Experienced content writer with a talent for crafting compelling copy and engaging stories across various platforms.',
    image: user2
  },
  {
    id: generateUniqueId(),
    type: 'Mentor',
    firstName: 'James',
    lastName: 'Taylor',
    occupation: 'Cybersecurity Expert',
    location: 'Dubai',
    CESscore: 9.1,
    MSscore: 4.7,
    description:
      'Specialist in cybersecurity with extensive experience in protecting systems and networks from threats and vulnerabilities.',
    image: user1
  },
  {
    id: generateUniqueId(),
    type: 'Personal',
    firstName: 'Anna',
    lastName: 'White',
    occupation: 'Business Analyst',
    location: 'Singapore',
    CESscore: 7.5,
    MSscore: 3.8,
    description:
      'Analytical thinker with expertise in identifying business needs, analyzing processes, and recommending solutions for improvement.',
    image: user2
  }
]
export const PersonalChartsData = [
  {
    id: generateUniqueId(),
    number: '95%',
    content: 'of your connections attending the seminar with Dr. Sturgis.'
  },
  {
    id: generateUniqueId(),
    number: '85%',
    content: 'of your connections attending the workshop with Dr. Smith.'
  },
  {
    id: generateUniqueId(),
    number: '75%',
    content: 'of your connections attending the webinar with Dr. Adams.'
  },
  {
    id: generateUniqueId(),
    number: '65%',
    content: 'of your connections attending the lecture with Dr. Brown.'
  },
  {
    id: generateUniqueId(),
    number: '55%',
    content: 'of your connections attending the conference with Dr. Taylor.'
  }
]
export const GraphsData = [
  {
    id: generateUniqueId(),
    title: 'Python Programming Workshop',
    percentage: 50,
    participants: [
      { title: 'Software Developer', count: 20 },
      { title: 'Data Scientist', count: 15 }
    ]
  },
  {
    id: generateUniqueId(),
    title: 'C# Programming Workshop',
    percentage: 10,
    participants: [
      { title: 'Software Developer', count: 25 },
      { title: 'System Analyst', count: 20 }    ]
  },
  {
    id: generateUniqueId(),
    title: 'Java Programming Workshop',
    percentage: 90,
    participants: [
      { title: 'Software Developer', count: 30 },
      { title: 'Backend Developer', count: 25 }
    ]
  }
]
export const GeneratedFilesData = [
  {
    id: generateUniqueId(),
    title: 'Senior Developer'
  },
  {
    id: generateUniqueId(),
    title: 'Senior Developer'
  },
  {
    id: generateUniqueId(),
    title: 'Senior Developer'
  },
  {
    id: generateUniqueId(),
    title: 'Senior Developer'
  },
  {
    id: generateUniqueId(),
    title: 'Senior Developer'
  },
  {
    id: generateUniqueId(),
    title: 'Senior Developer'
  },
  {
    id: generateUniqueId(),
    title: 'Senior Developer'
  },
  {
    id: generateUniqueId(),
    title: 'Senior Developer'
  },
  {
    id: generateUniqueId(),
    title: 'Senior Developer'
  },
  {
    id: generateUniqueId(),
    title: 'Senior Developer'
  }
]
export const UploadedFilesData = [
  {
    id: generateUniqueId(),
    title: 'Resume'
  },
  {
    id: generateUniqueId(),
    title: 'Resume'
  },
  {
    id: generateUniqueId(),
    title: 'Resume'
  },
  {
    id: generateUniqueId(),
    title: 'Resume'
  },
  {
    id: generateUniqueId(),
    title: 'Resume'
  },
  {
    id: generateUniqueId(),
    title: 'Resume'
  },
  {
    id: generateUniqueId(),
    title: 'Resume'
  },
  {
    id: generateUniqueId(),
    title: 'Resume'
  },
  {
    id: generateUniqueId(),
    title: 'Resume'
  },
  {
    id: generateUniqueId(),
    title: 'Resume'
  }
]
export const dataProfessionals = [
  {
    id: generateUniqueId(),
    title: 'AI-driven personalized learning paths',
    chimp: true,
    others: false
  },
  {
    id: generateUniqueId(),
    title: 'Real-time skill gap analysis',
    chimp: true,
    others: false
  },
  {
    id: generateUniqueId(),
    title: 'Mentorship pairing based on AI',
    chimp: true,
    others: false
  },
  {
    id: generateUniqueId(),
    title: 'Industry-specific virtual networking events',
    chimp: true,
    others: 'limited'
  },
  {
    id: generateUniqueId(),
    title: 'Comprehensive AI integration across services',
    chimp: true,
    others: false
  }
]

export const dataEnterprises = [
  {
    id: generateUniqueId(),
    title: 'Market entry analysis for global expansion',
    chimp: true,
    others: false
  },
  {
    id: generateUniqueId(),
    title: 'Real-time compliance and policy updates',
    chimp: true,
    others: false
  },
  {
    id: generateUniqueId(),
    title: 'AI-powered project matchmaking',
    chimp: true,
    others: false
  },
  {
    id: generateUniqueId(),
    title: 'Tailored industry insights and trends',
    chimp: true,
    others: false
  },
  {
    id: generateUniqueId(),
    title: 'Comprehensive AI integration across services',
    chimp: true,
    others: false
  }
]


export const proficiencyOptions = [
  'Native or Bilingual Proficiency',
  'Full Professional Proficiency',
  'Professional Working Proficiency',
  'Limited Working Proficiency',
  'Elementary Proficiency'
]
export const skillProficiencyOptions = [
  'Expert',
  'Advanced',
  'Intermediate',
  'Beginner'
]
export const skillExperienceOptions = [
  'Less than 1 year',
  '1-2 years',
  '3-5 years',
  '6-8 years',
  '9-10 years',
  'More than 10 years'
]
export const months = [
  'Jan.',
  'Feb.',
  'Mar.',
  'Apr.',
  'May',
  'Jun.',
  'Jul.',
  'Aug.',
  'Sep.',
  'Oct.',
  'Nov.',
  'Dec.'
]
export const employmentTypeOptions = [
  'Full-time',
  'Part-time',
  'Permanent',
  'Self-employed',
  'Freelance',
  'Contract',
  'Internship',
  'Apprenticeship'
]
export const companies = [
  'Apple',
  'Google',
  'Microsoft',
  'Amazon.',
  'Meta',
  'Tesla',
  'IBM',
  'Coca-Cola',
  'Nike',
  'Samsung'
]
export const citiesWithCountries = [
  'New York, USA',
  'Tokyo, Japan',
  'London, United Kingdom',
  'Paris, France',
  'Berlin, Germany',
  'Sydney, Australia',
  'Toronto, Canada',
  'São Paulo, Brazil',
  'Moscow, Russia',
  'Beijing, China'
]

export const schools = [
  'Harvard University',
  'Stanford University',
  'Massachusetts Institute of Technology',
  'University of Oxford',
  'California Institute of Technology',
  'University of Cambridge',
  'Princeton University',
  'Yale University',
  'University of Chicago',
  'Columbia University'
]

export const degrees = [
  "Associate's Degree",
  "Bachelor's Degree",
  "Master's Degree",
  'Doctoral Degree',
  'Professional Degree',
  'Certificate',
  'Diploma',
  'Advanced Diploma',
  'Postgraduate Certificate',
  'Postgraduate Diploma'
]

export const fieldsOfStudy = [
  'Computer Science',
  'Business Administration',
  'Mechanical Engineering',
  'Psychology',
  'Economics',
  'Biology',
  'Physics',
  'Political Science',
  'English Literature',
  'Electrical Engineering'
]
export const skills = [
  'Project Management',
  'Data Analysis',
  'Digital Marketing',
  'Content Writing',
  'Graphic Design',
  'Customer Relationship Management (CRM)',
  'Financial Analysis',
  'Public Speaking',
  'Negotiation',
  'Problem Solving'
]

export const languages = [
  'English',
  'Spanish',
  'Mandarin Chinese',
  'French',
  'German',
  'Japanese',
  'Portuguese',
  'Russian',
  'Arabic',
  'Italian'
]
export const workTitles = [
  'Software Engineer',
  'Marketing Manager',
  'Data Analyst',
  'Graphic Designer',
  'Sales Executive',
  'Project Manager',
  'Human Resources Specialist',
  'Financial Analyst',
  'Customer Service Representative',
  'Content Writer'
]