import React from "react";

function HatIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      fill="none"
      viewBox="0 0 60 60"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M30 7.5l-28.125 15L30 37.5l28.125-15L30 7.5z"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M13.125 28.5v12.75c0 4.125 7.5 9.375 16.875 9.375s16.875-5.25 16.875-9.375V28.5M58.125 22.5v24.375"
      ></path>
    </svg>
  );
}

export default HatIcon;
