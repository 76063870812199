import { useContext, useState } from 'react'

import style from './Offerings.module.css'
import ArrowUpIcon from '../../../assets/svgs/ArrowUp'
import ArrowDownIcon from '../../../assets/svgs/ArrowDown'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import HatIcon from '../../../assets/svgs/Hat'
import BagIcon from '../../../assets/svgs/Bag'
import EnterpriseIcon from '../../../assets/svgs/Enterprise'
import useWindowWidth from '../../../hooks/useWindowWidth'
import GuestContext from '../../../contexts/GuestContext'
const Offerings = () => {
  const [expandStudent, setExpandStudent] = useState(false)
  const [expandProfessionals, setExpandProfessionals] = useState(false)
  const [expandEnterprises, setExpandEnterprises] = useState(false)
  const { setOpenGuestInfo } = useContext(GuestContext)

  const navigate = useNavigate()
  const innerWidth = useWindowWidth()

  const toggleExpand = (value) => {
    if (value === 'Student') {
      setExpandStudent((prevExpand) => !prevExpand)
    } else if (value === 'Professionals') {
      setExpandProfessionals((prevExpand) => !prevExpand)
    } else if (value === 'Enterprises') {
      setExpandEnterprises((prevExpand) => !prevExpand)
    }
  }

  return (
    <div className={style.mainContainer} id="offerings">
      <div className={style.container}>
        <p className={style.title}>Our Offerings</p>
        <div className={style.cardsContainer}>
          <div className={style.singleCard}>
            <div className={style.imageContainer}>
              <HatIcon />
            </div>
            <div className={style.imageContainerMobile}>
              <HatIcon />
            </div>
            <div className={style.content}>
              {innerWidth <= 768 ? (
                <motion.div
                  className={style.plus}
                  onClick={(e) => {
                    e.stopPropagation()
                    toggleExpand('Student')
                  }}
                  initial={false}
                  animate={{ rotate: expandStudent ? 0 : 180 }}
                  transition={{ duration: 0.3 }}
                >
                  {expandStudent ? (
                    <ArrowUpIcon />
                  ) : (
                    <ArrowDownIcon color={'#CCD2E3'} />
                  )}
                </motion.div>
              ) : (
                <div className={style.plus}>
                  {expandStudent ? (
                    <ArrowUpIcon />
                  ) : (
                    <ArrowDownIcon color={'#CCD2E3'} />
                  )}
                </div>
              )}
              <p className={style.contentTitle}>Chimp for Students</p>
              {innerWidth <= 768 ? (
                <motion.div
                  className={`${style.contentUL} ${
                    expandStudent ? style.expand : ''
                  }`}
                  initial={{ height: 0 }}
                  animate={{ height: expandStudent ? 'auto' : 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <ul style={{ paddingLeft: '30px', width: 'auto' }}>
                    <li className={style.contentLI}>
                      Personalized Career Paths
                    </li>
                    <li className={style.contentLI}>
                      Tailored Learning Resources
                    </li>
                    <li className={style.contentLI}>Networking</li>
                  </ul>
                  <button
                    className={style.cardButton}
                    onClick={() => {
                      setOpenGuestInfo(true)

                      navigate('/dashboard/home')
                      window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                      })
                    }}
                  >
                    Try it now
                  </button>
                </motion.div>
              ) : (
                <div
                  className={`${style.contentUL} ${
                    expandStudent ? style.expand : ''
                  }`}
                >
                  <ul style={{ paddingLeft: '30px' }}>
                    <li className={style.contentLI}>
                      Personalized Career Paths
                    </li>
                    <li className={style.contentLI}>
                      Tailored Learning Resources
                    </li>
                    <li className={style.contentLI}>Networking</li>
                  </ul>
                </div>
              )}
              <button
                className={style.button}
                onClick={() => {
                  setOpenGuestInfo(true)

                  navigate('/dashboard/home')
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                  })
                }}
              >
                Try it now
              </button>
            </div>
          </div>

          <div className={style.singleCard}>
            <div className={style.imageContainer}>
              <BagIcon width={'55px'} height={'60px'} color={'#fff'} />
            </div>
            <div className={style.imageContainerMobile}>
              <BagIcon width={'55px'} height={'60px'} color={'#fff'} />
            </div>
            <div className={style.content}>
              <motion.div
                className={style.plus}
                onClick={(e) => {
                  e.stopPropagation()
                  toggleExpand('Professionals')
                }}
                initial={false}
                animate={{ rotate: expandProfessionals ? 0 : 180 }}
                transition={{ duration: 0.3 }}
              >
                {expandProfessionals ? (
                  <ArrowUpIcon />
                ) : (
                  <ArrowDownIcon color={'#CCD2E3'} />
                )}
              </motion.div>
              <p className={style.contentTitle}>Chimp for Professionals</p>

              {innerWidth <= 768 ? (
                <motion.div
                  className={`${style.contentUL} ${
                    expandProfessionals ? style.expand : ''
                  }`}
                  initial={{ height: 0 }}
                  animate={{ height: expandProfessionals ? 'auto' : 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <ul style={{ paddingLeft: '30px' }}>
                    <li className={style.contentLI}>Skill Assessment</li>
                    <li className={style.contentLI}>Learning Roadmaps</li>
                    <li className={style.contentLI}>Network & Collaborate</li>
                  </ul>
                  <button
                    className={style.cardButton}
                    onClick={() => {
                      setOpenGuestInfo(true)

                      navigate('/dashboard/home')
                      window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                      })
                    }}
                  >
                    Try it now
                  </button>
                </motion.div>
              ) : (
                <div
                  className={`${style.contentUL} ${
                    expandProfessionals ? style.expand : ''
                  }`}
                >
                  <ul style={{ paddingLeft: '30px' }}>
                    <li className={style.contentLI}>Skill Assessment</li>
                    <li className={style.contentLI}>Learning Roadmaps</li>
                    <li className={style.contentLI}>Network & Collaborate</li>
                  </ul>
                </div>
              )}

              <button
                className={style.button}
                onClick={() => {
                  setOpenGuestInfo(true)

                  navigate('/dashboard/home')
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                  })
                }}
              >
                Try it now
              </button>
            </div>
          </div>

          <div className={style.singleCard}>
            <div className={style.imageContainer}>
              <EnterpriseIcon />
            </div>
            <div className={style.imageContainerMobile}>
              <EnterpriseIcon />
            </div>
            <div className={style.content}>
              <motion.div
                className={style.plus}
                onClick={(e) => {
                  e.stopPropagation()
                  toggleExpand('Enterprises')
                }}
                initial={false}
                animate={{ rotate: expandEnterprises ? 0 : 180 }}
                transition={{ duration: 0.3 }}
              >
                {expandEnterprises ? (
                  <ArrowUpIcon />
                ) : (
                  <ArrowDownIcon color={'#CCD2E3'} />
                )}
              </motion.div>
              <p className={style.contentTitle}>Chimp for Enterprises</p>

              {innerWidth <= 768 ? (
                <motion.div
                  className={`${style.contentUL} ${
                    expandEnterprises ? style.expand : ''
                  }`}
                  initial={{ height: 0 }}
                  animate={{ height: expandEnterprises ? 'auto' : 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <ul style={{ paddingLeft: '30px' }}>
                    <li className={style.contentLI}>Talent Discovery</li>
                    <li className={style.contentLI}>Employee Upskilling</li>
                    <li className={style.contentLI}>
                      Innovative Collaboration
                    </li>
                  </ul>
                  <button
                    className={style.cardButton}
                    onClick={() => {
                      setOpenGuestInfo(true)

                      navigate('/dashboard/home')
                      window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                      })
                    }}
                  >
                    Try it now
                  </button>
                </motion.div>
              ) : (
                <div
                  className={`${style.contentUL} ${
                    expandEnterprises ? style.expand : ''
                  }`}
                >
                  <ul style={{ paddingLeft: '30px' }}>
                    <li className={style.contentLI}>Talent Discovery</li>
                    <li className={style.contentLI}>Employee Upskilling</li>
                    <li className={style.contentLI}>
                      Innovative Collaboration
                    </li>
                  </ul>
                </div>
              )}

              <button
                className={style.button}
                onClick={() => {
                  setOpenGuestInfo(true)
                  navigate('/dashboard/home')
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                  })
                }}
              >
                Try it now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Offerings
