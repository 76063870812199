import style from './Profile.module.css'

const StatisticsCard = ({ cardData }) => {
  return (
    <div className={style.statisticsContainer}>
      <div className={style.subContainer}>
        {cardData.map((item, index) => (
          <div key={index} className={style.singleCardStatistics}>
            <p className={style.singleCardStatisticsNumber}>{item.number}</p>
            <p className={style.singleCardStatisticsSubTitle}>{item.title}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default StatisticsCard
