import ChatIcon from '../../../assets/svgs/Chat'
import UserIcon from '../../../assets/svgs/User'
import FileIcon from '../../../assets/svgs/File'
import style from './HowItWorks.module.css'
import videoMobile from '../../../assets/images/mobileVideo.mp4'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import transparentVideo from '../../../assets/images/SinglePhoneScreen.webm'
import AutoPlaySilentVideo from '../video/Video'
import useWindowWidth from '../../../hooks/useWindowWidth'
import GuestContext from '../../../contexts/GuestContext'
const HowItWorks = () => {
  const [finished, setFinished] = useState(false)
  const { setOpenGuestInfo } = useContext(GuestContext)
  const innerWidth = useWindowWidth()
  const navigate = useNavigate()
  const handleVideoPlay = () => {
    if (innerWidth > 1000) {
      setTimeout(() => {
        setFinished(true)
      }, 1500)
    } else {
      setFinished(true)
    }
  }
  return (
    <div className={style.mainConatiner}>
      <video
        autoPlay
        muted
        className={style.backgroundVideo}
        onPlay={handleVideoPlay}
      >
        <source src={transparentVideo} type="video/webm" />
      </video>
      <div className={style.container}>
        <div className={style.leftContainer}>
          <div className={`${style.cards} ${finished ? style.show : ''}`}>
            <p className={style.title}>How Chimp works</p>
            <AutoPlaySilentVideo
              className={style.backgroundMobileVideo}
              src={videoMobile}
            />
            <div className={style.singleCard}>
              <div className={style.cardContent}>
                {' '}
                <div className={style.iconContainer}>
                  <FileIcon />
                </div>
                <div className={style.iconContainerMobile}>
                  <FileIcon />
                </div>
                <p className={style.subTitleMobile}>Upload your Work Profile</p>{' '}
              </div>
              <div className={style.textContainer}>
                <p className={style.subTitle}>Upload your Work Profile</p>{' '}
                <p className={style.text}>
                  Simply upload your resume, link your LinkedIn or GitHub
                  profile, and our AI will do the rest.
                </p>
              </div>
            </div>
            <div className={style.singleCard}>
              <div className={style.cardContent}>
                {' '}
                <div className={style.iconContainer}>
                  <ChatIcon />
                </div>
                <div className={style.iconContainerMobile}>
                  <ChatIcon />
                </div>
                <p className={style.subTitleMobile}>Chat with the AI</p>
              </div>
              <div className={style.textContainer}>
                <p className={style.subTitle}>Chat with the AI</p>
                <p className={style.text}>
                  Use our chat interface to ask any career-related questions or
                  seek advice on your professional journey.
                </p>
              </div>
            </div>
            <div className={style.singleCard}>
              <div className={style.cardContent}>
                {' '}
                <div className={style.iconContainer}>
                  <UserIcon />
                </div>
                <div className={style.iconContainerMobile}>
                  <UserIcon />
                </div>
                <p className={style.subTitleMobile}>
                  Get Personalized Insights
                </p>{' '}
              </div>
              <div className={style.textContainer}>
                {' '}
                <p className={style.subTitle}>Get Personalized Insights</p>{' '}
                <p className={style.text}>
                  Get instant responses based on your profile and access
                  tailored career roadmaps to steer your career in the right
                  direction.
                </p>
              </div>
            </div>
            <div className={style.buttonContainer}>
              <button
                className={style.button}
                onClick={() => {
                  setOpenGuestInfo(true)
                  navigate('/dashboard/home')
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                  })
                }}
              >
                Try it now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default HowItWorks
