import React from "react";

function UserIcon({width, height, color}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "32"}
      height={height ||"32"}
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        stroke={color || "#fff"}
        strokeLinecap="round"
        strokeWidth="2"
        d="M26.303 27.263c-.608-1.701-1.947-3.204-3.81-4.277-1.862-1.072-4.145-1.653-6.493-1.653-2.348 0-4.63.581-6.493 1.654-1.863 1.072-3.202 2.575-3.81 4.276"
      ></path>
      <circle
        cx="16"
        cy="10.667"
        r="5.333"
        stroke={color || "#fff"}
        strokeLinecap="round"
        strokeWidth="2"
      ></circle>
    </svg>
  );
}

export default UserIcon;
