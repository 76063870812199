import React, { useEffect, useRef, useState } from 'react'

import style from '../components/dashboard/profileTab/main/MainProfile.module.css'
import ArrowDownIcon from '../assets/svgs/ArrowDown'
import ArrowUpIcon from '../assets/svgs/ArrowUp'

const CustomDropdown = ({
  options,
  value,
  onChange,
  date,
  checkbox,
  placeHolder
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef(null)
  const toggleDropdown = () => {
    if (checkbox) {
      setIsOpen(false)
    } else {
      setIsOpen(!isOpen)
    }
  }

  const handleOptionSelect = (option) => {
    onChange(option)
    setIsOpen(false)
  }
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])
  return (
    <div className={style.dropdown} ref={ref}>
      <div
        className={`${style.selectedOption} ${isOpen ? style.open : ''} ${
          date ? style.dateinput : ''
        } ${checkbox ? style.disabled : ''}`}
        onClick={toggleDropdown}
      >
        {value !=='Present' && (value !== undefined  && value !=='')? value : placeHolder ? placeHolder : 'select your proficiency'}
        {!checkbox &&
          (isOpen ? (
            <ArrowUpIcon color={'#393D63'} />
          ) : (
            <ArrowDownIcon color={'#393D63'} />
          ))}
      </div>
      {isOpen && (
        <ul
          className={`${style.options} ${isOpen ? style.open : ''} ${
            date ? style.dateinput : ''
          }`}
        >
          {options.map((option) => (
            <li
              key={option}
              onClick={() => handleOptionSelect(option)}
              className={style.option}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default CustomDropdown
