const CloseBubbleIcon = ({color,buble,width,height}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width||"30"}
      height={height||"30"}
      viewBox=  {`0 0 ${height || '30'} ${height || '30'}`   }   
      fill="none"
    >
      <g id="close_ring">
       { !buble ? null :<path
          id="Ellipse 86"
          d="M15 26.25C13.5226 26.25 12.0597 25.959 10.6948 25.3936C9.3299 24.8283 8.08971 23.9996 7.04505 22.955C6.00039 21.9103 5.17172 20.6701 4.60635 19.3052C4.04099 17.9403 3.75 16.4774 3.75 15C3.75 13.5226 4.04099 12.0597 4.60636 10.6948C5.17172 9.3299 6.00039 8.08971 7.04505 7.04505C8.08971 6.00039 9.3299 5.17172 10.6948 4.60635C12.0597 4.04099 13.5226 3.75 15 3.75C16.4774 3.75 17.9403 4.04099 19.3052 4.60636C20.6701 5.17172 21.9103 6.00039 22.955 7.04505C23.9996 8.08971 24.8283 9.3299 25.3936 10.6948C25.959 12.0597 26.25 13.5226 26.25 15C26.25 16.4774 25.959 17.9403 25.3936 19.3052C24.8283 20.6701 23.9996 21.9103 22.9549 22.955C21.9103 23.9996 20.6701 24.8283 19.3052 25.3936C17.9403 25.959 16.4774 26.25 15 26.25L15 26.25Z"
          stroke="#251436"
          strokeWidth="2"
          strokeLinecap="round"
        />}
        <path
          id="Vector 112"
          d="M11.25 11.25L18.75 18.75"
          stroke={color||"#251436"}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          id="Vector 113"
          d="M18.75 11.25L11.25 18.75"
          stroke={color||"#251436"}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    </svg>
  )
}
export default CloseBubbleIcon
