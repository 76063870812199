import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { AuthProvider } from '../src/contexts/AuthContext'
import { FilesProvider } from './contexts/FilesContext'
import { GuestContextProvider } from './contexts/GuestContext'
import { DragProvider } from './contexts/dragContext'
import { UserProfileProvider } from './contexts/UserProfileContext'
import { HistoryProvider } from './contexts/HistoryContext'
import { LayoutProvider } from './contexts/LayoutContext'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    {' '}
    <AuthProvider>
      <LayoutProvider>
        <HistoryProvider>
          <UserProfileProvider>
            <GuestContextProvider>
              <FilesProvider>
                <DragProvider>
                  <App />
                </DragProvider>
              </FilesProvider>
            </GuestContextProvider>
          </UserProfileProvider>
        </HistoryProvider>
      </LayoutProvider>
    </AuthProvider>
  </React.StrictMode>
)

reportWebVitals()
