import { useCallback } from 'react';
import { useDragContext } from '../contexts/dragContext'; // Importing the drag context

const useDraggable = () => {
  // Extracting the isDragging state and setIsDragging function from the drag context
  const { isDragging, setIsDragging } = useDragContext(); 

  // Callback function to handle the start of the drag event
  const handleDragStart = useCallback((e, item) => {
    // Setting the data to be transferred during the drag event
    e.dataTransfer.setData('application/json', JSON.stringify(item));
    // Setting the isDragging state to true
    setIsDragging(true);
  }, [setIsDragging]);

  // Callback function to handle the end of the drag event
  const handleDragEnd = useCallback(() => {
    // Setting the isDragging state to false
    setIsDragging(false);
  }, [setIsDragging]);

  // Returning the properties and event handlers needed to make an element draggable
  return {
    draggable: true, // This makes the element draggable
    onDragStart: handleDragStart, // Event handler for when the drag starts
    onDragEnd: handleDragEnd, // Event handler for when the drag ends
    isDragging, // Current drag state
  };
};

export default useDraggable;
