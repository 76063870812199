import style from './Files.module.css'
import SingleCard from './SingleCard'
import { GeneratedFilesData } from '../../../dummyData'
const GeneratedFiles =()=>{
  
return(
    <div className={style.container} >
        <p className={style.title}>Chimp Generated</p>
        <div className={style.cardsContainer}>
            {GeneratedFilesData.map((item,index)=> (
                <SingleCard key={index} item={item}/>
            ))}
        </div>
        <p className={style.scrollText}>Scroll to see more</p>
    </div>
)
}
export default GeneratedFiles