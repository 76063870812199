import React from 'react'

function FilterIcon({ selected }) {
  return selected ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.9995 3.75C14.9995 3.33579 14.6637 3 14.2495 3H3.74951C3.3353 3 2.99951 3.33579 2.99951 3.75V4.93934C2.99951 5.13825 3.07853 5.32902 3.21918 5.46967L6.52948 8.77996C6.67013 8.92062 6.74915 9.11138 6.74915 9.31029V14.4797C6.74915 14.7357 6.99991 14.9164 7.24273 14.8355L10.9927 13.5855C11.1459 13.5344 11.2491 13.3911 11.2491 13.2297V9.31103C11.2491 9.11211 11.3282 8.92135 11.4688 8.7807L14.7798 5.46967C14.9205 5.32902 14.9995 5.13825 14.9995 4.93934V3.75Z"
        fill="#432C5B"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#393D63"
        d="M7.243 14.835l.158.475-.158-.475zm3.75-1.25l.158.475-.158-.475zM14.78 5.47l.353.353-.353-.353zM6.53 8.78l-.354.354.353-.354zM3.22 5.47l-.354.353.353-.353zm.53-1.97h10.5v-1H3.75v1zM3.5 4.94V3.75h-1v1.19h1zm3.383 3.486l-3.31-3.31-.707.707 3.31 3.31.707-.707zm-.634.884v5.17h1V9.31h-1zm0 5.17a.875.875 0 001.152.83l-.316-.949a.125.125 0 01.164.119h-1zm1.152.83l3.75-1.25-.316-.949-3.75 1.25.316.949zm3.75-1.25a.875.875 0 00.598-.83h-1c0-.054.035-.102.086-.119l.316.949zm.598-.83V9.31h-1v3.919h1zm2.677-8.114l-3.31 3.311.706.707 3.311-3.31-.707-.708zM14.5 3.75v1.19h1V3.75h-1zm.634 2.073a1.25 1.25 0 00.367-.884h-1a.25.25 0 01-.074.177l.707.707zM11.75 9.311a.25.25 0 01.073-.177l-.707-.707a1.25 1.25 0 00-.366.884h1zm-5.573-.177a.25.25 0 01.073.176h1c0-.331-.132-.65-.366-.884l-.707.708zM2.5 4.939c0 .332.131.65.366.884l.707-.707A.25.25 0 013.5 4.94h-1zM14.25 3.5a.25.25 0 01.25.25h1c0-.69-.56-1.25-1.25-1.25v1zm-10.5-1c-.69 0-1.25.56-1.25 1.25h1a.25.25 0 01.25-.25v-1z"
      ></path>
    </svg>
  )
}

export default FilterIcon
