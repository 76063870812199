import React from "react";

function TimeIcon({color}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
    >
      <circle cx="12" cy="12.5" r="9" stroke={color||"#65677D"} strokeWidth="2"></circle>
      <path
        stroke={color||"#65677D"}
        strokeLinecap="round"
        strokeWidth="2"
        d="M16.5 12.5h-4.25a.25.25 0 01-.25-.25V9"
      ></path>
    </svg>
  );
}

export default TimeIcon;
