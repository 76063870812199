import React from "react";

function AddUserIcon({width,height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width||"24"}
      height={height||"24"}
      fill="none"
      viewBox="0 0 24 24"
    >
      <ellipse cx="10" cy="8" fill="#432C5B" rx="5" ry="5"></ellipse>
      <path
        stroke="#432C5B"
        strokeLinecap="round"
        strokeWidth="2"
        d="M19 10v6M22 13h-6"
      ></path>
      <path
        fill="#432C5B"
        d="M17.142 20.383c.462-.105.739-.585.534-1.012-.552-1.15-1.459-2.162-2.634-2.924C13.595 15.508 11.823 15 10 15s-3.595.508-5.042 1.447c-1.175.762-2.082 1.773-2.634 2.924-.205.427.072.907.534 1.012a32.333 32.333 0 0014.284 0z"
      ></path>
    </svg>
  );
}

export default AddUserIcon;
