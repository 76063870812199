import React from "react";

function ErrorIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="9" stroke="#E50000"></circle>
      <path fill="#E50000" d="M12.5 7.5a.5.5 0 11-1 0 .5.5 0 011 0z"></path>
      <path stroke="#E50000" d="M12 17v-7"></path>
    </svg>
  );
}

export default ErrorIcon;
