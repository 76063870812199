import ArrowUpIcon from '../../../../assets/svgs/ArrowUp'
import CheckMark2Icon from '../../../../assets/svgs/CheckMark2'
import ErrorIcon from '../../../../assets/svgs/Error'
import PlusCircleIcon from '../../../../assets/svgs/PlusCircle'
import SmallUserIcon from '../../../../assets/svgs/SmallUser'
import style from './NewEvent.module.css'
const Capacity = ({openCapacity, setOpenCapacity,errors,formData,setFormData,handleFormData}) => {
    const toggleOpacity = () => {
        setOpenCapacity(!openCapacity)
      }
    const handleToggleCapacity = () => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          capacityUnlimited: !prevFormData.capacityUnlimited,
          capacity: !prevFormData.capacityUnlimited ? '0' : prevFormData.capacity
        }))
      }
    return (
        <div
        className={`${style.capacityContainer} ${
          openCapacity ? style.expandedCapacity : null
        }`}
      >
        {openCapacity ? (
          <>
            <p className={style.title}>Capacity</p>
            <div
              style={{ display: 'flex', alignItems: 'center', gap: '20px' }}
            >
              <div
                className={`${style.capacityInput} ${
                  errors.capacity ? style.error : null
                }`}
              >
                {errors.capacity && openCapacity && (
                  <div
                    className={style.errorContainer}
                    style={{ zIndex: '10', top: '8px', right: '5px' }}
                  >
                    <ErrorIcon />
                  </div>
                )}
                <SmallUserIcon color={errors.capacity ? 'red' : null} />
                <input
                  type="number"
                  className={style.numberInput}
                  placeholder="0"
                  value={formData.capacity}
                  name="capacity"
                  onChange={handleFormData}
                />
              </div>{' '}
              <p>or</p>{' '}
              <div>
                <button
                  name="capacityUnlimited"
                  value={formData.capacityUnlimited}
                  onClick={handleToggleCapacity}
                  className={`${style.opacityUnlimited} ${
                    formData.capacityUnlimited
                      ? style.opacityUnlimitedHighlighted
                      : null
                  }`}
                >
                  unlimited
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <p className={style.title}>Capacity</p>
            <p className={style.subtext}>
              {formData.capacity === '' && !formData.capacityUnlimited
                ? 'Use this section to specify how many attendees can attend your event.'
                : `Capacity: ${
                    formData.capacityUnlimited
                      ? 'Unlimited'
                      : formData.capacity
                  }`}
            </p>
          </>
        )}

        <div
          className={`${style.plus} ${
            (formData.capacityUnlimited || formData.capacity !== '') &&
            !openCapacity
              ? style.green
              : null
          }`}
          onClick={toggleOpacity}
        >
          {openCapacity ? (
            <ArrowUpIcon color={'black'} stroke={'2'} />
          ) : (formData.capacityUnlimited || formData.capacity !== '') &&
            !openCapacity ? (
            <CheckMark2Icon />
          ) : (
            <PlusCircleIcon />
          )}
        </div>
      </div>
    )
}
export default Capacity