import { useContext, useState } from 'react'
import Chat from '../chat/Chat'
import DashHeaderChat from '../header/DashHeaderChat'
import Main from './Main/Main'
import Profile from './Profile/Profile'
import { useUserProfileContext }  from '../../../contexts/UserProfileContext'

const ConnectTab = ({user,setSelectedTab}) => {
  const { selectedProfile, setSelectedProfile } = useUserProfileContext(); 
  return (
    <div className="mainDashboard">
      <DashHeaderChat />
      <div className="left">
        <Profile selectedProfile={selectedProfile} setSelectedProfile={setSelectedProfile} user={user}/>
      </div>
      <div className="main">
        <Main selectedProfile={selectedProfile} setSelectedTab={setSelectedTab} user={user}/>
      </div>
      <div className="right chat">
        <Chat/>
      </div>
    </div>
  )
}
export default ConnectTab
