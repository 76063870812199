import React from "react";

function EditIcon({width}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width||"22"}
      height={width||"22"}
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        stroke="#000"
        strokeWidth="1.375"
        d="M7.678 17.352h0l.007-.002.055-.014c.306-.076.577-.143.823-.283.247-.14.444-.337.667-.56l.045-.045 6.336-6.337.068-.067c.307-.307.573-.572.725-.878l-.616-.305.616.305a2.063 2.063 0 000-1.832l-.616.305.616-.305c-.152-.306-.418-.571-.725-.878l-.068-.067-.067-.068c-.307-.307-.572-.573-.878-.724a2.062 2.062 0 00-1.832 0c-.306.151-.571.417-.877.724l-.068.068h0l-.004.004-6.332 6.332-.046.045c-.223.223-.42.42-.56.667l.598.338-.598-.338c-.14.246-.207.517-.283.823l-.016.062-.454 1.816-.009.037c-.054.215-.111.445-.13.639-.021.212-.016.572.272.86.288.287.647.292.86.271.193-.018.423-.076.638-.13l.037-.01 1.816-.453z"
      ></path>
      <path
        fill="#000"
        d="M11.459 6.874l2.75-1.833 2.75 2.75-1.834 2.75-3.666-3.667z"
      ></path>
    </svg>
  );
}

export default EditIcon;