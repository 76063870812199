import React from "react";

function DragIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="13"
      fill="none"
      viewBox="0 0 10 13"
    >
      <path
        fill="#432C5B"
        d="M2.5 3a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM2.5 8a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM2.5 13a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM7.5 3a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM7.5 8a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM7.5 13a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
      ></path>
    </svg>
  );
}

export default DragIcon;
