import { useState, useEffect, useRef } from 'react'
import style from './Chat.module.css'
import ChatInput from './ChatInput'

const ChatInterface = ({
  history,
  loading,
  handleResponses,
  smallInterface,
  setOpenUpload,
  openUpload
}) => {
  const [formData, setFormData] = useState({ message: '' })
  const chatContainerRef = useRef(null)

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }, [history, loading])

  return (
    <div
      className={`${style.chatInterfaceContainerBig} ${
        smallInterface && style.chatInterfaceContainer
      }`}
      ref={chatContainerRef}
    >
      <div
        style={{ display: 'flex', flexDirection: 'column' }}
        className={`${!smallInterface && style.scrollChat}`}
      >
        {history.map((entry, index) => (
          <div key={index} className={style[entry.type]}>
            {entry.content}
          </div>
        ))}
        {loading && <div className={`${style.responsez}`}></div>}
      </div>
      {!smallInterface &&<div className={style.bigInput}>
        <ChatInput
          setOpenUpload={setOpenUpload}
          openUpload={openUpload}
          handleResponses={handleResponses}
          setFormData={setFormData}
          formData={formData}
        />
      </div>}
      
    </div>
  )
}

export default ChatInterface
