import { useEffect, useRef } from 'react'
import style from './HamburgerMenu.module.css'
import Icon from '../../../assets/svgs/Icon'
import SearchIcon from '../../../assets/svgs/Search'
import NewChatIcon from '../../../assets/svgs/NewChat'
import BagIcon from '../../../assets/svgs/Bag'
import MinusIcon from '../../../assets/svgs/Minus'
import CourseIcon from '../../../assets/svgs/CourseDB'

const HamburgerMenuMobile = ({ setOpenMenu, openMenu }) => {
  return (
    <>
      <div
        className={`${style.menuContainer} ${
          openMenu ? style.open : style.closed
        } `}
      >
        <div className={style.header}>
          <div className={style.icon}>
            <Icon color={'#432C5B'} height={30} width={30} />{' '}
          </div>
          <div className={style.searchContainerMobile}>
            <SearchIcon />
            <input
              placeholder="Search for something..."
              className={style.searchInput}
            />
          </div>
        </div>
        <div className={style.added}>
          <p className={style.addedTitle}>Added to Chat</p>
          <div className={style.singleCard}>
            <div className={style.cardIcon}>
              <BagIcon />
            </div>
            <div className={style.content}>
              <p className={style.title}>Title</p>
              <p className={style.subTitle}>Sub title</p>
            </div>
            <div>
              <MinusIcon />
            </div>
          </div>
          <div className={style.singleCard}>
            <div className={style.cardIcon}>
              <BagIcon />
            </div>
            <div className={style.content}>
              <p className={style.title}>Title</p>
              <p className={style.subTitle}>Sub title</p>
            </div>
            <div>
              <MinusIcon />
            </div>
          </div>
          <div className={style.singleCard}>
            <div className={style.cardIcon}>
              <BagIcon />
            </div>
            <div className={style.content}>
              <p className={style.title}>Title</p>
              <p className={style.subTitle}>Sub title</p>
            </div>
            <div>
              <MinusIcon />
            </div>
          </div>
        </div>
        <div className={style.history}>
          <p className={style.historyTitle}>History</p>
          <div>
            <p className={style.historyDate}>Today:</p>
            <div className={style.historyContent}>
              <p className={style.historySubtitle}>Summary of new articles.</p>
              <div>
                <div
                  className={style.iconBubble}
                  style={{ background: '#FFC092' }}
                >
                  <CourseIcon width={10} height={10} />
                </div>
              </div>
            </div>
          </div>
          <div>
            <p className={style.historyDate}>Yesterday:</p>
            <div className={style.historyContent}>
              <p className={style.historySubtitle}>Summary of new articles.</p>
              <div  className={style.icons}>
                <div
                  className={style.iconBubble}
                  style={{ background: '#FFC092' }}
                >
                  <CourseIcon width={10} height={10} />
                </div>
                <div
                  className={style.iconBubble}
                  style={{ background: '#FFC092' }}
                >
                  <CourseIcon width={10} height={10} />
                </div>
                <div
                  className={style.iconBubble}
                  style={{ background: '#FFC092' }}
                >
                  <CourseIcon width={10} height={10} />
                </div>
              </div>
            </div>
          </div>
          <div>
            <p className={style.historyDate}>This week:</p>
            <div className={style.historyContent}>
              <p className={style.historySubtitle}>Summary of new articles.</p>
              <div>
                <div
                  className={style.iconBubble}
                  style={{ background: '#FFC092' }}
                >
                  <CourseIcon width={10} height={10} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.newChatContainer} onClick={()=> setOpenMenu(false)}>
          <p className={style.newChatTitle}>Start a new chat </p>{' '}
          <NewChatIcon />
        </div>
      </div>
      {openMenu && (
        <div
          className={`${style.cover} `}
          onClick={() => setOpenMenu(false)}
        ></div>
      )}
    </>
  )
}

export default HamburgerMenuMobile
