import { useState } from 'react'
import style from './Main.module.css'
import FeedCard from '../../feed/FeedCard'
import { useNavigate } from 'react-router-dom'
import { EventData } from '../../../../dummyData'
const Events = ({ selectedProfile ,setSelectedTab}) => {
  const [selectedFilter, setFilter] = useState('All')
  const navigate = useNavigate()
  const filtersPersonal = ['All', 'Near Me', 'Upcomming', 'Online', 'Saved']
  const filtersMentor = ['All', 'Online', 'Upcomming', 'Over']
  
  const toggleFilter = (value) => {
    setFilter(value)
  }
  return (
    <div className={style.eventsContainer}>
      <p className={style.title}>Events</p>
      <div className={style.filterContainer}>
        <div style={{display :'flex', gap:'10px'}}>
          {selectedProfile === 'Personal'
          ? filtersPersonal.map((filter, index) => (
              <div
                key={index}
                className={`${
                  selectedFilter === filter
                    ? style.selectedFilter
                    : style.filter
                }`}
                onClick={() => toggleFilter(filter)}
              >
                {filter}
              </div>
            ))
          : filtersMentor.map((filter, index) => (
              <div
                key={index}
                className={`${
                  selectedFilter === filter
                    ? style.selectedFilter
                    : style.filter
                }`}
                onClick={() => toggleFilter(filter)}
              >
                {filter}
              </div>
            ))}
        </div>
        
        {selectedProfile === 'Mentor' && (
  <div className={style.newEvent}>
    Create a New Event 
    <div className={style.plus} onClick={() => {
      navigate('create-new-event');
      setSelectedTab('Connect');
    }}>
      +
    </div>
  </div>
)}      </div>
      <div className={style.cardsContainer}>
        {EventData.map((feedItem, index) => {
          return (
            <FeedCard
              key={index}
              feedItem={feedItem}
              index={index}
              events={true}
            />
          )
        })}
      </div>
      <p className={style.scrollText}>Scroll to see more</p>
    </div>
  )
}
export default Events
