import React from "react";

function Arrow2Icon({height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="216"
      height={height||"151"}
      fill="none"
      viewBox="0 0 216 151"
    >
      <path
        fill="#432C5B"
        d="M2.919 149.499a2.5 2.5 0 01-2.418-2.58l.729-22.488a2.5 2.5 0 114.997.162l-.647 19.989 19.99.648a2.499 2.499 0 11-.163 4.997l-22.488-.728zM215.068 5.26C125.502 47.57 79.562 78.67 4.71 148.824l-3.42-3.648C76.517 74.67 122.89 43.274 212.932.74l2.136 4.52z"
      ></path>
    </svg>
  );
}

export default Arrow2Icon;
