import React from 'react'

function FacebookIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      className="facebook-solid-svg"
    >
      <path
        d="M21.024 35H6.65577C5.74105 35 5 34.2585 5 33.3441V6.65576C5 5.74116 5.74116 5 6.65577 5H33.3443C34.2587 5 35 5.74116 35 6.65576V33.3441C35 34.2586 34.2586 35 33.3443 35H25.6996V23.3823H29.5991L30.183 18.8547H25.6996V15.9642C25.6996 14.6533 26.0636 13.76 27.9433 13.76L30.3408 13.759V9.70943C29.9262 9.65425 28.503 9.53098 26.8472 9.53098C23.3905 9.53098 21.024 11.641 21.024 15.5158V18.8547H17.1145V23.3823H21.024V35Z"
        fill="white"
      />
    </svg>
  )
}

export default FacebookIcon
