import style from './ConnectionTabMobile.module.css'
import AddUserIcon from '../../../../assets/svgs/AddUser'
import BagIcon from '../../../../assets/svgs/Bag'
import LocationIcon from '../../../../assets/svgs/Location'
import InfoIcon from '../../../../assets/svgs/InfoIcon'
import { useState } from 'react'
import AcceptIcon from '../../../../assets/svgs/Accept'
import DeclineIcon from '../../../../assets/svgs/Decline'
const SingleCard = ({ connectTab ,user,selectedProfile}) => {
  const [showBubble, setShowBubble] = useState(false)
  const toggleBubble = () => {
    setShowBubble(!showBubble)
  }
  return (
    <div className={style.singleCardContainer}>
      <div className={style.userType}>
        <p className={style.cardText}>{user.type} Profile</p>
        {connectTab === 'connect' ? (
            <div style={{height:'20px'}}>
              <AddUserIcon />
            </div>
          ) : (
            <div style={{ display: 'flex', width: '50px',height:'20px' }}>
              <AcceptIcon /> <DeclineIcon />
            </div>
          )}
      </div>
      <div className={style.userInfoContainer}>
        <div className={style.userImageContainer}>
          <img
            src={user.image}
            alt={user.firstName}
            className={style.userImage}
          />
        </div>
        <div className={style.userInfoWrapper}>
          <div>
            <p className={style.cardText}>
              {user.firstName} {user.lastName}
            </p>
          </div>
          <div style={{ display: 'flex', gap: '30px', width: '100%' }}>
            <div>
              <p className={style.cardSubtext}>
                <BagIcon width={'12px'} /> {user.occupation}
              </p>
              <p className={style.cardSubtext}>
                <LocationIcon
                  width={'12px'}
                  height={'12px'}
                  color={'#65677D'}
                />{' '}
                {user.location}
              </p>
            </div>
            <div className={style.scoreContainer}>
              <div className={style.infoIcon} onClick={toggleBubble}>
                <InfoIcon />
              </div>
              {showBubble && (
                <div className={style.infoBubble}>
                  <div className={style.bubbleAddon}></div>
                  <p className={style.bubbleText}>Chimp Engagement Score</p>
                  <p className={style.bubbleText}>Mentor Score</p>
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <p className={style.score}>CES</p>
                <p className={style.score}>
                  {user.CESscore}/
                  <span className={style.scoreHighlighted}>10</span>
                </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className={style.score}>MS</p>
                <p className={style.score}>
                  {user.MSscore}/
                  <span className={style.scoreHighlighted}>10</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.cardSubtext}>{user.description}</div>
      <div className={style.buttonContainer}>
        <button className={style.viewButton}>View profile</button>
        <button className={style.addButton}>Add to chat</button>
      </div>
    </div>
  )
}
export default SingleCard
