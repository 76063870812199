import { useState } from 'react'
import CloseBubbleIcon from '../../../../assets/svgs/CloseBubble'
import PlusCircleIcon from '../../../../assets/svgs/PlusCircle'
import style from './MainProfile.module.css'
const Profiles = ({ userId, userProfile, toggleModal, owner }) => {
    const [selectedProfile, setSelectedProfile]=useState('Personal')
  return (
    <div className={style.wrapper}>
      <div className={style.componentHeader}>
        <p className={style.title}>Profile Configuration</p>
          <div style={{ display: 'flex' }}>
            <div className={style.plus}>
              <PlusCircleIcon color={'white'} />
            </div>
            <div>
              <CloseBubbleIcon color="#393D63" height={20} width={20} />
            </div>
          </div>
    
      </div>
      <div className={style.profiles}>
        <div
          className={`${style.profile} ${
            selectedProfile === 'Personal' ? style.selected : null
          }`}
          onClick={() => setSelectedProfile('Personal')}
        >
          Personal Profile
        </div>
        <div
          className={`${style.profile} ${
            selectedProfile === 'Mentor' ? style.selected : null
          }`}
          onClick={() => setSelectedProfile('Mentor')}
        >
          Mentor Profile
        </div>
      </div>
        <div className={style.profileList}>
            <div className={style.profileCard}>
                <p className={style.profileCardTitle}>{userProfile?.user.user_title}</p>
                <p className={style.profileCardSubtitle}>Custom</p>
            </div>
        </div>
    </div>
  )
}
export default Profiles
