/*Mesaage after succesful event creation */
import { useEffect, useState } from 'react'
import CheckMarkIcon from '../assets/svgs/Checkmark'
import style from '../components/dashboard/header/DashHeader.module.css'
const SuccessfulMessage = ({ message, setMessage }) => {
  const [showMessage, setShowMessage] = useState(false)
  useEffect(() => {
    if (message !== '') {
      setShowMessage(true)
      const timer = setTimeout(() => {
        setShowMessage(false)
        setTimeout(() => setMessage(''), 500)
      }, 3000)
      return () => clearTimeout(timer)
    }
  }, [message])
  return (
    <div
      className={`${style.successfulMessage} ${
        showMessage ? style.show : style.hide
      }`}
    >
      <CheckMarkIcon /> {message !== '' && message}
    </div>
  )
}
export default SuccessfulMessage
