import { useState } from 'react'
import style from './Register.module.css'
import PersonalForm from './PersonalForm'
import EnterpriseForm from './EnterpriseForm'
import CloseBubbleIcon from '../../../assets/svgs/CloseBubble'
const Register = ({ handleLogin, setOpenAuth }) => {
  const [accountType, setAccountType] = useState('Personal')
  const handleAccountType = (type) => {
    setAccountType(type)
  }

  return (
    <div className={style.mainContainer}>
      <div className={style.container}>
        <div className={style.closeBubble} onClick={() => handleLogin()}>
          <CloseBubbleIcon />
        </div>
        <h2 className={style.title}>Sign up</h2>
        <div className={style.accountTypeMain}>
          <p className={style.accountTypeTitle}>Choose account type:</p>
          <div className={style.accountTypeContainer}>
            <div
              className={
                accountType === 'Personal'
                  ? style.highlight1
                  : style.accountType1
              }
              onClick={() => handleAccountType('Personal')}
            >
              Personal
            </div>
            <div
              className={
                accountType === 'Enterprise'
                  ? style.highlight2
                  : style.accountType2
              }
              onClick={() => handleAccountType('Enterprise')}
            >
              Enterprise
            </div>
          </div>
        </div>
        {accountType === 'Personal' ? (
          <PersonalForm setOpenAuth={setOpenAuth} />
        ) : (
          <EnterpriseForm setOpenAuth={setOpenAuth} />
        )}
      </div>
    </div>
  )
}
export default Register
