import { useContext, useEffect, useState } from 'react'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import DashHeader from '../components/dashboard/header/DashHeader'
import DashHome from '../components/dashboard/homeTab/Home'
import DashChatTab from '../components/dashboard/ChatTab/DashChatTab'
import ConnectTab from '../components/dashboard/ConnectTab/ConnectTab'
import NewEvent from '../components/dashboard/ConnectTab/NewEvent/NewEvent'
import ConnectTabMobile from '../components/dashboard/ConnectTab/ConectionTabMobile'
import PersonalCharts from '../components/dashboard/ConnectTab/MainMobile/PersonalCharts'
import MentorCharts from '../components/dashboard/ConnectTab/MainMobile/MentorCharts'
import WelcomeModal from '../utils/WelcomeModal'
import GuestContext from '../contexts/GuestContext'
import Auth from '../utils/AuthModal'
import style from '../components/home/header/Header.module.css'
import { useAuthContext } from '../contexts/AuthContext'
import useWindowWidth from '../hooks/useWindowWidth'
import UploadModal from '../components/dashboard/uploadModal/UploadModal'
import ProfileTab from '../components/dashboard/profileTab/ProfileTab'
const Dashboard = ({
  openReg,
  setOpenReg,
  setOpenAuth,
  openAuth,
  handleAuth
}) => {
  const [selectedTab, setSelectedTab] = useState('Home')
  const [user, setUser] = useState(null)
  const { guestUser, setGuestUser, openGuestInfo, setOpenGuestInfo } =
    useContext(GuestContext)
  const navigate = useNavigate()
  const location = useLocation()
  const { isAuthenticated, setIsAuthenticated ,getCookie} = useAuthContext()
  const innerWidth = useWindowWidth()
  const url = process.env.REACT_APP_API_URL || '';
  console.log('this is url:', url)
  const handleSelectTab = (value) => {
    setSelectedTab(value)
    navigate(`/dashboard/${value.toLowerCase()}`)
    if(value === 'Profile'){
      setSelectedTab(value)
      navigate(`/dashboard/${value.toLowerCase()}?id=${user.user.user_id}`)
    }
  }
  useEffect(() => {
    if (isAuthenticated) {
      const token = getCookie('token');
      console.log('Fetching user data...');
      fetch(`${url}/users/user-data`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setUser(data);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
    }
  }, [isAuthenticated]);
  


  useEffect(() => {
    const path = location.pathname.split('/')[2]
    if (path) {
      const capitalizedPath = path.charAt(0).toUpperCase() + path.slice(1)
      if (capitalizedPath !== selectedTab) {
        setSelectedTab(capitalizedPath)
      }
    } else {
      setSelectedTab('Home')
      navigate('/dashboard/home')
    }
  }, [location.pathname, selectedTab, navigate])

  return (
    <div className="dashboard">
      <DashHeader
        selectedTab={selectedTab}
        handleSelectTab={handleSelectTab}
        setOpenGuestInfo={setOpenGuestInfo}
        openGuestInfo={openGuestInfo}
        openReg={openReg}
        setOpenReg={setOpenReg}
        openAuth={openAuth}
        setOpenAuth={setOpenAuth}
        handleAuth={handleAuth}
        user={user}
      />
      {guestUser && openGuestInfo && (
        <div
          style={{
            width: 'calc(100vw - 40px)',
            position: 'absolute',
            top: '80px',
            height: 'calc(100vh - 90px)',
            margin: innerWidth > 768 ? '20px' : '10px',
            marginBottom: '0',
            zIndex: '20',
            borderRadius: '10px',
            marginTop: '0',
            overflow: 'scroll',
            background: 'white'
          }}
          className="dashWelcome"
        >
          <WelcomeModal
            setOpenGuestInfo={setOpenGuestInfo}
            openGuestInfo={openGuestInfo}
            setGuestUser={setGuestUser}
            openReg={openReg}
            setOpenReg={setOpenReg}
            openAuth={openAuth}
            setOpenAuth={setOpenAuth}
            handleAuth={handleAuth}
            innerWidth={innerWidth}
          />
        </div>
      )}

      <Routes>
        <Route
          path="home"
          element={
            <DashHome
              selectedTab={selectedTab}
              handleSelectTab={handleSelectTab}
              user={user}
            />
          }
        />
        <Route path="chat" element={<DashChatTab user={user} />} />
        <Route
          path="connect"
          element={
            innerWidth > 768 ? (
              <ConnectTab user={user} setSelectedTab={setSelectedTab} />
            ) : (
              <ConnectTabMobile user={user} setSelectedTab={setSelectedTab} />
            )
          }
        />
        <Route
          path="connect/create-new-event"
          element={<NewEvent user={user} setSelectedTab={setSelectedTab} />}
        />
        <Route
          path="connect/personal-chart"
          element={
            <PersonalCharts user={user} setSelectedTab={setSelectedTab} />
          }
        />
        <Route
          path="connect/mentor-chart"
          element={<MentorCharts user={user} setSelectedTab={setSelectedTab} />}
        />
        <Route path="/upload" element={<UploadModal />} />
        <Route path="/profile" element={<ProfileTab  user={user}/>} />

        <Route
          path="*"
          element={
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            >
              Coming soon
            </div>
          }
        />
      </Routes>
      {openReg && (
        <div className={style.form}>
          <Auth
            handleLogin={handleAuth}
            openReg={openReg}
            openAuth={openAuth}
            setOpenAuth={setOpenAuth}
          />
        </div>
      )}
    </div>
  )
}

export default Dashboard
