import { useLocation } from 'react-router-dom'
import useWindowWidth from '../../../hooks/useWindowWidth'
import ChatBig from '../chat/ChatBig'
import FeedSmall from '../feed/FeedSmall'
import GeneratedFiles from '../files/Generated'
import UploadedFiles from '../files/Uploads'
import DashHeaderChat from '../header/DashHeaderChat'
import History from '../history/History'

const DashChatTab = () => {
  const innerWidth = useWindowWidth();
  return (
    <div className="mainDashboard">
      <DashHeaderChat screenWidth={innerWidth}/>
      <div className="left" style={{display: innerWidth < 768 &&'none'}}>
        <FeedSmall />

        <History />
      </div>
      <div className="main">
        <ChatBig />
      </div>
      <div className="right chat" style={{display: innerWidth < 768 &&'none'}}>
        <GeneratedFiles />
        <UploadedFiles />
      </div>
    </div>
  )
}
export default DashChatTab
