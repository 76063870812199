import React, { useEffect, useRef, useState } from 'react'
import style from './MainProfile.module.css'
import cover from '../../../../assets/images/cover.png'
import LocationIcon2 from '../../../../assets/svgs/Location2'
import UserIcon from '../../../../assets/svgs/User'
import InfoIcon from '../../../../assets/svgs/InfoIcon'
import About from './About'
import Experience from './Experience'
import Education from './Education'
import Skills from './Skills'
import Languages from './Languages'
import { useLocation } from 'react-router-dom'
import { useLayoutContext } from '../../../../contexts/LayoutContext'
import EditIcon from '../../../../assets/svgs/EditIcon'
import AddImageIcon from '../../../../assets/svgs/AddImage'
import useWindowWidth from '../../../../hooks/useWindowWidth'

const MainProfile = ({ userProfile, toggleModal, openModal, owner }) => {
  const ref = useRef(null)
  const [showBubble, setShowBubble] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const location = useLocation()
  const { layout, setProfileId, fetchProfileLayout, setLayout } =
    useLayoutContext()
  const { saveLayout } = useLayoutContext()
  const userId = new URLSearchParams(location.search).get('id')
  console.log('user profile in main ', userProfile)
  const editModelToggle = () => {
    setEditMode(!editMode)
  }
  const innerWidth = useWindowWidth()
  console.log(innerWidth)
  useEffect(() => {
    fetchProfileLayout(userId)
  }, [])

  const [draggingIndex, setDraggingIndex] = useState(null)
  const [dragOverIndex, setDragOverIndex] = useState(null)

  const toggleBubble = () => setShowBubble(!showBubble)

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowBubble(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [ref])

  const handleDragStart = (index) => {
    setDraggingIndex(index)
  }

  const handleDragOver = (index) => {
    if (draggingIndex === null) return
    setDragOverIndex(index)
  }

  const handleDrop = (index) => {
    if (draggingIndex === null) return

    const updatedComponents = [...layout]
    const [draggedComponent] = updatedComponents.splice(draggingIndex, 1)
    updatedComponents.splice(index, 0, draggedComponent)

    updatedComponents[index].type = 'half'
    if (index + 1 < updatedComponents.length) {
      updatedComponents[index + 1].type = 'half'
    }

    setLayout(updatedComponents)
    saveLayout(updatedComponents, userProfile.user.user_id) // Save the updated layout
    setDraggingIndex(null)
    setDragOverIndex(null)
  }

  const handleDragEnd = () => {
    setDraggingIndex(null)
    setDragOverIndex(null)
  }

  const handleDoubleClick = (index) => {
    const updatedComponents = [...layout]
    updatedComponents[index].type = 'full'
    setLayout(updatedComponents)
    saveLayout(updatedComponents, userProfile.user.user_id)
  }
  const renderComponent = (id) => {
    switch (id) {
      case '1':
        return (
          <div
            draggable={owner && editMode ? true : false}
            style={{ height: '100%', width: '100%' }}
          >
            <About
              userProfile={userProfile}
              toggleModal={toggleModal}
              openModal={openModal}
              owner={owner}
              editMode={editMode}
              removeComponent={removeComponent}
            />
          </div>
        )
      case '2':
        return (
          <div
            draggable={owner && editMode ? true : false}
            style={{ height: '100%' }}
          >
            <Experience
              userProfile={userProfile}
              toggleModal={toggleModal}
              openModal={openModal}
              owner={owner}
              editMode={editMode}
              removeComponent={removeComponent}
            />
          </div>
        )
      case '3':
        return (
          <div
            draggable={owner && editMode ? true : false}
            style={{ height: '100%' }}
          >
            <Education
              userProfile={userProfile}
              toggleModal={toggleModal}
              openModal={openModal}
              owner={owner}
              editMode={editMode}
              removeComponent={removeComponent}
            />
          </div>
        )
      case '4':
        return (
          <div
            draggable={owner && editMode ? true : false}
            style={{ height: '100%', width: '100%' }}
          >
            <Skills
              userProfile={userProfile}
              toggleModal={toggleModal}
              openModal={openModal}
              owner={owner}
              editMode={editMode}
              removeComponent={removeComponent}
            />
          </div>
        )
      case '5':
        return (
          <div
            draggable={owner && editMode ? true : false}
            style={{ height: '100%' }}
          >
            <Languages
              userProfile={userProfile}
              toggleModal={toggleModal}
              openModal={openModal}
              owner={owner}
              editMode={editMode}
              removeComponent={removeComponent}
            />
          </div>
        )
      default:
        return null
    }
  }
  const removeComponent = (id) => {
    const updatedLayout = layout.filter((item) => item.id !== id)
    setLayout(updatedLayout)
    saveLayout(updatedLayout, userId)
  }

  return (
    <div className={style.container}>
      <div className={style.coverWrapper}>
        <div className={style.iconContainer}>
          <UserIcon
            width={innerWidth < 768 ? '30' : '100'}
            height={innerWidth < 768 ? '30' : '100'}
            color={'#ffffff'}
          />
          <div className={style.addImage}>
            <AddImageIcon             width={innerWidth < 768 ? '30' : '100'}
            height={innerWidth < 768 ? '30' : '100'}/>
          </div>
        </div>
        <img src={cover} className={style.coverImage} alt="cover" />
        <div className={style.imageCover}></div>
        <div className={style.userInfo}>
          <div className={style.userDetails}>
            <div style={{ display: 'flex', gap: '10px' }}>
              <p className={style.userName}>
                {userProfile && userProfile?.user.first_name}{' '}
                {userProfile?.user.last_name}
              </p>
              {owner && editMode && (
                <div
                  onClick={() => toggleModal('profile', userProfile.user)}
                  className={style.profileEdit}
                >
                  <EditIcon />
                </div>
              )}
            </div>

            <p className={style.userOccupation}>
              {userProfile.user.user_title}
            </p>
            {userProfile && (
              <div className={style.userLocations}>
                <LocationIcon2 width={20} />
                {userProfile?.user.user_location}
              </div>
            )}
          </div>
          <div className={style.scoreContainer}>
            <div className={style.infoIcon} onClick={toggleBubble} ref={ref}>
              <InfoIcon />
            </div>
            {showBubble && (
              <div className={style.infoBubble}>
                <div className={style.bubbleAddon}></div>
                <p className={style.bubbleText}>
                  “Chimp Engagement Score” measures your level of activity and
                  interaction within the Chimp platform. It takes into account
                  the number of interactions you have with Chimp, connections
                  made with other users, participation in events, and the
                  suggestions or recommendations from Chimp that you have
                  followed. This score reflects how engaged you are with the
                  platform and its community.
                </p>
                {owner && (
                  <p className={style.bubbleText}>
                    “Mentor Score” evaluates your effectiveness and activities
                    as a mentor on the Chimp platform. It is determined by the
                    number of events you organize, the number of participants
                    who attend your events, and the positive feedback you
                    receive from the participants.
                  </p>
                )}
              </div>
            )}
            <div />
            <p className={style.score}>
              CES 7/<span className={style.scoreHighlighted}>10</span>
            </p>
            {owner && (
              <p className={style.score}>
                MS 9/<span className={style.scoreHighlighted}>10</span>
              </p>
            )}

            {owner && (
              <button
                className={style.button}
                onClick={() => setEditMode(!editMode)}
              >
                {editMode ? 'Save' : 'Edit'}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className={style.gridContainer}>
        {layout?.map((item, index) => (
          <div
            key={index}
            onDragStart={() => handleDragStart(index)}
            onDragOver={(e) => {
              e.preventDefault()
              handleDragOver(index)
            }}
            onDrop={() => handleDrop(index)}
            onDragEnd={handleDragEnd}
            onDoubleClick={() => handleDoubleClick(index)}
            className={`${style.gridItem} ${
              item.type === 'half' ? style.twoPerRow : ''
            } ${dragOverIndex === index ? style.shrink : ''} ${
              draggingIndex === index ? style.expand : ''
            }`}
          >
            {renderComponent(item.id)}
          </div>
        ))}
      </div>
      {layout?.length <= 0 && (
        <p className={style.emptyProfile}>
          {owner ? 'Your' : `User's`} profile is empty
        </p>
      )}
    </div>
  )
}

export default MainProfile
