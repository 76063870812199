import React from "react";

function FolderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#fff"
        strokeWidth="2"
        d="M4 8c0-1.886 0-2.828.586-3.414C5.172 4 6.114 4 8 4h.343c.818 0 1.226 0 1.594.152.368.152.657.442 1.235 1.02l.656.656c.579.578.867.868 1.235 1.02.368.152.776.152 1.594.152H16c1.886 0 2.828 0 3.414.586C20 8.172 20 9.114 20 11v4c0 1.886 0 2.828-.586 3.414C18.828 19 17.886 19 16 19H8c-1.886 0-2.828 0-3.414-.586C4 17.828 4 16.886 4 15V8z"
      ></path>
      <path
        stroke="#fff"
        strokeMiterlimit="1.414"
        strokeWidth="2"
        d="M5 11h14"
      ></path>
    </svg>
  );
}

export default FolderIcon;
