const HandleUpload = async (selectedFile, userId, isAuthenticated, setMessage) => {
  const url = process.env.REACT_APP_API_URL || '';
  if (!selectedFile) {
    setMessage('No file selected');
    return;
  }

  const formData = new FormData();
  formData.append('file', selectedFile);
  formData.append('userId', userId);

  try {
    const response = await fetch(`${url}/users/upload-save`, {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Error uploading file');
    }

    const data = await response.json();
    setMessage(data.message);
  } catch (error) {
    console.error('Error uploading file:', error);
    setMessage('Error uploading file.');
  }
};

export default HandleUpload;
