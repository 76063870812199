import React from "react";

function UserIcon({stroke,color}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={color||"#292929"}
        strokeWidth={stroke||"1"}
        strokeLinecap="round"
        d="M19.727 20.447c-.455-1.276-1.46-2.403-2.857-3.207C15.473 16.436 13.761 16 12 16c-1.761 0-3.473.436-4.87 1.24-1.397.804-2.402 1.931-2.857 3.207"
      ></path>
      <circle
        cx="12"
        cy="8"
        r="4"
        stroke={color||"#292929"}
        strokeLinecap="round"
        strokeWidth={stroke||"1"}
      ></circle>
    </svg>
  );
}

export default UserIcon;
