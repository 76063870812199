import { Swiper, SwiperSlide } from 'swiper/react'
import { UsersData } from '../../../../dummyData'
import 'swiper/css'
import 'swiper/css/effect-cards'
import { EffectCards } from 'swiper/modules'
import SingleCard from './SingleCard'

const CardSwiper = ({ selectedFilter, connectTab, selectedProfile }) => {
  const filteredItems = UsersData.filter((user) =>
    selectedFilter !== 'All' ? user.type === selectedFilter : true
  )

  return (
    <>
      <Swiper
        effect={'cards'}
        grabCursor={true}
        modules={[EffectCards]}
        className="mySwiper"
        loop={true}
      >
        {filteredItems.map((user, index) => (
          <SwiperSlide key={`${user.firstName}-${user.lastName}-${index}`}>
            <SingleCard
              user={user}
              connectTab={connectTab}
              selectedProfile={selectedProfile}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}
export default CardSwiper
