import { useLocation, useNavigate } from 'react-router-dom'
import ArrowLeftIcon from '../../../../assets/svgs/ArrowLeft'
import style from './NewEvent.module.css'
import { useEffect, useState } from 'react'
import 'react-quill/dist/quill.snow.css'
import 'react-datepicker/dist/react-datepicker.css'
import ImagesUpload from './ImagesUpload'
import EventTitle from './EventTitle'
import LocationAndDate from './LoacationAndDate'
import Capacity from './Capacity'
import Description from './Description'
import GoogleMapsProvider from './GoogleMapsProvider'
import SuccessfullIcon from '../../../../assets/svgs/Successfull'
import EventIcon from '../../../../assets/svgs/EventDB'
import LocationIcon from '../../../../assets/svgs/Location'
import URLIcon from '../../../../assets/svgs/UrlIcon'
import TimeIcon from '../../../../assets/svgs/Time'
import SmallUserIcon from '../../../../assets/svgs/SmallUser'
import useWindowWidth from '../../../../hooks/useWindowWidth'
const NewEvent = () => {
  const location = useLocation()
  const innerWidth = useWindowWidth()
  const [files, setFiles] = useState([])
  const [openUpload, setOpenUpload] = useState(false)
  const [openEventTitle, setOpenEventTitle] = useState(false)
  const [openCapacity, setOpenCapacity] = useState(false)
  const [openDescription, setOpenDescription] = useState(false)
  const [openLocation, setOpenLocation] = useState(false)
  const [value, setValue] = useState('')
  const [successfull, setSuccessfull] = useState(false)
  const [toggleButton, setToggleButton] = useState('venue')
  const [errors, setErrors] = useState({
    title: false,
    summary: false,
    date: false,
    startTime: false,
    endTime: false,
    onlineUrl: false,
    address: false,
    description: false,
    capacity: false
  })
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      description: value
    }))
  }, [value])
  const [formData, setFormData] = useState({
    video: '',
    title: '',
    summary: '',
    capacity: '',
    capacityUnlimited: false,
    description: value,
    startTime: '',
    endTime: '',
    onlineUrl: '',
    address: '',
    date: ''
  })

  const handleFormData = (e) => {
    const { name, value } = e.target
    if (name === 'capacity') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        capacity: value,
        capacityUnlimited: false
      }))
      setErrors((prev) => ({ ...prev, [name]: false }))
    }
    if (name === 'onlineUrl') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        onlineUrl: value,
        address: ''
      }))
      setErrors((prev) => ({ ...prev, [name]: false }))
    }
    if (name === 'address') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        address: value,
        onlineUrl: ''
      }))
      setErrors((prev) => ({ ...prev, [name]: false }))
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
    setErrors((prev) => ({ ...prev, [name]: false }))
  }

  const navigate = useNavigate()
  const backToConnect = () => {
    setTimeout(() => {
      navigate('/dashboard/connect')
      setValue('')
      setFormData({
        video: '',
        title: '',
        summary: '',
        capacity: '',
        capacityUnlimited: false,
        description: value,
        startTime: '',
        endTime: '',
        onlineUrl: '',
        address: '',
        date: ''
      })
    }, 3000)
  }
  const validateForm = () => {
    return {
      title: !formData.title,
      summary: !formData.summary,
      date: !formData.date,
      startTime: !formData.startTime,
      endTime: !formData.endTime,
      onlineUrl: toggleButton === 'online' && !formData.onlineUrl,
      address: toggleButton === 'venue' && !formData.address,
      description: !formData.description,
      capacity: !formData.capacity
    }
  }
  const handleSubmit = (e) => {
    const validationErrors = validateForm()
    setErrors(validationErrors)
    e.preventDefault()
    const hasErrors = Object.values(validationErrors).some((error) => error)
    if (hasErrors) {
      if (errors.title || errors.summary) {
        setOpenEventTitle(true)
      }
      if (
        errors.startTime ||
        errors.endTime ||
        errors.date ||
        errors.onlineUrl ||
        errors.address
      ) {
        setOpenLocation(true)
      }
      if (errors.capacity) setOpenCapacity(true)
      if (errors.description) {
        setOpenDescription(true)
      }
    } else {
      setSuccessfull(true)
      backToConnect()
    }
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString)
    return date.toDateString()
  }
  const formattedDate = formatDate(formData.date)
  return (
    <>
      {successfull ? (
        <div
          className={` ${
            innerWidth < 768
              ? style.newEventContainerSuccessfullMobile
              : style.newEventContainerSuccessfull
          }`}
        >
          <SuccessfullIcon width={'200px'} height={'200px'} />
          <p className={style.successfullText}>
            Your event has been created successfully
          </p>
          <div className={style.successfullContainer}>
            <div className={style.successfullWrapper}>
              <EventIcon color={'#393D63'} /> {formData.title}
            </div>
            {formData.address === '' ? (
              <div className={style.successfullWrapper}>
                <URLIcon color={'#393D63'} />
                <a href={formData.onlineUrl} target="_blank" rel="noreferrer">
                  Online
                </a>
              </div>
            ) : (
              <div className={style.successfullWrapper}>
                <LocationIcon color={'#393D63'} /> {formData.address}
              </div>
            )}
            <div>
              <div className={style.successfullWrapper}>
                <TimeIcon color={'#393D63'} />
                {formattedDate}
              </div>
              <div className={style.successfullWrapper}>
                <SmallUserIcon color={'#393D63'} />{' '}
                {formData.capacityUnlimited ? 'Unlimited' : formData.capacity}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div
            className={` ${
              innerWidth < 768
                ? style.newEventContainerMobile
                : style.newEventContainer
            }`}
          >
            <p
              className={style.back}
              onClick={() => navigate('/dashboard/connect')}
            >
              <ArrowLeftIcon /> Back to Connect
            </p>
            <p className={style.title}>Create your event</p>
            <p className={style.subTitle}>
              Add all of your event details and let attendees know what to
              expect
            </p>
            <div className={style.scrollable}>
              <ImagesUpload
                openUpload={openUpload}
                setOpenUpload={setOpenUpload}
                files={files}
                setFiles={setFiles}
                formData={formData}
                handleFormData={handleFormData}
              />
              <EventTitle
                openEventTitle={openEventTitle}
                errors={errors}
                handleFormData={handleFormData}
                formData={formData}
                setOpenEventTitle={setOpenEventTitle}
              />
              <GoogleMapsProvider>
                <LocationAndDate
                  errors={errors}
                  setErrors={setErrors}
                  openLocation={openLocation}
                  setOpenLocation={setOpenLocation}
                  formData={formData}
                  setFormData={setFormData}
                  setToggleButton={setToggleButton}
                  toggleButton={toggleButton}
                  handleFormData={handleFormData}
                />
              </GoogleMapsProvider>

              <Capacity
                openCapacity={openCapacity}
                errors={errors}
                formData={formData}
                setFormData={setFormData}
                handleFormData={handleFormData}
                setOpenCapacity={setOpenCapacity}
              />
              <Description
                openDescription={openDescription}
                errors={errors}
                value={value}
                setValue={setValue}
                formData={formData}
                setOpenDescription={setOpenDescription}
              />
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center'
                }}
              >
                {' '}
                <button
                  className={style.createButtonMobile}
                  onClick={handleSubmit}
                >
                  Create Event
                </button>
              </div>
            </div>
          </div>
          <button className={style.createButton} onClick={handleSubmit}>
            Create Event
          </button>
        </>
      )}
    </>
  )
}

export default NewEvent
