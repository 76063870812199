import React from "react";

function CheckBoxIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <rect width="20" height="20" fill='#251436' rx="4"></rect>
      <path
        fill="white"
        fillRule="evenodd"
        d="M14.64 6.473a1 1 0 01.128 1.408l-3.598 4.317-.037.045c-.295.355-.58.696-.85.943-.304.276-.71.546-1.26.571-.549.025-.978-.208-1.305-.455-.292-.221-.606-.535-.933-.862l-.04-.04-1.452-1.452a1 1 0 011.414-1.414l1.452 1.451c.382.383.597.595.765.722a.18.18 0 00.007.004l.006-.005c.155-.141.35-.372.697-.788l3.598-4.317a1 1 0 011.408-.128z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default CheckBoxIcon;
