import { useState } from 'react'
import HamburgerMobileIcon from '../../../assets/svgs/HamburgerMobile'
import SearchIcon from '../../../assets/svgs/Search'
import UserIcon from '../../../assets/svgs/User'
import style from './DashHeader.module.css'
import HamburgerMenuMobile from './HamburgerMenu'
import { useLocation } from 'react-router-dom'
import StartYourJourrney from '../../../utils/StartJourney'
import useWindowWidth from '../../../hooks/useWindowWidth'

const DashHeaderChat = ({ screenWidth }) => {
  const [openMenu, setOpenMenu] = useState(false)
const innerWidth = useWindowWidth()
    const location = useLocation()
  const handleToggleMenu = () => {
    setOpenMenu(!openMenu)
  }

  return (
    <>
      <div
        className={style.chatHeaderContainer}
        style={{
          display:
            location.pathname !== '/dashboard/chat' || screenWidth > 600
              ? 'none'
              : 'flex'
        }}
      >
        <div onClick={handleToggleMenu}>
          <HamburgerMobileIcon />
        </div>
        <div className={style.searchContainerMobile}>
          <SearchIcon />
          <input
            placeholder="Search for something..."
            className={style.searchInputMobile}
          />
        </div>

        <div className={style.mobileIconContainer}>
          <UserIcon width={'20'} height={'20'} color={'#ffffff'} />
        </div>

        <HamburgerMenuMobile openMenu={openMenu} setOpenMenu={setOpenMenu} />
      </div>
      {innerWidth < 768 &&   location.pathname === '/dashboard/chat' &&   <StartYourJourrney/>}

    </>
  )
}

export default DashHeaderChat
