import React from 'react'

function LocationIcon({color,height,width}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width||"24"}
      height={height||"25"}
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M19.5 12.9819C19.5 17.9999 14.0117 21.3846 12.4249 22.2583C12.1568 22.4059 11.8432 22.4059 11.5751 22.2583C9.98831 21.3846 4.5 17.9999 4.5 12.9819C4.5 8.48193 8.13401 5.48193 12 5.48193C16 5.48193 19.5 8.48193 19.5 12.9819Z"
        stroke={color||"white"}
        strokeWidth={2}
      />
      <circle cx="12" cy="12.9819" r="3.5" stroke={color||"white"}         strokeWidth={2}/>
    </svg>
  )
}

export default LocationIcon
