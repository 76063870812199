import style from './Main.module.css'
import { useState } from 'react'
import UserCard from './UserCard'
import ArrowRightIcon from '../../../../assets/svgs/ArrowRight'
import ArrowLeftIcon from '../../../../assets/svgs/ArrowLeft'
import { UsersData } from '../../../../dummyData'
const Slider = ({ selectedTab, selectedFilter }) => {
  const [startIndex, setStartIndex] = useState(0)
  
  const filteredItems = UsersData.filter((user) =>
    selectedFilter !== 'All' ? user.type === selectedFilter : true
  )

  const totalProducts = filteredItems.length

  const nextSlide = () => {
    setStartIndex((startIndex + 1) % totalProducts)
  }

  const prevSlide = () => {
    setStartIndex((startIndex - 1 + totalProducts) % totalProducts)
  }
  return (
    <>
      <div onClick={nextSlide} className={style.buttonNext}>
        <ArrowRightIcon height={40} width={40} />
      </div>
      <div onClick={prevSlide} className={style.buttonPrev}>
        <ArrowLeftIcon height={40} width={40} />
      </div>

      <div style={{ width: '95%', filter: 'blur(4px)', borderRadius: '10px' ,marginTop:'25px'}}>
        <UserCard user={filteredItems[(startIndex + 1) % totalProducts]} height={'80px'}/>
      </div>
      <div
        style={{
          width: '85%',
          height:'150px',
          filter: 'blur(3px)',
          borderRadius: '10px',
          position: 'absolute',
          marginTop:'10px'
        }}
      >
        <UserCard user={filteredItems[(startIndex + 2) % totalProducts]} height={'120px'} />
      </div>
      <div style={{ width: '76%', borderRadius: '10px', position: 'absolute' }}>
        <UserCard
          user={filteredItems[(startIndex + 2) % totalProducts]}
          selectedTab={selectedTab}
        />
      </div>
    </>
  )
}
export default Slider
