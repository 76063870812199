import React from "react";

function HamburgerMobileIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <path
        stroke="#432C5B"
        strokeLinecap="round"
        strokeWidth="2"
        d="M6.25 8.75h17.5M6.25 15h12.5M6.25 21.25h7.5"
      ></path>
    </svg>
  );
}

export default HamburgerMobileIcon;