import AboutChimp from '../components/home/about/AboutChimp'
import Contact from '../components/home/contact/Contact'
import Features from '../components/home/features/Features'
import Footer from '../components/home/footer/Footer'
import Header from '../components/home/header/Header'
import HeroBanner from '../components/home/hero/HeroBanner'
import Offerings from '../components/home/offerings/Offerings'
import PartnersBanner from '../components/home/partners/PartnersBanner'
import WhyChimp from '../components/home/whyChimp/WhyChimp'
import AOS from 'aos'
import 'aos/dist/aos.css'
import ScrollUp from '../components/home/scrollUp/ScrollUp'
import HowItWorks from '../components/home/howItWorks/HowItWorks'
const HomePage = ({openReg,setOpenReg,setOpenAuth,openAuth,handleAuth}) => {
  AOS.init()


  return (
    <>
      <Header openReg={openReg} setOpenReg={setOpenReg} openAuth={openAuth} setOpenAuth={setOpenAuth} handleAuth={handleAuth}/>
      <HeroBanner openReg={openReg} setOpenReg={setOpenReg} openAuth={openAuth} setOpenAuth={setOpenAuth} handleAuth={handleAuth}/>
      <PartnersBanner />
      <div className='gradient'>
        <div data-aos="fade" data-aos-delay="250">
        <AboutChimp openReg={openReg} setOpenReg={setOpenReg} openAuth={openAuth} setOpenAuth={setOpenAuth} handleAuth={handleAuth}/>
      </div>
      <div data-aos="fade" data-aos-delay="250">
        <Features openReg={openReg} setOpenReg={setOpenReg} openAuth={openAuth} setOpenAuth={setOpenAuth} handleAuth={handleAuth}/>
      </div>
      </div>
      
      <div data-aos="fade" data-aos-delay="250">
        <HowItWorks openReg={openReg} setOpenReg={setOpenReg} openAuth={openAuth} setOpenAuth={setOpenAuth} handleAuth={handleAuth}/>
      </div>
      <div data-aos="fade" data-aos-delay="250">
        <WhyChimp openReg={openReg} setOpenReg={setOpenReg} openAuth={openAuth} setOpenAuth={setOpenAuth} handleAuth={handleAuth}/>
      </div>
      <div data-aos="fade" data-aos-delay="250">
        <Offerings openReg={openReg} setOpenReg={setOpenReg} openAuth={openAuth} setOpenAuth={setOpenAuth} handleAuth={handleAuth}/>
      </div>
      <div data-aos="fade" data-aos-delay="250">
        <Contact openReg={openReg} setOpenReg={setOpenReg} openAuth={openAuth} setOpenAuth={setOpenAuth} handleAuth={handleAuth}/>
      </div>
      <Footer openReg={openReg} setOpenReg={setOpenReg} openAuth={openAuth} setOpenAuth={setOpenAuth} handleAuth={handleAuth}/>
      <ScrollUp/>
    </>
  )
}
export default HomePage
