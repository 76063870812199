import React from "react";

function ContactIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        cx="10"
        cy="8"
        r="4"
        stroke="#511956"
        strokeLinecap="round"
        strokeWidth="2"
      ></circle>
      <path
        stroke="#511956"
        strokeLinecap="round"
        strokeWidth="2"
        d="M15.796 20.447a6 6 0 00-11.592 0M19 10v6M22 13h-6"
      ></path>
    </svg>
  );
}

export default ContactIcon;
