import React from "react";

function Arrow3Icon({height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="232"
      height={height||"127"}
      fill="none"
      viewBox="0 0 232 127"
    >
      <path
        fill="#432C5B"
        d="M229.427 122.463a2.499 2.499 0 002.036-2.89l-3.84-22.17a2.5 2.5 0 10-4.926.854l3.413 19.706-19.707 3.414a2.499 2.499 0 10.854 4.926l22.17-3.84zM.163 5.356C97.001 39.752 146.671 65.038 227.56 122.044l2.88-4.088C149.158 60.674 99.078 35.183 1.837.644L.163 5.356z"
      ></path>
    </svg>
  );
}

export default Arrow3Icon;
