import style from './HeroBanner.module.css'
import video from '../../../assets/images/testVideo.webm'
import videoMobile from '../../../assets/images/testVideo.mp4'
import { useNavigate } from 'react-router-dom'
import AutoPlaySilentVideo from '../video/Video'
import useWindowWidth from '../../../hooks/useWindowWidth'
import GuestContext from '../../../contexts/GuestContext'
import { useContext } from 'react'
const HeroBanner = () => {
  const innerWidth = useWindowWidth()
  const navigate = useNavigate()
  const { setOpenGuestInfo} = useContext(GuestContext)
  return (
    <div className={style.mainContainer}>
      <div className={style.container}>
        <div className={style.heroTextContainer}>
          <p className={style.heroH1}>
            Meet <span className={style.heroSpan}>Chimp</span>
          </p>
          <p className={style.heroSubText}>
            Your ultimate profesional AI sidekick
          </p>
          <button
            className={style.heroButton}
            onClick={() => {
              setOpenGuestInfo(true)
              navigate('/dashboard/home')
              window.scrollTo({
                top: 0,
                behavior: 'smooth'
              })
            }}
          >
            Try it now
          </button>
        </div>
        <div className={style.heroImageContainer}>
          <AutoPlaySilentVideo
            className={style.heroVideo}
            src={innerWidth < 678 ? videoMobile : video}
          />
        </div>
      </div>
    </div>
  )
}
export default HeroBanner
