import style from './Files.module.css'
import SingleCard from './SingleCard'
import { UploadedFilesData } from '../../../dummyData'
import { useRef } from 'react'
const UploadedFiles = () => {
  const fileInputRef = useRef(null)

  const handleDivClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <div className={style.uploadsContainer}>
      <div className={style.titleContainer}>
        <p className={style.title}>Your Files</p>
        <div onClick={handleDivClick} className={style.uploadbutton}>
          <p className={style.test}>+</p>
          <p className={style.test2}>Upload</p>
        </div>
        <input type="file" ref={fileInputRef} style={{ display: 'none' }} />
      </div>

      <div className={style.cardsContainer}>
        {UploadedFilesData.map((item, index) => (
          <SingleCard key={index} item={item} uploaded={true} />
        ))}
      </div>
      <p className={style.scrollText}>Scroll to see more</p>
    </div>
  )
}
export default UploadedFiles
