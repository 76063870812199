import React from 'react'

function ArrowUpIcon({ color,stroke ,width,height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width||"24"}
      height={height||"24"}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18 15L12 9L6 15"
        stroke={color || 'white'}
        strokeWidth={stroke||"4"}
        strokeLinecap="round"
      />
    </svg>
  )
}

export default ArrowUpIcon
