function FileIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34"
        height="34"
        fill="none"
        viewBox="0 0 34 34"
      >
        <path
          stroke="#fff"
          strokeWidth="2"
          d="M18.598 4.244h-5.689c-2.571 0-3.857 0-4.656.818-.798.817-.798 2.132-.798 4.764v13.953c0 2.631 0 3.947.798 4.764.8.817 2.085.817 4.656.817h8.182c2.571 0 3.857 0 4.656-.817.798-.817.798-2.133.798-4.764V12.377c0-.57 0-.856-.103-1.112-.104-.257-.301-.458-.695-.862l-5.22-5.341c-.395-.404-.592-.605-.843-.712-.25-.106-.529-.106-1.086-.106z"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth="2"
          d="M12.91 18.198h8.18M12.91 23.78h5.454"
        ></path>
        <path
          stroke="#fff"
          strokeWidth="2"
          d="M18.364 4.244v5.582c0 1.315 0 1.973.399 2.382.4.408 1.042.408 2.328.408h5.454"
        ></path>
      </svg>
    );
  }
  
  export default FileIcon;