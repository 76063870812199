import React from 'react'

function PlusCircleIcon({color}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M14 7L14 21"
        stroke={color||"#432C5B"}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M21 14L7 14"
        stroke={color||"#432C5B"}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default PlusCircleIcon
