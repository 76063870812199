import Footer from '../components/home/footer/Footer'
import Header from '../components/home/header/Header'
import Shreya from '../assets/images/Shreya.jpg'
import Seray from '../assets/images/SerayKocDisli.png'
import Mantas from '../assets/images/Mantas.jpeg'
const Team = ({ openReg, setOpenReg, setOpenAuth, openAuth, handleAuth }) => {
  return (
    <>
      <Header
        openReg={openReg}
        setOpenReg={setOpenReg}
        openAuth={openAuth}
        setOpenAuth={setOpenAuth}
        handleAuth={handleAuth}
      />
      <div className="container">
        <p className="title">Our team</p>
        <div className="teamContainer">
          <div className="member">
            <div className="namesContainer">
              <p className="name">Shreya Kohli</p>
              <p className="name role">Designation Founder</p>
            </div>

            <img className="memberImage" src={Shreya} />
          </div>
          <div className="member">
            <div className="namesContainer">
              <p className="name">Abhinav Singh</p>
              <p className="name role"></p>
            </div>
            {/* 
            <img className="memberImage" src={} /> */}
          </div>
          <div className="member">
            <div className="namesContainer">
              <p className="name">Mantas Navickis</p>
              <p className="name role">Full Stack Developer</p>
            </div>

            <img className="memberImage" src={Mantas} />
          </div>
          <div className="member">
            <div className="namesContainer">
              <p className="name">Seray Koç</p>
              <p className="name role">UX/UI Designer</p>
            </div>

            <img className="memberImage" src={Seray} />
          </div>
        </div>
        <p className="title">Contributors</p>
        <div className="teamContainer">
          <div className="member">
            <div className="namesContainer">
              <p className="name">Anton Carlstedt</p>
              <p className="name role">UX/UI Designer</p>
            </div>

            {/* <img className="memberImage" src={} /> */}
          </div>
        </div>
      </div>
      <Footer
        openReg={openReg}
        setOpenReg={setOpenReg}
        openAuth={openAuth}
        setOpenAuth={setOpenAuth}
        handleAuth={handleAuth}
      />
    </>
  )
}
export default Team
