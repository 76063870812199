import { useEffect, useState } from 'react'
import CloseBubbleIcon from '../../../../assets/svgs/CloseBubble'
import style from './MainProfile.module.css'
import { useAuthContext } from '../../../../contexts/AuthContext'
import CustomDropdown from '../../../../utils/CustomDropdown'
import CheckBoxIcon from '../../../../assets/svgs/CheckBox'
import CheckBoxEmptyIcon from '../../../../assets/svgs/CheckBoxEmpty'
import {
  proficiencyOptions,
  skillProficiencyOptions,
  skillExperienceOptions,
  months,
  employmentTypeOptions,
  citiesWithCountries,
  schools,
  degrees,
  fieldsOfStudy,
  skills,
  languages,
  companies,
  workTitles
} from '../../../../dummyData'
import { useLayoutContext } from '../../../../contexts/LayoutContext'
import { useLocation } from 'react-router-dom'
import CloseIcon from '../../../../assets/svgs/Close'

const ProfileModal = ({
  toggleModal,
  openModal,
  modalTitle,
  userProfile,
  setUserProfile,
  selectedId,
  create
}) => {
  const {
    layout,
    saveLayout,
    fetchProfileLayout,
    setComponents,
    addComponent,
    setLayout
  } = useLayoutContext()
  const location = useLocation()
  const urlLocal = process.env.REACT_APP_API_URL || '';
  const userId = new URLSearchParams(location.search).get('id')
  // useEffect(() => {
  //   const fetchData = async () => {
  //     await fetchProfileLayout(userId)
  //     setLayout(layout || [])
  //   }
  //   fetchData()
  // }, [])
  const { getCookie } = useAuthContext()
  const token = getCookie('token')
  const initialState = {
    about: {
      content: selectedId?.content || ''
    },
    profile: {
      title: selectedId?.user_title || '',
      location: selectedId?.user_location || ''
    },
    language: {
      language: selectedId?.language || '',
      proficiency: selectedId?.proficiency || ''
    },
    skill: {
      skillName: selectedId?.skill_name || '',
      proficiency: selectedId?.proficiency || '',
      yearsOfExperience: selectedId?.years_of_experience || ''
    },
    experience: {
      title: selectedId?.title || '',
      companyName: selectedId?.company_name || '',
      location: selectedId?.location || '',
      locationType: selectedId?.location_type || '',
      employmentType: selectedId?.employment_type || ''
    },
    education: {
      schoolName: selectedId?.school_name || '',
      degree: selectedId?.degree || '',
      fieldOfStudy: selectedId?.field_of_study || ''
    },
    startDate: { month: '', year: '' },
    endDate: { month: '', year: '' },
    checkbox: false
  }
  const [state, setState] = useState(initialState)

  const getDate = (date) => {
    const [month, year] = date.split(' ')
    return { month, year }
  }
  const handleDelete = (id) => {
    const url = `${urlLocal}/users/${modalTitle}`
    fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ id: id })
    })
      .then((response) => {
        if (!response.ok) throw new Error('Network response was not ok')
        return response.json()
      })
      .then(() => {
        fetch(
          `${url}/users/user-profile?id=${userProfile.user.user_id}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
          .then((response) => {
            if (!response.ok) throw new Error('Network response was not ok')
            return response.json()
          })
          .then(setUserProfile)
          .finally(toggleModal)
      })
      .catch(console.error)
  }
  useEffect(() => {
    if (selectedId) {
      if (selectedId.start_date)
        setState((prevState) => ({
          ...prevState,
          startDate: getDate(selectedId.start_date)
        }))
      if (selectedId.end_date)
        setState((prevState) => ({
          ...prevState,
          endDate: getDate(selectedId.end_date)
        }))
      if (selectedId.end_date === 'Present') {
        state.checkbox = true
      }
      setState((prevState) => ({
        ...prevState,
        language: {
          language: selectedId.language,
          proficiency: selectedId.proficiency
        },
        skill: {
          skillName: selectedId.skill_name,
          proficiency: selectedId.proficiency,
          yearsOfExperience: selectedId.years_of_experience
        },
        experience: {
          title: selectedId.title,
          companyName: selectedId.company_name,
          location: selectedId.location,
          locationType: selectedId.location_type,
          employmentType: selectedId.employment_type
        },
        education: {
          schoolName: selectedId.school_name,
          degree: selectedId.degree,
          fieldOfStudy: selectedId.field_of_study
        },profile: {
          title: selectedId.user_title,
          location: selectedId.user_location        
        },
          about:{
            content :selectedId.content
          }
      }))
    }
  }, [selectedId])


  const handleExperienceChange = (field, value) => {
    setState((prevState) => ({
      ...prevState,
      experience: {
        ...prevState.experience,
        [field]: value
      }
    }))
  }

  const handleEducationChange = (field, value) => {
    setState((prevState) => ({
      ...prevState,
      education: {
        ...prevState.education,
        [field]: value
      }
    }))
  }

  const handleSkillChange = (field, value) => {
    setState((prevState) => ({
      ...prevState,
      skill: {
        ...prevState.skill,
        [field]: value
      }
    }))
  }

  const handleAboutChange = (field, value) => {
    setState((prevState) => ({
      ...prevState,
      about: {
        ...prevState.about,
        [field]: value
      }
    }))
  }

  const handleLanguageChange = (field, value) => {
    setState((prevState) => ({
      ...prevState,
      language: {
        ...prevState.language,
        [field]: value
      }
    }))
  }

  const handleProfileChange = (field, value) => {
    setState((prevState) => ({
      ...prevState,
      profile: {
        ...prevState.profile,
        [field]: value
      }
    }))
  }

  const handleUpdateProfile = () => {
    const requestBody = create
      ? {
          about: {
            content: state.about.content
          },
          language: {
            language: state.language.language,
            proficiency: state.language.proficiency
          },
          profile: {
            userTitle: state.profile.title,
            userLocation: state.profile.location
          },
          skill: {
            skillName: state.skill.skillName,
            proficiency: state.skill.proficiency,
            yearsOfExperience: state.skill.yearsOfExperience
          },
          experience: {
            title: state.experience.title,
            companyName: state.experience.companyName,
            location: state.experience.location,
            locationType: state.experience.locationType,
            employmentType: state.experience.employmentType,
            startDate: `${state.startDate.month} ${state.startDate.year}`,
            endDate: state.checkbox
              ? 'Present'
              : `${state.endDate.month} ${state.endDate.year}`
          },
          education: {
            schoolName: state.education.schoolName,
            degree: state.education.degree,
            fieldOfStudy: state.education.fieldOfStudy,
            startDate: `${state.startDate.month} ${state.startDate.year}`,
            endDate: state.checkbox
              ? 'Present'
              : `${state.endDate.month} ${state.endDate.year}`
          }
        }
      : {
          about: {
            id:selectedId.id,
            content: state.about.content
          },
          profile: {
            userTitle: state.profile.title,
            userLocation: state.profile.location
          },
          language: {
            id: selectedId?.id,
            language: state.language.language,
            proficiency: state.language.proficiency
          },
          skill: {
            id: selectedId?.id,
            skillName: state.skill.skillName,
            proficiency: state.skill.proficiency,
            yearsOfExperience: state.skill.yearsOfExperience
          },
          experience: {
            id: selectedId?.id,
            title: state.experience.title,
            companyName: state.experience.companyName,
            location: state.experience.location,
            locationType: state.experience.locationType,
            employmentType: state.experience.employmentType,
            startDate: `${state.startDate.month} ${state.startDate.year}`,
            endDate: state.checkbox
              ? 'Present'
              : `${state.endDate.month} ${state.endDate.year}`
          },
          education: {
            id: selectedId?.id,
            schoolName: state.education.schoolName,
            degree: state.education.degree,
            fieldOfStudy: state.education.fieldOfStudy,
            startDate: `${state.startDate.month} ${state.startDate.year}`,
            endDate: state.checkbox
              ? 'Present'
              : `${state.endDate.month} ${state.endDate.year}`
          }
        }
    const url = `${urlLocal}/users/${modalTitle}`
    const method = create ? 'POST' : 'PUT'

    fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(requestBody[modalTitle])
    })
      .then((response) => {
        if (!response.ok) throw new Error('Network response was not ok')
        return response.json()
      })
      .then(() => {
        fetch(
          `${urlLocal}/users/user-profile?id=${userProfile.user.user_id}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            }
          }
        )
          .then((res) => res.json())
          .then((res) => {
            setUserProfile(res)
            switch (modalTitle) {
              case 'experience':
                addComponent('2', userProfile.user.user_id)
                break
              case 'education':
                addComponent('3', userProfile.user.user_id)
                break
              case 'skill':
                addComponent('4', userProfile.user.user_id)
                break
              case 'language':
                addComponent('5', userProfile.user.user_id)
                break
              case 'about':
                addComponent('1', userProfile.user.user_id)
                break
              default:
                break
            }
          })

        toggleModal('', {}, '')
      })
      .catch((error) =>
        console.error('There was a problem with the request:', error)
      )
  }
  const [workTitlePlaceholder, setWorkTitlePlaceholder] = useState('')
  const [companyPlaceholder, setCompanyPlaceholder] = useState('')
  const [cityPlaceholder, setCityPlaceholder] = useState('')
  const [schoolPlaceholder, setSchoolPlaceholder] = useState('')
  const [degreePlaceholder, setDegreePlaceholder] = useState('')
  const [fieldOfStudyPlaceholder, setFieldOfStudyPlaceholder] = useState('')
  const [skillPlaceholder, setSkillPlaceholder] = useState('')
  const [languagePlaceholder, setLanguagePlaceholder] = useState('')

  useEffect(() => {
    setWorkTitlePlaceholder(`Ex. ${workTitles[random(0, workTitles.length)]}`)
    setCompanyPlaceholder(`Ex. ${companies[random(0, companies.length)]}`)
    setCityPlaceholder(
      `Ex. ${citiesWithCountries[random(0, citiesWithCountries.length)]}`
    )
    setSchoolPlaceholder(`Ex. ${schools[random(0, schools.length)]}`)
    setDegreePlaceholder(`Ex. ${degrees[random(0, degrees.length)]}`)
    setFieldOfStudyPlaceholder(
      `Ex. ${fieldsOfStudy[random(0, fieldsOfStudy.length)]}`
    )
    setSkillPlaceholder(`Ex. ${skills[random(0, skills.length)]}`)
    setLanguagePlaceholder(`Ex. ${languages[random(0, languages.length)]}`)
  }, [])

  const random = (min, max) => Math.floor(Math.random() * max)
  const locationTypeOptions = ['On site', 'Remote', 'Hybrid']
  const years = Array.from(
    { length: 50 },
    (_, i) => new Date().getFullYear() - i
  )
  return openModal ? (
    <div className={style.modalWrapper}>
          <div onClick={toggleModal} className={style.mobileClose}>
            <CloseIcon color="#393D63" height={20} width={20} />
          </div>
      <div className={style.modal}>
        <div className={style.header}>
          <p className={style.title}>
            {create ? 'Add' : 'Edit'} {modalTitle}
          </p>
          <div onClick={toggleModal} className={style.closeButton}>
            <CloseBubbleIcon color="#393D63" height={20} width={20} />
          </div>
        </div>
        <div className={style.modalScroll}>
          {modalTitle === 'about' && (
            <textarea
              className={style.editAboutInput}
              value={state.about.content}
              onChange={(e) => handleAboutChange('content', e.target.value)}
            />
          )}
          {modalTitle === 'language' && (
            <>
              <p className={style.inputTitle}>Language</p>
              <input
                className={style.input}
                placeholder={languagePlaceholder}
                type="text"
                value={state.language.language}
                onChange={(e) =>
                  handleLanguageChange('language', e.target.value)
                }
              />
              <p className={style.inputTitle}>Proficiency</p>
              <CustomDropdown
                options={proficiencyOptions}
                value={state.language.proficiency}
                onChange={(value) => handleLanguageChange('proficiency', value)}
              />
            </>
          )}
          {modalTitle === 'skill' && (
            <>
              <p className={style.inputTitle}>Skill</p>
              <input
                className={style.input}
                placeholder={skillPlaceholder}
                type="text"
                value={state.skill.skillName}
                onChange={(e) => handleSkillChange('skillName', e.target.value)}
              />
              <p className={style.inputTitle}>Proficiency</p>
              <CustomDropdown
                options={skillProficiencyOptions}
                value={state.skill.proficiency}
                onChange={(value) => handleSkillChange('proficiency', value)}
              />
              <p className={style.inputTitle}>Experience</p>
              <CustomDropdown
                options={skillExperienceOptions}
                value={state.skill.yearsOfExperience}
                onChange={(value) =>
                  handleSkillChange('yearsOfExperience', value)
                }
              />
            </>
          )}
          {modalTitle === 'experience' && (
            <>
              <p className={style.inputTitle}>Title</p>
              <input
                className={style.input}
                type="text"
                placeholder={workTitlePlaceholder}
                value={state.experience.title}
                onChange={(e) =>
                  handleExperienceChange('title', e.target.value)
                }
              />
              <p className={style.inputTitle}>Employment type</p>
              <CustomDropdown
                options={employmentTypeOptions}
                value={state.experience.employmentType}
                placeHolder={'Please select'}
                onChange={(value) =>
                  handleExperienceChange('employmentType', value)
                }
              />
              <p className={style.inputTitle}>Company name</p>
              <input
                className={style.input}
                type="text"
                value={state.experience.companyName}
                placeholder={companyPlaceholder}
                onChange={(e) =>
                  handleExperienceChange('companyName', e.target.value)
                }
              />
              <p className={style.inputTitle}>Location type</p>
              <CustomDropdown
                options={locationTypeOptions}
                placeHolder={'Please select'}
                value={state.experience.locationType}
                onChange={(value) =>
                  handleExperienceChange('locationType', value)
                }
              />
              <p className={style.inputTitle}>Location</p>
              <input
                className={style.input}
                placeholder={cityPlaceholder}
                type="text"
                value={state.experience.location}
                onChange={(e) =>
                  handleExperienceChange('location', e.target.value)
                }
              />
              <div
                className={style.checkbox}
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    checkbox: !prevState.checkbox
                  }))
                }
              >
                {state.checkbox ? <CheckBoxIcon /> : <CheckBoxEmptyIcon />}
                <p>I am currently working in this role</p>
              </div>
              <div className={style.date}>
                <div className={style.dateBox}>
                  <p className={style.inputTitle}>Start Date</p>
                  <div className={style.dateInput}>
                    <CustomDropdown
                      options={months}
                      value={state.startDate.month}
                      date={true}
                      placeHolder={'month'}
                      onChange={(value) =>
                        setState((prevState) => ({
                          ...prevState,
                          startDate: { ...prevState.startDate, month: value }
                        }))
                      }
                    />
                    <CustomDropdown
                      options={years}
                      value={state.startDate.year}
                      date={true}
                      placeHolder={'year'}
                      onChange={(value) =>
                        setState((prevState) => ({
                          ...prevState,
                          startDate: { ...prevState.startDate, year: value }
                        }))
                      }
                    />
                  </div>
                </div>
                <div className={style.dateBox}>
                  <p className={style.inputTitle}>End Date</p>
                  <div className={style.dateInput}>
                    <CustomDropdown
                      options={months}
                      checkbox={state.checkbox}
                      value={state.endDate.month}
                      date={true}
                      placeHolder={'month'}
                      onChange={(value) =>
                        setState((prevState) => ({
                          ...prevState,
                          endDate: { ...prevState.endDate, month: value }
                        }))
                      }
                    />
                    <CustomDropdown
                      options={years}
                      checkbox={state.checkbox}
                      value={state.endDate.year}
                      date={true}
                      placeHolder={'year'}
                      onChange={(value) =>
                        setState((prevState) => ({
                          ...prevState,
                          endDate: { ...prevState.endDate, year: value }
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {modalTitle === 'education' && (
            <>
              <p className={style.inputTitle}>School</p>
              <input
                className={style.input}
                placeholder={schoolPlaceholder}
                type="text"
                value={state.education.schoolName}
                onChange={(e) =>
                  handleEducationChange('schoolName', e.target.value)
                }
              />
              <p className={style.inputTitle}>Degree</p>
              <input
                className={style.input}
                placeholder={degreePlaceholder}
                type="text"
                value={state.education.degree}
                onChange={(e) =>
                  handleEducationChange('degree', e.target.value)
                }
              />
              <p className={style.inputTitle}>Field of study</p>
              <input
                className={style.input}
                placeholder={fieldOfStudyPlaceholder}
                type="text"
                value={state.education.fieldOfStudy}
                onChange={(e) =>
                  handleEducationChange('fieldOfStudy', e.target.value)
                }
              />
              <div
                className={style.checkbox}
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    checkbox: !prevState.checkbox
                  }))
                }
              >
                <div className={style.emptyCheckBox}>
                  {state.checkbox && <CheckBoxIcon />}
                </div>
                <p>I'm currently studying</p>
              </div>
              <div className={style.date}>
                <div className={style.dateBox}>
                  <p className={style.inputTitle}>Start Date</p>
                  <div className={style.dateInput}>
                    <CustomDropdown
                      options={months}
                      value={state.startDate.month}
                      placeHolder={'month'}
                      date={true}
                      onChange={(value) =>
                        setState((prevState) => ({
                          ...prevState,
                          startDate: { ...prevState.startDate, month: value }
                        }))
                      }
                    />
                    <CustomDropdown
                      options={years}
                      value={state.startDate.year}
                      date={true}
                      placeHolder={'year'}
                      onChange={(value) =>
                        setState((prevState) => ({
                          ...prevState,
                          startDate: { ...prevState.startDate, year: value }
                        }))
                      }
                    />
                  </div>
                </div>
                <div className={style.dateBox}>
                  <p className={style.inputTitle}>End Date</p>
                  <div className={style.dateInput}>
                    <CustomDropdown
                      options={months}
                      value={state.endDate.month}
                      placeHolder={'month'}
                      checkbox={state.checkbox}
                      date={true}
                      onChange={(value) =>
                        setState((prevState) => ({
                          ...prevState,
                          endDate: { ...prevState.endDate, month: value }
                        }))
                      }
                    />
                    <CustomDropdown
                      options={years}
                      value={state.endDate.year}
                      checkbox={state.checkbox}
                      placeHolder={'year'}
                      date={true}
                      onChange={(value) =>
                        setState((prevState) => ({
                          ...prevState,
                          endDate: { ...prevState.endDate, year: value }
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {modalTitle === 'profile' && (
            <>
              <p className={style.inputTitle}>Title</p>
              <input
                className={style.input}
                placeholder={workTitlePlaceholder}
                type="text"
                value={state.profile.title}
                onChange={(e) => handleProfileChange('title', e.target.value)}
              />
              <p className={style.inputTitle}>Location</p>
              <input
                className={style.input}
                placeholder={cityPlaceholder}
                type="text"
                value={state.profile.location}
                onChange={(e) =>
                  handleProfileChange('location', e.target.value)
                }
              />
            </>
          )}
        </div>
        <div className={style.modalButtonContainer}>
          {create || modalTitle === 'profile' ? (
            <div></div>
          ) : (
            <button
              className={style.modalRemoveButton}
              onClick={() => handleDelete(selectedId.id)}
            >
              Remove
            </button>
          )}

          <button className={style.modalButton} onClick={handleUpdateProfile}>
            Save
          </button>
        </div>
      </div>
      <div className={style.modalMobileButtonContainer}>
          {create || modalTitle === 'profile' ? (
            <div></div>
          ) : (
            <button
              className={style.modalRemoveButton}
              onClick={() => handleDelete(selectedId.id)}
            >
              Remove
            </button>
          )}

          <button className={style.modalMobileButton} onClick={handleUpdateProfile}>
            Save
          </button>
        </div>
    </div>
  ) : null
}

export default ProfileModal
