import { useState } from 'react'
import DragIcon from '../../../assets/svgs/Drag'
import style from './News.module.css'
import useDroppable from '../../../hooks/useDroppable'
import useDraggable from '../../../hooks/useDraggable'
import { NewsData } from '../../../dummyData'
import { useFilesContext } from '../../../contexts/FilesContext'
import FileUploadedIcon from '../../../assets/svgs/FileUploaded'
const News = () => {
  const [selected, setSelected] = useState()
  const [feed, setFeed] = useState(NewsData)
  const droppableProps = useDroppable(feed, setFeed)
  const { addFile, files } = useFilesContext()

  const { draggable, onDragStart, onDragEnd, isDragging } = useDraggable()
  const handleSelection = (index) => {
    if (selected === index) {
      setSelected(undefined)
    } else {
      setSelected(index)
    }
  }
  const handleClickAdd = (card, event) => {
    event.stopPropagation()
    addFile(card)
  }
  const checkTime = (dayPosted) => {
    const currentDate = new Date()
    const postedDate = new Date(dayPosted)
    const [datePart] = dayPosted.split(' ')

    if (
      currentDate.getDate() === postedDate.getDate() &&
      currentDate.getMonth() === postedDate.getMonth() &&
      currentDate.getFullYear() === postedDate.getFullYear() &&
      currentDate.getHours() === postedDate.getHours()
    ) {
      return 'Just Now'
    }
    return datePart
  }

  return (
    <div className={style.container} {...droppableProps}>
      <p className={style.title}>What’s new</p>
      <div
        className={`${style.cardsContainer} ${
          isDragging ? style.dragging : ''
        }`}
      >
        {NewsData.map((card, index) => (
          <div
            className={`${style.card} ${
              selected === index ? style.expanded : style.collapsed
            }`}
            key={index}
            onClick={() => handleSelection(index)}
            draggable
            onDragStart={(e) => onDragStart(e, card)}
            onDragEnd={onDragEnd}
          >
            <div className={style.cardHoverContent}>
              <div className={style.cardContent}>
                <div className={style.cardTextContainer}>
                  <p className={style.cardText}>{card.title}</p>
                  <p className={style.cardSubText}>{card.subTitle}</p>
                </div>
                <div className={style.cardDate}>
                  <p className={style.date}>{checkTime(card.dayPosted)}</p>
                </div>
              </div>
              <p className={style.cardDescription}>{card.text}</p>
              <div className={style.buttonContainer}>
         
                <button className={style.cardButton} onClick={(event)=>handleClickAdd(card,event)}>{files.some((item) => item.id === card.id) ?        <FileUploadedIcon noBubble={true} />: 'Add to chat'}</button>
              </div>
            </div>

            <div className={style.cardBuble}>
              {card.seen ? '' : <div className={style.bubble}></div>}
            </div>
            {files.some((item) => item.id === card.id) ? (
              <div className={style.cardUploadedIcon}>
                <FileUploadedIcon />
              </div>
            ) : (
              <div className={style.cardDrag}>
                <DragIcon />
              </div>
            )}
          </div>
        ))}
      </div>
      <p className={style.scrollText}>Scroll to see more</p>
    </div>
  )
}

export default News
