import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import SmallUserIcon from '../../../../assets/svgs/SmallUser'
import style from './Profile.module.css'

const Graph = ({ title, percentage, participants,mobile }) => {
  return (
    <div className={style.graph}>
      <p className={style.subtitle}>{title}</p>
      <div className={style.circularBar} style={{height:mobile? '150px':null,width:mobile? '100px':null, display:'flex'
      }}>
        <p className={`${style.barPercentage} ${mobile&&style.mobileBar}`}> {percentage}%</p>
        <p className={`${style.subText} ${mobile&&style.mobileBarSubText}`}>positive feedback</p>
        <CircularProgressbar
          value={percentage}
          styles={buildStyles({
            strokeLinecap: 'butt',
            pathColor: '#393D63',
            trailColor: 'white'
          })}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '5px',overflow:'scroll',height:'100%'}}>
        <p className={style.smallText}>Titles of Participants</p>
        {participants.map((participant, index) => (
          <div
            key={index}
            style={{ display: 'flex', flexDirection: 'row', gap: '50px',justifyContent:'space-between' }}
          >
            <p className={style.smallText}>{participant.title} </p>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
              <SmallUserIcon />
              <p className={style.smallNumber}>{participant.count}</p>
            </div>{' '}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Graph
