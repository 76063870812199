import { useEffect, useState } from 'react'
import style from './ScrollUp.module.css'
import ArrowUpIcon from '../../../assets/svgs/ArrowUp'

const ScrollUp = () => {
  const [openScroll, setOpenScroll] = useState(false)
  const [yAxis, setYAxis] = useState(0)
  const [manual, setManual] = useState(true)

  useEffect(() => {
    const handleScroll = () => {
      const y = window.scrollY
      setYAxis(y)
      setOpenScroll(y > 1 && manual)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [manual])
  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
  return (
    <div
      className={
        openScroll ? `${style.container} ${style.appear}` : style.container
      }
      onClick={scrollUp}
    >
      <ArrowUpIcon color={'#000'} />
    </div>
  )
}

export default ScrollUp
