import React from "react";

function SearchIcon({stroke}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <circle cx="7.333" cy="7.333" r="4" stroke="#4D4D4D" ></circle>
      <path
        stroke="#4D4D4D"
        strokeLinecap="round"
        strokeWidth={stroke||"1"}
        d="M13.334 13.334l-2-2"
      ></path>
    </svg>
  );
}

export default SearchIcon;
