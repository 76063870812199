import React, { useContext, useEffect, useState } from 'react'
import style from './AboutChimp.module.css'
import AlternatingOne from './Alternating'
import AlternatingTwo from './Alternating2'
import { useNavigate } from 'react-router-dom'
import GuestContext from './../../../contexts/GuestContext'

const AboutChimp = () => {
  const [openFirst, setOpenFirst] = useState(false)
  const [openSecond, setOpenSecond] = useState(false)
  const [openThird, setOpenThird] = useState(false)
  const { setOpenGuestInfo} = useContext(GuestContext)

  const navigate = useNavigate()
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY

      if (scrollY > 650 && scrollY < 950) {
        setOpenFirst(true)
      }

      if (scrollY > 870 && scrollY < 990) {
        setOpenSecond(true)
      }
      if (scrollY > 1000 && scrollY < 1150) {
        setOpenThird(true)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className={style.mainConatiner}>
      <div className={style.container} id="about">
        <div className={style.textContainer}>
          <div className={style.mainText}>
            Are&nbsp;you&nbsp;
            <AlternatingOne />
            seeking&nbsp;
            <br />
            <span className={style.mainText}>
              <AlternatingTwo />
            </span>{' '}
          </div>
          <div className={style.subText}>
            Discover How<span className={style.highlightedPeriod}>.</span>
          </div>
        </div>
        <div className={style.cardContainer}>
          <div
            className={`${style.singleCard}${
              openFirst ? ` ${style.openFirst}` : ''
            }`}
          >
            <p className={style.cardTitle}>All-In-One Platform</p>
            <p className={style.cardTitleMobile}>All-In-One Platform</p>
            <p className={style.cardText}>
              Utilizing advanced AI technology to provide personalized
              recommendations and insights tailored to each user's professional
              goals, skills, and interests.
            </p>
          </div>
          <div
            className={`${style.singleCard}${
              openSecond ? ` ${style.openSecond}` : ''
            }`}
          >
            <p className={style.cardTitle}>AI-Driven Mentoring</p>
            <p className={style.cardTitleMobile}>AI-Driven Mentoring</p>
            <p className={style.cardText}>
              Serving as a holistic platform for professional growth and
              networking, offering resources ranging from skill development to
              industry trends and networking opportunities.
            </p>
          </div>
          <div
            className={`${style.singleCard}${
              openThird ? ` ${style.openThird}` : ''
            }`}
          >
            <p className={style.cardTitle}>Streamlined Experience</p>
            <p className={style.cardTitleMobile}>Streamlined Experience</p>
            <p className={style.cardText}>
              Alleviating the struggles associated with traditional job
              searching and empowering users to navigate their career paths
              confidently.
            </p>
          </div>
        </div>
        <button
          className={style.button}
          onClick={() => {
            setOpenGuestInfo(true)
            navigate('/dashboard/home')
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            })
          }}
        >
          Try it now
        </button>
      </div>
    </div>
  )
}

export default AboutChimp
