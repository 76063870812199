import React from "react";

function UploadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <path
        fill="#432C5B"
        d="M12.5 5l-.707-.707.707-.707.707.707L12.5 5zm1 9a1 1 0 11-2 0h2zM6.793 9.293l5-5 1.414 1.414-5 5-1.414-1.414zm6.414-5l5 5-1.414 1.414-5-5 1.414-1.414zM13.5 5v9h-2V5h2z"
      ></path>
      <path
        stroke="#432C5B"
        strokeWidth="2"
        d="M5.5 16v1a2 2 0 002 2h10a2 2 0 002-2v-1"
      ></path>
    </svg>
  );
}

export default UploadIcon;
