import React from 'react'

function EnvelopeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <rect x="4" y="6.98193" width="16" height="12" rx="2" stroke="white" />
      <path
        d="M4 9.98193L11.1056 13.5347C11.6686 13.8162 12.3314 13.8162 12.8944 13.5347L20 9.98193"
        stroke="white"
      />
    </svg>
  )
}

export default EnvelopeIcon
