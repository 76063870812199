import style from './Profile.module.css'
import image from '../../../../assets/images/Ellipse.png'
import UserIcon from '../../../../assets/svgs/User'
import useWindowWidth from '../../../../hooks/useWindowWidth'

const ProfileHeader = ({ user, selectedProfile, profileToggle }) => {
  console.log('user in profile' ,user)
  const innerWidth = useWindowWidth()

  return (
    <div className={style.userInfoContainer}>
      <div className={style.userImageContainer}>
      <div className={style.iconContainer}>
          <UserIcon
            width={'30'}
            height={'30'}
            color={'#ffffff'}
          />
         
        </div>
      </div>
      <div className={style.userInfo}>
        <p className={style.userName}>{user?.user?.first_name} {user?.user?.last_name}</p>
        <p className={style.userProf}>{user?.user?.user_title}</p>
      </div>
      <div className={style.profiles}>
        <div
          className={`${style.profile} ${
            selectedProfile === 'Personal' ? style.selected : null
          }`}
          onClick={() => profileToggle('Personal')}
        >
          Personal Profile
        </div>
        <div
          className={`${style.profile} ${
            selectedProfile === 'Mentor' ? style.selected : null
          }`}
          onClick={() => profileToggle('Mentor')}
        >
          Mentor Profile
        </div>
      </div>
    </div>
  )
}

export default ProfileHeader
