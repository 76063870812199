import { useState, useContext } from 'react'
import style from './Header.module.css'
import HamburgerMenu from './HamburgerMenu'
import { useNavigate } from 'react-router-dom'
import IconWhite from '../../../assets/svgs/IconWhite'
import IconHover from '../../../assets/svgs/IconHover'
import Auth from '../../../utils/AuthModal'
import useWindowWidth from '../../../hooks/useWindowWidth'
import { useAuthContext } from '../../../contexts/AuthContext'
import useScrollMenu from '../../../hooks/useScrollMenu'
import GuestContext from '../../../contexts/GuestContext'

const Header = ({ openReg, openAuth, setOpenAuth, handleAuth }) => {
  const innerWidth = useWindowWidth()
  const [manual, setManual] = useState(true)
  const { yAxis, openMenu, setOpenMenu } = useScrollMenu(manual)
  const navigate = useNavigate()
  const { logout } = useAuthContext()
  const { setOpenGuestInfo } = useContext(GuestContext)
  const loggedIn = document.cookie.includes('token')
  const url = process.env.REACT_APP_API_URL || '';
  const handleClick = () => {
    setOpenMenu(!openMenu)
    setManual(!manual)
    if (innerWidth < 768) {
      if (!openMenu) {
        document.documentElement.classList.add('disable-scroll')
      } else {
        document.documentElement.classList.remove('disable-scroll')
      }
    }
  }

  const handleLogout = async (e) => {
    e.preventDefault()
    try {
      const response = await fetch(`${url}/users/logout`, { method: 'GET' })
      if (!response.ok) throw new Error('Logout request failed')
      logout()
      navigate('/')
      console.log('Logout successful')
    } catch (error) {
      console.error('Error logging out:', error.message)
    }
  }

  const handleNavigation = (path, sectionId) => {
    navigate(path)
    setTimeout(() => {
      const section = document.getElementById(sectionId)
      if (section) section.scrollIntoView({ behavior: 'smooth' })
    }, 200)
  }

  const renderMenuItems = () => (
    <>
      <p
        className={style.singleMenuItem}
        onClick={() => handleNavigation('/', '')}
      >
        Home
      </p>
      <p
        className={style.singleMenuItem}
        onClick={() => handleNavigation('/', 'about')}
      >
        About
      </p>
      <p
        className={style.singleMenuItem}
        onClick={() => handleNavigation('/', 'offerings')}
      >
        Offerings
      </p>
      <p
        className={style.singleMenuItem}
        onClick={() => handleNavigation('/', 'contact')}
      >
        Contact
      </p>
      {loggedIn ? (
        <p className={style.singleMenuItem} onClick={handleLogout}>
          Log out
        </p>
      ) : (
        <p
          className={style.singleMenuItem}
          onClick={() => {
            handleAuth(!openReg)
            setOpenAuth('login')
          }}
        >
          Log in
        </p>
      )}
      <button
        className={style.menuButton}
        onClick={() => {
          handleNavigation('/dashboard/home', '')
          setOpenGuestInfo(true)
        }}
      >
        Try it now
      </button>
    </>
  )

  return (
    <>
      <div className={style.cover}></div>
      <div className={`${style.container} ${yAxis > 1 ? style.stickyNav : ''}`}>
        <div className={style.subContainer}>
          <div className={style.iconContainer}>
            <div
              className="chimpIcon"
              onClick={() => handleNavigation('/', '')}
            >
              <IconWhite />
              <IconHover />
            </div>
          </div>
          <div className={style.menuContainer}>
            <div
              className={`${style.menuItems} ${
                openMenu ? style.menuItemsVisible : ''
              }`}
              style={{ pointerEvents: openMenu ? 'auto' : 'none' }}
            >
              {renderMenuItems()}
            </div>
            <div
              className={`${openMenu ? style.menuBackground : ''} ${
                openMenu ? style.open : style.closed
              }`}
              onClick={() => setOpenMenu(!openMenu)}
            ></div>
            <div
              className={`${style.mobileMenu} ${
                openMenu ? style.open : style.closed
              }`}
            >
              <div
                className={style.HamburgerMenuMobile}
                onClick={handleClick}
              ></div>
              {renderMenuItems()}
            </div>
            <div onClick={handleClick} className={style.hamburger}>
              <HamburgerMenu
                isOpen={openMenu}
                toggleMenu={() => setOpenMenu(!openMenu)}
              />
            </div>
          </div>
        </div>
      </div>
      {openReg && (
        <div className={style.form}>
          <Auth
            handleLogin={handleAuth}
            openReg={openReg}
            openAuth={openAuth}
            setOpenAuth={setOpenAuth}
          />
        </div>
      )}
    </>
  )
}

export default Header
