import CloseBubbleIcon from '../../../../assets/svgs/CloseBubble'
import EditIcon from '../../../../assets/svgs/EditIcon'
import PlusCircleIcon from '../../../../assets/svgs/PlusCircle'
import style from './MainProfile.module.css'
const Education = ({
  userProfile,
  toggleModal,
  owner,
  removeComponent,
  editMode
}) => {
  return (
    <>
      {userProfile.education.length > 0 && (
        <div className={style.educationWrapper}>
          <div className={style.educationActions}>
            <p className={style.title}>Education</p>
            {owner && editMode && (
              <div style={{ display: 'flex' }}>
                <div
                  className={style.plus}
                  onClick={() =>
                    toggleModal('education', userProfile, 'create')
                  }
                >
                  <PlusCircleIcon color={'white'} />
                </div>
                <div onClick={() => removeComponent('3')}>
                  <CloseBubbleIcon color="#393D63" height={20} width={20} />
                </div>
              </div>
            )}
          </div>
          <div className={style.cardsContainer}>
            {userProfile &&
              userProfile.education.map((item, index) => {
                return (
                  <div className={style.singleCard} key={index}>
                    <div className={style.cardImage}></div>
                    <div className={style.cardInfo}>
                      <p className={style.cardTitle}>{item.school_name}</p>

                      <p className={style.cardSubtext}>
                        {item.degree} Degree - {item.field_of_study}
                      </p>
                      <p className={style.cardSubtext}>
                        {item.start_date}-{item.end_date}
                      </p>
                      {owner && editMode && (
                        <div
                          onClick={() => toggleModal('education', item)}
                          className={style.edit}
                        >
                          <EditIcon />
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      )}
    </>
  )
}
export default Education
