import React from 'react'

function CheckBoxEmptyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <rect x="0.5" width="20" height="20" rx="4" fill="#F9F9F9" />
    </svg>
  )
}

export default CheckBoxEmptyIcon
