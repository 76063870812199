import CloseBubbleIcon from '../../../../assets/svgs/CloseBubble'
import EditIcon from '../../../../assets/svgs/EditIcon'
import PlusCircleIcon from '../../../../assets/svgs/PlusCircle'
import style from './MainProfile.module.css'
const Languages = ({ userProfile,toggleModal,owner,removeComponent,editMode }) => {
  return (
    <>{userProfile.languages.length >0 &&
    <div className={style.skillsWrapper}>
      <div className={style.experienceActions}>
        <p className={style.title}>Languages</p>
        {owner && editMode&&<div style={{ display: 'flex' }}>
          <div className={style.plus}  onClick={()=>toggleModal('language', userProfile, 'create')}>
            <PlusCircleIcon color={'white'} />
          </div>
          <div onClick={()=> removeComponent('5')}>
            <CloseBubbleIcon color="#393D63" height={20} width={20} />
          </div>
        </div> }
        
      </div>
      <div className={style.cardsContainer}>
        {userProfile &&
          userProfile.languages.map((item, index) => {
            return (
              <div className={style.skill} key={index}>
                <p className={style.skillName}>{item.language}</p>
                <p className={style.subtext}> · {item.proficiency}</p>
                {owner &&editMode&&<div onClick={()=>toggleModal('language',item)} className={style.edit} >
            <EditIcon />
          </div> }
                
              </div>
            )
          })}
      </div>
    </div>
    }</>
    
  )
}
export default Languages
