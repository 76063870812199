import React from 'react'

function CourseIcon({ height, width }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width||"24"}
      height="24"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M4.13124 9.60892L4.13124 9.60892C4.13078 9.60939 4.13031 9.60985 4.12985 9.61031C4.12155 9.61861 4.1129 9.62719 4.10397 9.63606C4.0036 9.73572 3.86689 9.87145 3.76962 10.0432C3.67236 10.215 3.62631 10.4021 3.59249 10.5394C3.58932 10.5523 3.58625 10.5648 3.58325 10.5768L3.1409 12.3462C3.13901 12.3538 3.13701 12.3617 3.13493 12.37C3.11066 12.4664 3.07508 12.6078 3.06272 12.7342C3.04839 12.8806 3.04223 13.2138 3.31465 13.4862C3.58705 13.7586 3.92018 13.7524 4.06664 13.7381C4.19299 13.7257 4.33438 13.6902 4.43084 13.6659C4.43912 13.6638 4.44706 13.6618 4.45463 13.6599L6.22405 13.2176C6.23602 13.2146 6.24848 13.2115 6.26138 13.2083C6.39874 13.1745 6.5858 13.1285 6.75759 13.0312C6.92938 12.9339 7.06511 12.7972 7.16477 12.6968C7.17414 12.6874 7.18318 12.6783 7.1919 12.6696L11.988 7.87353C11.9972 7.86433 12.0064 7.85513 12.0156 7.84593C12.2137 7.64789 12.4117 7.45 12.5539 7.26362C12.7159 7.05128 12.8743 6.76838 12.8743 6.40039C12.8743 6.0324 12.7159 5.74951 12.5539 5.53716C12.4117 5.35079 12.2137 5.1529 12.0156 4.95486C12.0064 4.94566 11.9972 4.93645 11.988 4.92725L11.8736 4.81287L11.3432 5.3432L11.8736 4.81287C11.8644 4.80367 11.8552 4.79446 11.846 4.78526C11.6479 4.58712 11.45 4.38913 11.2637 4.24693C11.0513 4.08492 10.7684 3.92653 10.4004 3.92653C10.0324 3.92653 9.74955 4.08492 9.5372 4.24693C9.35083 4.38913 9.15294 4.58712 8.9549 4.78525C8.9457 4.79446 8.93649 4.80367 8.92729 4.81287L4.13124 9.60892Z"
        stroke="#632F08"
        strokeWidth="1.5"
      />
      <path
        d="M8.73376 5.40023L10.7338 4.06689L12.7338 6.06689L11.4004 8.06689L8.73376 5.40023Z"
        fill="#632F08"
      />
    </svg>
  )
}

export default CourseIcon
