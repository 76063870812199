import React from "react";

function AcceptIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="9" stroke="#432C5B" strokeWidth="2"></circle>
      <path stroke="#432C5B" strokeWidth="2" d="M8 12l3 3 5-6"></path>
    </svg>
  );
}

export default AcceptIcon;
