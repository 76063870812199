import React, { useRef } from 'react';
import { StandaloneSearchBox } from '@react-google-maps/api';
import style from './NewEvent.module.css';

const AutoFillAddress = ({ handleFormData, formData, setlocation }) => {
  const inputRef = useRef();

  const handlePlaceChanged = () => {
    const places = inputRef.current.getPlaces();
    if (places && places.length > 0) {
      const place = places[0];
      const address = place.formatted_address;
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      console.log('Address:', address);
      console.log('Latitude:', lat);
      console.log('Longitude:', lng);
      setlocation({ lat, lng });
      handleFormData({
        target: {
          name: 'address',
          value: address,
        },
      });
    }
  };

  return (
    <StandaloneSearchBox onLoad={(ref) => (inputRef.current = ref)} onPlacesChanged={handlePlaceChanged}>
      <input
        className={style.urlInput}
        type="text"
        name="address"
        value={formData.address}
        onChange={handleFormData}
        placeholder="Location"
      />
    </StandaloneSearchBox>
  );
};

export default AutoFillAddress;
