import React from "react";

function CheckMark2Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M21.582 5.543a1 1 0 010 1.414l-11.33 11.33a1 1 0 01-1.407.006l-6.546-6.429a1 1 0 111.402-1.427l5.838 5.735 10.629-10.63a1 1 0 011.414 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default CheckMark2Icon;
