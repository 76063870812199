import { useEffect, useState } from 'react'
import style from './Feed.module.css'
import FeedCard from './FeedCard'
import FilterIcon from '../../../assets/svgs/Filter'
import useDroppable from '../../../hooks/useDroppable'
import { FeedData } from '../../../dummyData'
const Feed = () => {
  
  const filters = [
    'All',
    'Jobs',
    'Courses',
    'Contacts',
    'Events',
    'News',
    'Chimp',
    'Saved'
  ]

  const [selectedFilters, setSelectedFilters] = useState([])
  const [feed, setFeed] = useState(FeedData)
  const [openFilter, setOpenFilter] = useState(false)
  const droppableProps = useDroppable(feed, setFeed);
  useEffect(() => {
    if (selectedFilters.length === 0) {
      setSelectedFilters(['All'])
    }
  }, [selectedFilters])

  const handleSelect = (filter) => {
    if (filter === 'All') {
      setSelectedFilters([])
    } else {
      const index = selectedFilters.indexOf(filter)
      if (index === -1) {
        if (selectedFilters.includes('All')) {
          setSelectedFilters([filter])
        } else {
          setSelectedFilters([...selectedFilters, filter])
        }
      } else {
        setSelectedFilters(selectedFilters.filter((f) => f !== filter))
      }
    }
  }

  const isFiltered = (item) => {
    if (selectedFilters.length === 0) return true
    return (
      selectedFilters.includes('All') || selectedFilters.includes(item.category)
    )
  }
  // Function to check if a date is today
  const isToday = (someDate) => {
    const today = new Date()
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    )
  }

  // Function to check if a date is yesterday
  const isYesterday = (someDate) => {
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    return (
      someDate.getDate() === yesterday.getDate() &&
      someDate.getMonth() === yesterday.getMonth() &&
      someDate.getFullYear() === yesterday.getFullYear()
    )
  }

  // Function to check if a date is within the current week
  const isThisWeek = (someDate) => {
    const today = new Date()
    const firstDayOfWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - today.getDay()
    )
    const lastDayOfWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + (6 - today.getDay())
    )
    const isFirstDayOfWeekOrEarlier = someDate <= firstDayOfWeek
    const isLastDayOfWeekOrLater = someDate >= lastDayOfWeek
    const isTodayOrYesterday = isToday(someDate) || isYesterday(someDate)
    return (
      !isTodayOrYesterday &&
      !isFirstDayOfWeekOrEarlier &&
      !isLastDayOfWeekOrLater
    )
  }
  const isThisMonth = (someDate) => {
    const today = new Date()
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    )
    const isFirstDayOfMonthOrEarlier = someDate <= firstDayOfMonth
    const isLastDayOfMonthOrLater = someDate >= lastDayOfMonth
    const isThiWeek =
      isToday(someDate) || isYesterday(someDate) || isThisWeek(someDate)
    return !isThiWeek && !isFirstDayOfMonthOrEarlier && !isLastDayOfMonthOrLater
  }
  const isEarlierThisYear = (someDate) => {
    const today = new Date()
    const firstDayOfYear = new Date(today.getFullYear(), 0, 1)
    const lastDayOfYear = new Date(today.getFullYear(), 11, 31)
    return (
      someDate >= firstDayOfYear &&
      someDate <= lastDayOfYear &&
      !isThisMonth(someDate)
    )
  }

  return (
    <>
      <div className={style.container}>
        <>
          <p className={style.title}>Feed</p>
          <div className={style.filterContainer}>
            {filters.map((filter, index) => (
              <div
                className={`${style.filter} ${
                  selectedFilters.includes(filter) ? style.selected : ''
                }`}
                key={index}
                onClick={() => {filter === 'All' ? handleSelect('All') : handleSelect(filter)}}
              >
                {filter}
              </div>
            ))}
          </div>
          <div
            onClick={() => setOpenFilter(!openFilter)}
            className={style.mobileFilters}
          >
            {openFilter ? '' : <p className={style.filterTitle}>Filter</p>}
            {selectedFilters.length > 0 && !selectedFilters.includes('All') ? (
              <div className={style.filterBubble}>{selectedFilters.length}</div>
            ) : (
              null
            )}
            <FilterIcon />
          </div>
          {openFilter && (
            <div className={style.filterContainerMobile}>
              {filters.map((filter, index) => (
                <div
                  className={`${style.filter} ${
                    selectedFilters.includes(filter) ? style.selected : ''
                  }`}
                  key={index}
                  onClick={() => {filter === 'All' ? handleSelect('All') : handleSelect(filter)}}
                >
                  {filter}
                </div>
              ))}
            </div>
          )}
        </>
        <div className={style.cardsContainer}>
          {feed.some((item) => isToday(new Date(item.datePosted))) && (
            <div className={style.filteredFeed}>
              <p className={style.timetext}>Today:</p>
              {feed
                .filter((item) => isToday(new Date(item.datePosted)))
                .map(
                  (feedItem, index) =>
                    isFiltered(feedItem) && (
                      <div
                        key={index}
                        onDrop={(e) => droppableProps.onDrop(e, index)}
                        onDragOver={droppableProps.onDragOver}
                      >
                        <FeedCard
                          feedItem={feedItem}
                          index={index}
                        />
                      </div>
                    )
                )}
            </div>
          )}
          {feed.some((item) => isYesterday(new Date(item.datePosted))) && (
            <div className={style.filteredFeed}>
              <p className={style.timetext}>Yesterday:</p>
              {feed
                .filter((item) => isYesterday(new Date(item.datePosted)))
                .map(
                  (feedItem, index) =>
                    isFiltered(feedItem) && (
                      <div
                        key={index}
                        onDrop={(e) => droppableProps.onDrop(e, index)}
                        onDragOver={droppableProps.onDragOver}
                      >
                        <FeedCard
                          feedItem={feedItem}
                          index={index}
                        />
                      </div>
                    )
                )}
            </div>
          )}
          {feed.some((item) => isThisWeek(new Date(item.datePosted))) && (
            <div className={style.filteredFeed}>
              <p className={style.timetext}>This Week:</p>
              {feed
                .filter((item) => isThisWeek(new Date(item.datePosted)))
                .map(
                  (feedItem, index) =>
                    isFiltered(feedItem) && (
                      <div
                        key={index}
                        onDrop={(e) => droppableProps.onDrop(e, index)}
                        onDragOver={droppableProps.onDragOver}
                      >
                        <FeedCard
                          feedItem={feedItem}
                          index={index}
                        />
                      </div>
                    )
                )}
            </div>
          )}
          {feed.some((item) => isThisMonth(new Date(item.datePosted))) && (
            <div className={style.filteredFeed}>
              <p className={style.timetext}>This Month:</p>
              {feed
                .filter((item) => isThisMonth(new Date(item.datePosted)))
                .map(
                  (feedItem, index) =>
                    isFiltered(feedItem) && (
                      <div
                        key={index}
                        onDrop={(e) => droppableProps.onDrop(e, index)}
                        onDragOver={droppableProps.onDragOver}
                      >
                        <FeedCard
                          feedItem={feedItem}
                          index={index}
                        />
                      </div>
                    )
                )}
            </div>
          )}
          {feed.some((item) =>
            isEarlierThisYear(new Date(item.datePosted))
          ) && (
            <div className={style.filteredFeed}>
              <p className={style.timetext}>Earlier This Year:</p>
              {feed
                .filter((item) => isEarlierThisYear(new Date(item.datePosted)))
                .map(
                  (feedItem, index) =>
                    isFiltered(feedItem) && (
                      <div
                        key={index}
                        onDrop={(e) => droppableProps.onDrop(e, index)}
                        onDragOver={droppableProps.onDragOver}
                      >
                        <FeedCard
                          feedItem={feedItem}
                          index={index}
                        />
                      </div>
                    )
                )}
            </div>
          )}
        </div>
        <p className={style.scrollText}>Scroll to see more</p>
      </div>
    </>
  );
}

export default Feed
