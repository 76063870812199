import React from "react";

function ChatIcon({color}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        stroke={color || "#fff"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M25.768 7.704c.899 1.345.899 3.217.899 6.963 0 3.745 0 5.618-.9 6.963a5.336 5.336 0 01-1.47 1.471c-1.176.785-2.753.885-5.63.897V24l-1.474 2.948c-.492.983-1.894.983-2.386 0L13.333 24v-.002c-2.877-.012-4.454-.112-5.63-.897a5.335 5.335 0 01-1.47-1.471c-.9-1.345-.9-3.218-.9-6.963 0-3.746 0-5.618.9-6.963.388-.583.888-1.083 1.47-1.472 1.346-.899 3.218-.899 6.964-.899h2.666c3.746 0 5.618 0 6.963.9.583.388 1.083.888 1.472 1.47zM12 12h8M12 17.333h4"
      ></path>
    </svg>
  );
}

export default ChatIcon;
