import style from './Profile.module.css'
import ProfileHeader from './ProfileHeader'
import StatisticsCard from './StatisticsCard'
import ConnectionsChoices from './ConnectionsChoices'
import EventStatistics from './EventStatistics'
import { useState } from 'react'
import useWindowHeight from '../../../../hooks/useWindowHeight'
import { useUserProfileContext } from '../../../../contexts/UserProfileContext'
import { PersonalChartsData } from '../../../../dummyData'
const Profile = ({ user, selectedProfile, setSelectedProfile }) => {
  const [openCard, setOpenCard] = useState()
  const [startIndex, setStartIndex] = useState(0)
  const {selectProfile} = useUserProfileContext()

  const profileToggle = (value) => {
selectProfile(value)
  }

  const cardToggle = (value) => {
    if (openCard === value) {
      setOpenCard(null)
    } else {
      setOpenCard(value)
    }
  }

  const innerHeight = useWindowHeight()
  const cardDataPersonal = [
    { title: 'events attended', number: '20' },
    { title: 'conversations', number: '300' },
    { title: 'connections', number: '150' },
    { title: 'interactions', number: '25' }
  ]

  const cardDataMentor = [
    { title: 'events organized', number: '20' },
    { title: 'impressions', number: '300' },
    { title: 'attendees', number: '150' },
    { title: 'new visitors', number: '25' }
  ]



  const totalProducts = 3

  const nextSlide = () => {
    setStartIndex((startIndex + 1) % totalProducts)
  }

  const prevSlide = () => {
    setStartIndex((startIndex - 1 + totalProducts) % totalProducts)
  }

  return (
    <div className={style.container}>
      <ProfileHeader
        user={user}
        selectedProfile={selectedProfile}
        profileToggle={profileToggle}
      />
      {selectedProfile === 'Personal' ? (
        <>
          {innerHeight > 561 && <StatisticsCard cardData={cardDataPersonal} />}
          <ConnectionsChoices
            data={PersonalChartsData}
            openCard={openCard}
            cardToggle={cardToggle}
          />
        </>
      ) : (
        <>
          <StatisticsCard cardData={cardDataMentor} />
          <EventStatistics
            startIndex={startIndex}
            nextSlide={nextSlide}
            prevSlide={prevSlide}
          />
        </>
      )}
    </div>
  )
}

export default Profile
