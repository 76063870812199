import React, { createContext, useContext, useEffect, useState } from 'react'

// Create the AuthContext to hold authentication state and functions
const AuthContext = createContext()

// Custom hook to use the AuthContext
export const useAuthContext = () => useContext(AuthContext)

// AuthProvider component to provide the AuthContext to its children
export const AuthProvider = ({ children }) => {
  // State to track if the user is authenticated
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  // useEffect to check for the token cookie when the component mounts
  useEffect(() => {
    const token = getCookie('token');

    // If a token is found, set isAuthenticated to true
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  // Function to handle user login
  const login = (responseData) => {
    // Set the token cookie and update authentication state
    setCookie('token', responseData.token, 1); 
    setIsAuthenticated(true);
  };

  // Function to set a cookie with optional expiration days
  function setCookie(name, value, days) {
    var expires = '';
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    // Set the cookie with SameSite and Secure attributes for security
    document.cookie = name + '=' + (value || '') + expires + '; path=/; SameSite=Lax; Secure';
  }

  // Function to delete a cookie
  function deleteCookie(name) {
    // Set the cookie's expiration date to a past date to delete it
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  // Function to handle user logout
  const logout = () => {
    // Delete the token cookie and update authentication state
    deleteCookie('token');
    setIsAuthenticated(false);
  };

  // Function to get a cookie by name
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  // Provide the authentication state and functions to children components
  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, setIsAuthenticated ,getCookie}}>
      {children}
    </AuthContext.Provider>
  )
}
