import React from "react";

function URLIcon({color}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
    >
      <path
        stroke={color||"#65677D"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 16.5H7a4 4 0 01-4-4v0a4 4 0 014-4h3M16 12.5H8M14 16.5h3a4 4 0 004-4v0a4 4 0 00-4-4h-3"
      ></path>
    </svg>
  );
}

export default URLIcon;
