import React, { createContext, useState } from 'react';
import { useAuthContext } from './AuthContext';

// Create the GuestContext to hold guest-related state and functions
const GuestContext = createContext();

// GuestContextProvider component to provide the GuestContext to its children
export const GuestContextProvider = ({ children }) => {
  // Use the AuthContext to determine if the user is authenticated
  const { isAuthenticated } = useAuthContext();
  // State to track if the user is a guest (not authenticated)
  const [guestUser, setGuestUser] = useState(!isAuthenticated);
  // State to manage whether guest information modal is open
  const [openGuestInfo, setOpenGuestInfo] = useState(false);

  // Provide the guest-related state and functions to children components
  return (
    <GuestContext.Provider value={{ guestUser, setGuestUser, openGuestInfo, setOpenGuestInfo }}>
      {children}
    </GuestContext.Provider>
  );
};

export default GuestContext;
