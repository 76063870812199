import React from 'react'

function ArrowDownIcon({ color,width,height }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width||"25"}
      height={height||"25"}
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M6.5 9.88892L12.5 15.8889L18.5 9.88892"
        stroke={color ||"white"}
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default ArrowDownIcon
