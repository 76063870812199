import ForgotPassword from '../components/home/forgotPassword/ForgotPassword'
import Login from '../components/home/login/Login'
import Register from '../components/home/register/Register'

const AuthModal = ({ handleLogin, openReg, openAuth, setOpenAuth }) => {
  return (
    <>
      {openReg && openAuth === 'register' ? (
        <Register handleLogin={handleLogin} setOpenAuth={setOpenAuth} />
      ) : openAuth === 'login' ? (
        <Login handleLogin={handleLogin} setOpenAuth={setOpenAuth} />
      ) : (
        <ForgotPassword handleLogin={handleLogin} setOpenAuth={setOpenAuth} />
      )}
    </>
  )
}
export default AuthModal
