import { useEffect, useRef, useState } from 'react'
import style from './Register.module.css'
import ErrorIcon from '../../../assets/svgs/Error'
import { useNavigate } from 'react-router-dom'
import ShowPassIcon from '../../../assets/svgs/ShowPass'
import PasswordCheckIcon from '../../../assets/svgs/PasswordCheck'
import checkPasswordValidation from '../../../utils/PasswordValidation'
const EnterpriseForm = ({ setOpenAuth }) => {
  const [error, setError] = useState(false)
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    enterpriseName: ''
  })
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [isPasswordFocused, setIsPasswordFocused] = useState(false)
  const [isConfirmPasswordFocused, setIsConfrimPasswordFocused] =
    useState(false)
  const [passwordValidationMessages, setPasswordValidationMessages] = useState({
    minLength: '',
    hasNumber: '',
    hasSpecialCharacter: ''
  })
  const url = process.env.REACT_APP_API_URL || '';
  const [
    confrimPasswordValidationMessages,
    setConfirmPasswordValidationMessages
  ] = useState({
    minLength: '',
    hasNumber: '',
    hasSpecialCharacter: ''
  })
  const passwordRef = useRef(null)
  const confirmPasswordRef = useRef(null)

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
    setError((prevError) => {
      const newError = { ...prevError }
      delete newError[name]
      return newError
    })
    if (name === 'password') {
      const passwordValidationMessages = checkPasswordValidation(value)
      setPasswordValidationMessages(passwordValidationMessages)
    } else if (name === 'confirmPassword') {
      const confirmPasswordValidationMessages = checkPasswordValidation(value)
      setConfirmPasswordValidationMessages(confirmPasswordValidationMessages)
    }

    setIsPasswordFocused(name === 'password' && value !== '')
    setIsConfrimPasswordFocused(name === 'confirmPassword' && value !== '')
  }
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (passwordRef.current && !passwordRef.current.contains(event.target)) {
        setIsPasswordFocused(false)
      }
      if (
        confirmPasswordRef.current &&
        !confirmPasswordRef.current.contains(event.target)
      ) {
        setIsConfrimPasswordFocused(false)
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])
  const handleSubmit = async (e) => {
    e.preventDefault()
    let hasError = false

    const requiredFields = [
      'firstName',
      'lastName',
      'email',
      'password',
      'confirmPassword',
      'enterpriseName'
    ]
    const errors = {}

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    requiredFields.forEach((fieldName) => {
      if (!formData[fieldName]) {
        errors[fieldName] = 'Please fill out all the fields'
        hasError = true
      } else if (
        fieldName === 'email' &&
        !emailRegex.test(formData[fieldName])
      ) {
        errors[fieldName] = 'Not a valid email'
        hasError = true
      }
    })

    if (formData['password'] && formData['confirmPassword']) {
      if (formData['password'] !== formData['confirmPassword']) {
        errors['password'] = 'Passwords do not match'
        errors['confirmPassword'] = 'Passwords do not match'
        hasError = true
      }
    }
    const passwordValidationResult = checkPasswordValidation(
      formData['password']
    )
    const confirmPasswordValidationResult = checkPasswordValidation(
      formData['confirmPassword']
    )
    if (!errors['password'] || !errors['confirmPassword']) {
      if (
        passwordValidationResult.hasError ||
        confirmPasswordValidationResult.hasError
      ) {
        errors['password'] = 'Password does not meet requirements'
        errors['confirmPassword'] = 'Password does not meet requirements'
        hasError = true
      }
    }

    if (hasError) {
      setError(errors)
      return
    }
    if (!hasError) {
      try {
        const response = await fetch(`${url}/users/create`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        })

        if (response.ok) {
          setOpenAuth('login')
        } else {
          const errorResponseData = await response.json()
          errors.email = errorResponseData.errors
          setError(errors)
          throw new Error('Network response was not ok')
        }
      } catch (error) {
        console.error('Error submitting form:', error)
      }
    }
  }

  return (
    <div className={style.personalFormContainer}>
      <form className={style.form} onSubmit={handleSubmit}>
        <div className={style.inputContainerSub}>
          {error && error.enterpriseName !== undefined ? (
            <div className={style.errorIconContainerEnterprise}>
              <ErrorIcon />
            </div>
          ) : null}

          <input
            type="text"
            name="enterpriseName"
            value={formData.enterpriseName}
            onChange={handleInputChange}
            className={`${style.enterpriseInput} ${
              error.enterpriseName
                ? style.inputFieldError
                : style.inputFieldEnterprise
            }`}
            placeholder="Enterprise name"
          />
          <p className={style.errorMessage}>{error.enterpriseName}</p>
        </div>
        <div className={style.inputContainerDubble}>
          <div className={style.inputContainerSub}>
            {error && error.firstName !== undefined ? (
              <div className={style.errorIconContainer}>
                <ErrorIcon />
              </div>
            ) : null}

            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              className={
                error.firstName ? style.inputFieldError : style.inputField
              }
              placeholder="First name"
            />
            <p className={style.errorMessage}>{error.firstName}</p>
          </div>
          <div className={style.inputContainerSub}>
            {error && error.lastName !== undefined ? (
              <div className={style.errorIconContainer}>
                <ErrorIcon />
              </div>
            ) : null}

            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              className={
                error.lastName ? style.inputFieldError : style.inputField
              }
              placeholder="Last name"
            />
            <p className={style.errorMessage}>{error.lastName}</p>
          </div>
        </div>
        <div className={style.inputContainerSub}>
          {error && error.email !== undefined ? (
            <div className={style.errorIconContainer}>
              <ErrorIcon />
            </div>
          ) : null}

          <input
            type="text"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className={error.email ? style.inputFieldError : style.inputField}
            placeholder="Email"
          />
          <p className={style.errorMessage}>{error.email}</p>
        </div>
        <div className={style.inputContainerSub}>
          {error && error.password !== undefined ? (
            <div className={style.errorIconContainer}>
              <ErrorIcon />
            </div>
          ) : null}

          <input
            ref={passwordRef}
            type={showPassword ? 'text' : 'password'}
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            className={
              error.password ? style.inputFieldError : style.inputField
            }
            placeholder="Password"
          />
          <div
            className={`${style.passwordCheck} ${
              isPasswordFocused ? style.showPasswordCheck : ''
            }`}
          >
            {passwordValidationMessages.minLength}
            {passwordValidationMessages.hasNumber}
            {passwordValidationMessages.hasSpecialCharacter}
          </div>

          <div
            onClick={() => setShowPassword(!showPassword)}
            className={style.showPassword}
          >
            <ShowPassIcon show={showPassword ? true : false} />
          </div>

          <p className={style.errorMessage}>{error.password}</p>
        </div>
        <div className={style.inputContainerSub}>
          {error && error.confirmPassword !== undefined ? (
            <div className={style.errorIconContainer}>
              <ErrorIcon />
            </div>
          ) : null}

          <input
            ref={confirmPasswordRef}
            type={showConfirmPassword ? 'text' : 'password'}
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleInputChange}
            className={
              error.confirmPassword ? style.inputFieldError : style.inputField
            }
            placeholder="Confirm password"
          />

          <div
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            className={style.showPassword}
          >
            <ShowPassIcon show={showConfirmPassword ? true : false} />
          </div>

          <div
            className={`${style.passwordCheck} ${
              isConfirmPasswordFocused ? style.showPasswordCheck : ''
            }`}
          >
            {confrimPasswordValidationMessages.minLength}
            {confrimPasswordValidationMessages.hasNumber}
            {confrimPasswordValidationMessages.hasSpecialCharacter}
          </div>
          <p className={style.errorMessage}>{error.confirmPassword}</p>
        </div>
        <button className={style.button}>Sign up</button>
        <div className={style.divider}>
          <div className={style.hr}></div>
          <p className={style.dividerText}> Or</p>
        </div>
        <button
          className={style.mobileButton}
          onClick={(e) => {
            setOpenAuth('login')
          }}
        >
          Log in
        </button>
      </form>
    </div>
  )
}
export default EnterpriseForm
