
const validateLoginForm = (formData) => {
    const errors = {};
    let hasError = false;
  
    if (!formData.email) {
      errors.email = 'Please fill out all the fields';
      hasError = true;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = 'Not a valid email';
      hasError = true;
    }
  
    if (!formData.password) {
      errors.password = 'Please fill out all the fields';
      hasError = true;
    }
  
    return { errors, isValid: !hasError };
  };
  
  export default validateLoginForm;
  