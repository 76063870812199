/* Modal to display welcome page for guest user*/
import React from 'react'
import style from './Welcome.module.css'
import WelcomeIcon from '../assets/svgs/Welcome'
import Welcome2Icon from '../assets/svgs/Welcome2'
import Welcome3Icon from '../assets/svgs/Welcome3'
import Welcome4Icon from '../assets/svgs/Welcome4'
import Welcome5Icon from '../assets/svgs/Welcome5'
import Arrow1Icon from '../assets/svgs/Arrow1'
import Arrow2Icon from '../assets/svgs/Arrow2'
import Arrow3Icon from '../assets/svgs/Arrow3'
import CloseBubbleIcon from '../assets/svgs/CloseBubble'
import useWindowWidth from '../hooks/useWindowWidth'
import CloseIcon from '../assets/svgs/Close'
const WelcomeModal = ({
  openGuestInfo,
  setOpenGuestInfo,
  openReg,
  setOpenReg,
  setOpenAuth,
  openAuth,
  handleAuth
}) => {
  const innerWidth = useWindowWidth()
  const toggleModal = () => {
    setOpenGuestInfo(!openGuestInfo)
  }

  return (
    <>
      {innerWidth > 768 && openGuestInfo ? (
        <div className={style.welcomeContainer}>
          <div className={style.closeButton} onClick={toggleModal}>
         
            <CloseIcon  color={'#393D63'}/>
          </div>
          <div className={style.steps}>
            <p className={style.mainTitle}>Welcome to Chimp!</p>
            <div className={style.firstStep}>
              <p className={style.subTitle}>Setup your profile</p>
              <WelcomeIcon />
              <div className={style.firstStepArrow}>
                <Arrow1Icon />
              </div>
            </div>
            <div className={style.secondStep}>
              <p className={style.subTitle}>Get suggestions</p>
              <Welcome2Icon />
              <div className={style.secondStepAroow}>
                <Arrow2Icon />
              </div>
            </div>
            <div className={style.thirdStep}>
              <p className={style.subTitle}>Chat with Chimp</p>
              <Welcome3Icon />
              <div className={style.thirdStepArrow}>
                <Arrow3Icon />
              </div>
            </div>
            <div className={style.forthStep}>
              <p className={style.subTitle}>Execute roadmap</p>
              <Welcome4Icon />
            </div>
          </div>

          <div className={style.main}>
            <p className={style.mainImageTitle}>Achieve your goals!</p>
            <Welcome5Icon width={'100%'} />
            <button
              className={style.button}
              onClick={() => {
                setOpenGuestInfo(false)
                handleAuth(!openReg)
                setOpenAuth('register')
              }}
            >
              Get Started{' '}
            </button>
          </div>
        </div>
      ) : (
        <div className={style.welcomeContainerMobile}>
          <div className={style.closeButtonMobile} onClick={toggleModal}>
            <CloseBubbleIcon
              color={'#393D63'}
              bubble={false}
              width={'50px'}
              height={'50px'}
            />
          </div>
          <div className={style.stepsMobile}>
            <p className={style.mainTitleMobile}>Welcome to Chimp!</p>
            <div className={style.firstStepMobile}>
              <p className={style.subTitleMobile}>Setup your profile</p>
              <WelcomeIcon width={'300px'} height={'300px'} />
              <div className={style.firstStepArrowMobile}>
                <Arrow1Icon height={'50px'} />
              </div>
            </div>
            <div className={style.secondStepMobile}>
              <p className={style.subTitleMobile}>Get suggestions</p>
              <Welcome2Icon width={'300px'} height={'300px'} />
              <div className={style.secondStepArrowMobile}>
                <Arrow2Icon height={'50px'} />
              </div>
            </div>
            <div className={style.thirdStepMobile}>
              <p className={style.subTitleMobile}>Chat with Chimp</p>
              <Welcome3Icon width={'300px'} height={'300px'} />
              <div className={style.thirdStepArrowMobile}>
                <Arrow3Icon height={'50px'} />
              </div>
            </div>
            <div className={style.forthStepMobile}>
              <p className={style.subTitleMobile}>Execute roadmap</p>
              <Welcome4Icon width={'300px'} height={'300px'} />
            </div>
          </div>

          <div className={style.mainMobile}>
            <p className={style.mainImageTitleMobile}>Achieve your goals!</p>
            <Welcome5Icon width={'100%'} />
            <button
              className={style.buttonMobile}
              onClick={() => {
                setOpenGuestInfo(false)
                handleAuth(!openReg)
                setOpenAuth('register')
              }}
            >
              Get Started{' '}
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default WelcomeModal
