import style from './Profile.module.css'

const ConnectionsChoices = ({ data, openCard, cardToggle,noTitle }) => {
  return (
    <div className={style.choicesContainer}>
      {!noTitle &&<p className={style.choicesTitle}>Your connections' choices</p> }
      <div className={style.cardsContainer}>
        {data.map((content, index) => (
          <div
            className={`${style.singleCard} ${
              openCard === index ? style.open : style.closed
            }`}
            key={index}
            onClick={() => cardToggle(index)}
          >
            <div>
              <span className={style.highlighted}>{content.number}</span>{' '}
              {content.content}
            </div>
            <button
              className={`${style.button} ${
                openCard === index ? style.show : style.noShow
              }`}
            >
              See event details
            </button>
          </div>
        ))}
      </div>
    {!noTitle && <p className={style.scroll}>Scroll to see more</p>} 
    </div>
  )
}

export default ConnectionsChoices
