import style from './Features.module.css'
import image from '../../../assets/images/WhatIsChimp.png'
import LayersIcon from '../../../assets/svgs/Layers'
import SettingsIcon from '../../../assets/svgs/Settings'
import UserIcon from '../../../assets/svgs/User'
import ChatIcon from '../../../assets/svgs/Chat'
import mobileImage from '../../../assets/images/laptop.png'
const Features = () => {
  return (
    <div className={style.mainContainer}>
      <div className={style.container}>
        <p className={style.title}>What is Chimp</p>
        <img src={image} className={style.image} alt="placeholder" />
        <img src={mobileImage} className={style.mobileImage} alt="placeholder" />
        <div className={style.cards}>
          <div className={style.cardsContainer}>
            {' '}
            <div className={style.singleCard}>
              <div className={style.cardContent}>
                <div className={style.iconContainer}>
                  <LayersIcon color={'#393D63'} />
                </div>
                <div className={style.iconContainerMobile}>
                  <LayersIcon color={'#393D63'} />
                </div>
                <p className={style.cardTitle}>Professional Platform</p>
              </div>

              <p className={style.cardText}>
                Build your network and career with our innovative professional
                platform.
              </p>
            </div>
            <div className={style.singleCard}>
              <div className={style.cardContent}>
                {' '}
                <div className={style.iconContainer}>
                  <SettingsIcon color={'#393D63'} />
                </div>
                <div className={style.iconContainerMobile}>
                  <SettingsIcon color={'#393D63'} />
                </div>
                <p className={style.cardTitle}>Customizable Profile</p>
              </div>

              <p className={style.cardText}>
                Tailor your profile, refine your career with our personalized
                guidance system.
              </p>
            </div>
          </div>
          <div className={style.cardsContainer}>
            <div className={style.singleCard}>
              <div className={style.cardContent}>
                {' '}
                <div className={style.iconContainer}>
                  <UserIcon color={'#393D63'} />
                </div>
                <div className={style.iconContainerMobile}>
                  <UserIcon color={'#393D63'} />
                </div>
                <p className={style.cardTitle}>Personalized Insights</p>
              </div>

              <p className={style.cardText}>
                Unlock your growth potential with personalized insights for
                career success.
              </p>
            </div>
            <div className={style.singleCard}>
              <div className={style.cardContent}>
                {' '}
                <div className={style.iconContainer}>
                  <ChatIcon color={'#393D63'} />
                </div>
                <div className={style.iconContainerMobile}>
                  <ChatIcon color={'#393D63'} />
                </div>
                <p className={style.cardTitle}>AI Chatbot</p>{' '}
              </div>

              <p className={style.cardText}>
                Get personalized career guidance from our AI roadmap assistant.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Features
