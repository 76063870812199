import React from "react";

function DeclineIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#65677D"
        strokeLinecap="round"
        strokeWidth="2"
        d="M12 21a9 9 0 110-18 9 9 0 010 18h0zM9 9l6 6M15 9l-6 6"
      ></path>
    </svg>
  );
}

export default DeclineIcon;
