import TimeInput from './timeInput'
import ArrowUpIcon from '../../../../assets/svgs/ArrowUp'
import CheckMark2Icon from '../../../../assets/svgs/CheckMark2'
import ErrorIcon from '../../../../assets/svgs/Error'
import EventIcon from '../../../../assets/svgs/EventDB'
import LocationIcon from '../../../../assets/svgs/Location'
import PlusCircleIcon from '../../../../assets/svgs/PlusCircle'
import URLIcon from '../../../../assets/svgs/UrlIcon'
import style from './NewEvent.module.css'
import TimeIcon from '../../../../assets/svgs/Time'
import { forwardRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import AutoFillAddress from './AutoFillAddress'

import MapDisplay from '../../../../utils/GoogleMap'
const LocationAndDate = ({
  errors,
  openLocation,
  setFormData,
  setErrors,
  setToggleButton,
  toggleButton,
  formData,
  handleFormData,
  setOpenLocation
}) => {
  const [startDate, setStartDate] = useState(new Date())
  const [location, setlocation] = useState({
    lat: '',
    lng: ''
  })
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div
      className={`${style.dateInputContainer} ${
        errors.date ? style.error : null
      }`}
      style={{ zIndex: '10' }}
    >
      <EventIcon color={errors.date ? 'red' : null} />
      <button className={style.example} onClick={onClick} ref={ref}>
        {value}
      </button>
    </div>
  ))
  const handleDateChange = (date) => {
    setStartDate(date)
    setFormData((prevFormData) => ({
      ...prevFormData,
      date: date
    }))
    setErrors((prev) => ({ ...prev, date: false }))
  }
  const handleStartTimeChange = (time) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      startTime: time
    }))
    setErrors((prev) => ({ ...prev, startTime: false }))
  }
  const handleEndTimeChange = (time) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      endTime: time
    }))
    setErrors((prev) => ({ ...prev, endTime: false }))
  }
  const toggleButtons = (value) => {
    setToggleButton(value)
  }
  const toggleLocation = () => {
    setOpenLocation(!openLocation)
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString)
    return date.toDateString()
  }
  const formattedDate = formatDate(formData.date)

  const getAmPm = (time) => {
    const [hour] = time.split(':')
    return hour >= 12 ? 'PM' : 'AM'
  }
  return (
    <div
      className={`${style.locationContainer} ${
        openLocation ? style.expandedLocation : null
      }`}
    >
      {openLocation ? (
        <>
          <p className={style.title}>Date and Location</p>
          <div className={style.datesContainer}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px'
              }}
            >
              <p className={style.subTitle}>Date</p>
              <div style={{ position: 'relative' }}>
                {errors.date && openLocation && (
                  <div
                    className={style.errorContainer}
                    style={{ zIndex: '10', top: '15px' }}
                  >
                    <ErrorIcon />
                  </div>
                )}
                <DatePicker
                  selected={startDate}
                  onChange={handleDateChange}
                  customInput={<ExampleCustomInput />}
                />
              </div>{' '}
            </div>
            <div style={{ display: 'flex', gap: '20px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                  width: '100%'
                }}
              >
                <p className={style.subTitle}>Start time</p>
                <div
                  className={`${style.timeInputContainer} ${
                    errors.startTime ? style.error : null
                  }`}
                >
                  {errors.startTime && openLocation && (
                    <div
                      className={style.errorContainer}
                      style={{ zIndex: '10', top: '15px', right: '8px' }}
                    >
                      <ErrorIcon />
                    </div>
                  )}
                  <TimeIcon color={errors.startTime ? 'red' : null} />
                  <TimeInput
                    name="startTime"
                    initTime="00:00"
                    className={style.timeInput}
                    value={formData.startTime}
                    onTimeChange={handleStartTimeChange}
                  />
                </div>
              </div>{' '}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                  width: '100%'
                }}
              >
                <p className={style.subTitle}>End time</p>
                <div
                  className={`${style.timeInputContainer} ${
                    errors.endTime ? style.error : null
                  }`}
                >
                  {errors.endTime && openLocation && (
                    <div
                      className={style.errorContainer}
                      style={{ zIndex: '10', top: '15px', right: '8px' }}
                    >
                      <ErrorIcon />
                    </div>
                  )}
                  <TimeIcon color={errors.endTime ? 'red' : null} />
                  <TimeInput
                    name="endTime"
                    initTime="00:00"
                    className={style.timeInput}
                    value={formData.endTime}
                    onTimeChange={handleEndTimeChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <p className={style.subTitle}>Location</p>
          <div className={style.buttonContainer}>
            <div
              className={`${style.button} ${
                toggleButton === 'venue' ? style.highlighted : null
              }`}
              onClick={() => toggleButtons('venue')}
            >
              Venue
            </div>
            <div
              className={`${style.button} ${
                toggleButton === 'online' ? style.highlighted : null
              }`}
              onClick={() => toggleButtons('online')}
            >
              Online Event
            </div>
          </div>
          {toggleButton === 'venue' ? (
            <div
              className={`${style.onlineContainer} ${
                errors.address && toggleButton === 'venue' ? style.error : null
              }`}
            >
              {errors.address && openLocation && (
                <div
                  className={style.errorContainer}
                  style={{ zIndex: '10', top: '15px', right: '8px' }}
                >
                  <ErrorIcon />
                </div>
              )}
              <LocationIcon color={errors.address ? 'red' : 'black'} />
              <div style={{ position: 'relative', width: '100%' }}>
                <AutoFillAddress
                  formData={formData}
                  handleFormData={handleFormData}
                  setlocation={setlocation}
                />
              </div>
            </div>
          ) : (
            <div
              className={`${style.onlineContainer} ${
                errors.onlineUrl && toggleButton === 'online'
                  ? style.error
                  : null
              }`}
            >
              {errors.onlineUrl && openLocation && (
                <div
                  className={style.errorContainer}
                  style={{ zIndex: '10', top: '15px', right: '8px' }}
                >
                  <ErrorIcon />
                </div>
              )}
              <URLIcon color={errors.onlineUrl ? 'red' : null} />
              <input
                className={style.urlInput}
                type="text"
                name="onlineUrl"
                value={formData.onlineUrl}
                onChange={handleFormData}
                placeholder="Link"
              />
            </div>
          )}
        </>
      ) : (
        <>
          <p className={style.title}>Date and Location</p>
          {formData.startTime === '' &&
          formData.endTime === '' &&
          (formData.address === '' || formData.onlineUrl === '') ? (
            <p className={style.subtext}>
              Use this section to specify where and when your event will take
              place.
            </p>
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <div className={style.displayedDate}>
                  {' '}
                  <EventIcon /> {formattedDate} {formData.startTime}{' '}
                  {getAmPm(formData.startTime)} - {formData.endTime}{' '}
                  {getAmPm(formData.endTime)}
                </div>
                <div className={style.displayedDate}>
                  {' '}
                  {formData.address !== '' ? (
                    <LocationIcon color={'black'} />
                  ) : (
                    <URLIcon />
                  )}
                  {formData.address === '' ? (
                    <a
                      href={formData.onlineUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Online
                    </a>
                  ) : (
                    formData.address
                  )}
                </div>
              </div>
              {formData.address !== '' && (
                <MapDisplay
                  latitude={location.lat}
                  longitude={location.lng}
                  width={'100%'}
                  height={'180px'}
                  borderRadius={'10px'}
                />
              )}{' '}
            </>
          )}
        </>
      )}

      <div
        className={`${style.plus} ${
          formData.startTime !== '' &&
          formData.endTime !== '' &&
          formData.date !== '' &&
          (formData.onlineUrl !== '' || formData.address) &&
          !openLocation
            ? style.green
            : null
        }`}
        onClick={toggleLocation}
      >
        {openLocation ? (
          <ArrowUpIcon color={'black'} stroke={'2'} />
        ) : formData.startTime !== '' &&
          formData.endTime !== '' &&
          formData.date !== '' &&
          (formData.onlineUrl !== '' || formData.address) &&
          !openLocation ? (
          <CheckMark2Icon />
        ) : (
          <PlusCircleIcon />
        )}
      </div>
    </div>
  )
}
export default LocationAndDate
