import { useEffect, useState } from 'react'
import style from './ConnectionTabMobile.module.css'
import { useNavigate } from 'react-router-dom'
import FilterIcon from '../../../../assets/svgs/Filter'
import FeedCard from '../../feed/FeedCard'
import { EventData } from '../../../../dummyData'
const Events = ({ selectedProfile, setSelectedTab }) => {
  const navigate = useNavigate()
  const [openFilter, setOpenFilter] = useState(false)
  const [selectedFilter, setFilter] = useState('All')
  const filtersPersonal = ['All', 'Near Me', 'Upcomming', 'Online', 'Saved']
  const filtersMentor = ['All', 'Online', 'Upcomming', 'Over']
  const toggleFilter = (value) => {
    setFilter(value)
  }
  const toggleOpenFilter = () => {
    setOpenFilter(!openFilter)
  }
  
  return (
    <div className={style.eventsContainer}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <p className={style.eventTite}>
          {selectedProfile === 'Personal' ? 'Events' : 'Your Events'}
          {selectedProfile === 'Mentor' && (
            <div
              className={style.plus}
              onClick={() => {
                navigate('create-new-event')
                setSelectedTab('Connect')
              }}
            >
              +
            </div>
          )}
        </p>{' '}
        <div
          onClick={toggleOpenFilter}
          style={{ display: 'flex', alignItems: 'center', height: '20px' }}
        >
          <p className={style.filtertext}>Filter</p>
          <FilterIcon selected={openFilter} />
        </div>
      </div>

      {openFilter && (
        <div className={style.filtersWrapper}>
          <div className={style.filterContainer}>
            {selectedProfile === 'Personal'
              ? filtersPersonal.map((filter, index) => (
                  <div
                    key={index}
                    className={`${
                      selectedFilter === filter
                        ? style.selectedFilter
                        : style.filter
                    }`}
                    onClick={() => toggleFilter(filter)}
                  >
                    {filter}
                  </div>
                ))
              : filtersMentor.map((filter, index) => (
                  <div
                    key={index}
                    className={`${
                      selectedFilter === filter
                        ? style.selectedFilter
                        : style.filter
                    }`}
                    onClick={() => toggleFilter(filter)}
                  >
                    {filter}
                  </div>
                ))}
          </div>
        </div>
      )}
      <div className={style.cardsContainer}>
        {EventData.map((feedItem, index) => {
          return (
            <FeedCard
              key={index}
              feedItem={feedItem}
              index={index}
              events={true}
            />
          )
        })}
      </div>
    </div>
  )
}
export default Events
