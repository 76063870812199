import React from "react";

function SuccessfullIcon({width,height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width||"120"}
      height={height||"120"}
      fill="none"
      viewBox="0 0 120 120"
    >
      <path
        stroke="#61487C"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M102.857 56.081v3.943a42.854 42.854 0 01-57.71 40.177 42.856 42.856 0 1132.296-79.348m25.414 4.886L60 68.639 47.143 55.782"
      ></path>
    </svg>
  );
}

export default SuccessfullIcon;
