import React from "react";

function BagIcon({stroke, width,height, color}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width||"24"}
      height={height||"24"}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={color||"#292929"}
        strokeWidth={stroke||"1"}
        strokeLinecap="round"
        d="M8 12V8a4 4 0 014-4v0a4 4 0 014 4v4"
      ></path>
      <path
      strokeWidth={stroke||"1"}
        stroke={color||"#292929"}
        d="M3.694 12.668c.145-1.741.218-2.611.792-3.14C5.06 9 5.934 9 7.681 9h8.639c1.746 0 2.62 0 3.194.528.574.528.647 1.399.792 3.14l.514 6.166c.084 1.013.126 1.52-.17 1.843-.298.323-.806.323-1.824.323H5.174c-1.017 0-1.526 0-1.823-.323-.297-.323-.255-.83-.17-1.843l.513-6.166z"
      ></path>
    </svg>
  );
}

export default BagIcon;
