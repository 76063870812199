import style from './Feed.module.css'
import FeedCard from './FeedCard'
import { FeedData } from '../../../dummyData'
const FeedSmall = () => {
 
  return (
    <div className={style.feedContainer}>
      <p className={style.title}>Feed</p>
      <div className={style.feeds}>
        {FeedData.map((feedItem, index) => (
          <FeedCard
            key={index}
            feedItem={feedItem}
            index={feedItem.id}
            chatTab={true}
          />
        ))}
      </div>
      <p className={style.scrollText}>Scroll to see more</p>
    </div>
  )
}
export default FeedSmall
