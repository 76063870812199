import FileIcon from '../../../assets/svgs/FileIcon'
import style from './Files.module.css'
import FolderIcon from '../../../assets/svgs/Folder'
import { useEffect, useRef, useState } from 'react'
import { useFilesContext } from './../../../contexts/FilesContext'
import { UploadedFilesData } from '../../../dummyData'
const UploadModal = ({ setOpenUpload, openUpload }) => {
  const [uploaded, setUploaded] = useState(false)
  const [generated, setGenerated] = useState(false)
  const { files, setFiles } = useFilesContext()
  const ref = useRef(null)


  return (
    <>
      <div className={style.cover} onClick={() => setOpenUpload(false)}>
        {' '}
      </div>
      <div className={style.modalContainer} ref={ref}>
        {!uploaded && !generated && (
          <div className={style.optionsContainer}>
            <div
              className={style.singleModalCard}
              onClick={() => setUploaded(!uploaded)}
            >
              <div className={`${style.modalIcon}`}>
                <FolderIcon />
              </div>
              <p className={style.singleModalCardTitle}>Uploaded Files</p>
            </div>
            <div
              className={style.singleModalCard}
              onClick={() => setGenerated(!generated)}
            >
              <div className={`${style.modalIcon}`}>
                <FileIcon color={'white'} height={'24'} width={'24'} />
              </div>
              <p className={style.singleModalCardTitle}>Chimp Generated</p>
            </div>
          </div>
        )}
        {uploaded && (
          <div className={style.cardsContainerMobile}>
            {UploadedFilesData.map((item, index) => {
              return (
                <div className={style.singleSmallCard} key={index}>
                  <div className={style.modalIconSmall}>
                    <FolderIcon />{' '}
                  </div>
                  <p className={style.smallCardTitle}>{item.title}</p>
                </div>
              )
            })}
          </div>
        )}
        {generated && (
          <div className={style.cardsContainerMobile}>
            {UploadedFilesData.map((item, index) => {
              return (
                <div className={style.singleSmallCard} key={index}>
                  <div className={style.modalIconSmall}>
                    <FileIcon color={'white'} />{' '}
                  </div>
                  <p className={style.smallCardTitle}>{item.title}</p>
                </div>
              )
            })}
          </div>
        )}
        <button
          className={style.modalButton}
          onClick={() => setOpenUpload(!openUpload)}
        >
          Cancel
        </button>
      </div>
    </>
  )
}

export default UploadModal
