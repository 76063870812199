import { useState, useEffect } from 'react';

// Custom hook to get the current window height
const useWindowHeight = () => {
  // State to store the window's inner height
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  useEffect(() => {
    // Function to update the window height state
    const handleResize = () => {
      setInnerHeight(window.innerHeight);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array means this effect runs once after initial render

  // Return the current window height
  return innerHeight;
};

export default useWindowHeight;
