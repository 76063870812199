import ReactQuill from 'react-quill'
import ArrowUpIcon from '../../../../assets/svgs/ArrowUp'
import CheckMark2Icon from '../../../../assets/svgs/CheckMark2'
import PlusCircleIcon from '../../../../assets/svgs/PlusCircle'
import style from './NewEvent.module.css'
import ErrorIcon from '../../../../assets/svgs/Error'
const Description = ({openDescription,setOpenDescription,errors,value,setValue,formData}) =>{
    const toggleDescription = () => {
        setOpenDescription(!openDescription)
      }
    return (
        <div
        className={`${style.descriptionContainer} ${
          openDescription ? style.expandedDescription : null
        }`}
      >
        {openDescription ? (
          <>
            <p className={style.title}>About this event</p>
            <p className={style.subtext}>
              Add more details about your event and include what people can
              expect if they attend.
            </p>
            <div
              className={`${style.editor} ${
                errors.description ? style.error : null
              }`}
            >
              {errors.description && openDescription && (
                <div
                  className={style.errorContainer}
                  style={{ zIndex: '10', top: '8px', right: '5px' }}
                >
                  <ErrorIcon />
                </div>
              )}
              <ReactQuill
                theme="snow"
                value={value}
                onChange={setValue}
                style={{ height: '150px' }}
              />
            </div>
          </>
        ) : (
          <>
            <p className={style.title}>About this event</p>
            {formData.description === '' ? (
              <p className={style.subtext}>
                Use this section to provide more details about your event.
                You can include things to know, venue information, parking,
                accessbility options-anything that will help people know
                what to expect.
              </p>
            ) : (
              <div
                dangerouslySetInnerHTML={{ __html: formData.description }}
              ></div>
            )}
          </>
        )}

        <div
          className={`${style.plus} ${
            formData.description !== '' && !openDescription
              ? style.green
              : null
          }`}
          onClick={toggleDescription}
        >
          {openDescription ? (
            <ArrowUpIcon color={'black'} stroke={'2'} />
          ) : formData.description !== '' && !openDescription ? (
            <CheckMark2Icon />
          ) : (
            <PlusCircleIcon />
          )}
        </div>
      </div>
    )
}
export default Description