import style from './Main.module.css'
import BagIcon from '../../../../assets/svgs/Bag'
import LocationIcon from '../../../../assets/svgs/Location2'
import AddUserIcon from '../../../../assets/svgs/AddUser'
import AcceptIcon from '../../../../assets/svgs/Accept'
import DeclineIcon from '../../../../assets/svgs/Decline'
import InfoIcon from '../../../../assets/svgs/InfoIcon'
import { useFilesContext } from '../../../../contexts/FilesContext'
const UserCard = ({ selectedTab, user, height }) => {
  const { addFile } = useFilesContext()
  const handleClickAdd = (event) => {
    event.stopPropagation()

    addFile(user)
  }
  return (
    <div className={style.userCardContainer}>
      <div className={style.imageContainer}>
        <img
          src={user.image}
          alt={user.firstName}
          className={style.userImage}
          style={{ height: height || '' }}
        />
      </div>
      <div className={style.userInfo}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <p className={style.userName}>
            {user.firstName} {user.lastName}
          </p>
          <p className={style.userType}>{user.type}</p>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            height: '30px'
          }}
        >
          <p className={style.userOccupation}>
            <BagIcon color={'black'} width={20} />
            {user.occupation}
          </p>
          <p className={style.userLocation}>
            <LocationIcon width={20} />
            {user.location}
          </p>
        </div>
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '150px',
              position: 'relative'
            }}
          >
            <div className={style.infoIcon}>
              <InfoIcon />
            </div>
            <div className={style.infoBubble}>
              <div className={style.bubbleAddon}></div>
              <p className={style.bubbleText}>Chimp Engagement Score</p>
              <p className={style.bubbleText}>Mentor Score</p>
            </div>
            <p className={style.score}>CES</p>
            <p className={style.score}>
              {user.CESscore}
              <span className={style.scoreHighlight}>/10</span>
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '150px',
              height: height === '80px' ? '10px' : height ? '0px' : ''
            }}
          >
            <p className={style.score}>MS</p>
            <p className={style.score}>
              {user.MSscore}
              <span className={style.scoreHighlight}>/10</span>
            </p>
          </div>
        </div>
        <div
          style={{ height: height === '140px' ? '20px' : height ? '0px' : '' }}
        >
          <p className={style.userDescription}>{user.description}</p>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          {selectedTab === 'Connect' ? (
            <div style={{ height: '20px' }}>
              <AddUserIcon />
            </div>
          ) : (
            <div style={{ display: 'flex', width: '50px', height: '20px' }}>
              <AcceptIcon /> <DeclineIcon />
            </div>
          )}
          <div style={{ display: 'flex', gap: '20px' }}>
            <button className={style.button1}>View profile</button>
            <button className={style.button2} onClick={handleClickAdd}>
              Add to chat
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default UserCard
