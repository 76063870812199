import CloseBubbleIcon from '../../../../assets/svgs/CloseBubble'
import EditIcon from '../../../../assets/svgs/EditIcon'
import PlusCircleIcon from '../../../../assets/svgs/PlusCircle'
import style from './MainProfile.module.css'
const Experience = ({ toggleModal, userProfile, owner,removeComponent ,editMode}) => {
  return (
    <>{userProfile.experience.length >0 &&
    
     <div className={style.experienceWrapper}>
      <div className={style.experienceActions}>
        <p className={style.title}>Experience</p>
        {owner &&editMode&& (
          <div style={{ display: 'flex' }}>
            <div className={style.plus}  onClick={()=>toggleModal('experience', userProfile, 'create')}>
              <PlusCircleIcon color={'white'} />
            </div>
            <div onClick={()=> removeComponent('2')}>
            <CloseBubbleIcon color="#393D63" height={20} width={20} />
          </div>
          </div>
        )}
      </div>
      <div className={style.cardsContainer}>
        {userProfile &&
          userProfile.experience.map((item, index) => {
            return (
              <div className={style.singleCard} key={index}>
            {owner && editMode&&<div
                  onClick={() => toggleModal('experience', item)}
                  className={style.edit}
                >
                  <EditIcon />
                </div>}
                
                <div className={style.cardImage}></div>
                <div className={style.cardInfo}>
                  <p className={style.cardTitle}>{item.title}</p>
                  <p className={style.cardCompany}>{item.company_name}</p>
                  <p className={style.cardSubtext}>
                    {item.start_date}-{item.end_date}
                  </p>
                  <p className={style.cardSubtext}>
                    {item.location}· {item.location_type}
                  </p>
                </div>
              </div>
            )
          })}
      </div>
    </div>
    }</>
   
  )
}
export default Experience
