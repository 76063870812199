import { createContext, useContext, useState } from "react";

// Create the FilesContext to hold file-related state and functions
const FilesContext = createContext();

// Custom hook to use the FilesContext
export const useFilesContext = () => useContext(FilesContext);

// FilesProvider component to provide the FilesContext to its children
export const FilesProvider = ({ children }) => {
  // State to hold a single file
  const [file, setFile] = useState();
  // State to hold an array of files
  const [files, setFiles] = useState([]);

  // Function to add a file to the files array
  const addFile = (fileInfo) => {
    // Check if the file already exists in the files array based on its id
    const existingFile = files.some((item) => fileInfo.id === item.id);
    // If the file doesn't exist, add it to the files array
    if (!existingFile) {
      setFiles([...files, fileInfo]);
    }
  };

  // Provide the file-related state and functions to children components
  return (
    <FilesContext.Provider value={{ files, setFiles, addFile, file, setFile }}>
      {children}
    </FilesContext.Provider>
  );
};
