import PlusIcon from '../../../assets/svgs/Plus'
import SendIcon from '../../../assets/svgs/Send'
import { useFilesContext } from '../../../contexts/FilesContext'
import style from './ChatInput.module.css'

const ChatInput = ({ openUpload, setOpenUpload, handleResponses, setFormData, formData = { message: '' } }) => {
  const { files } = useFilesContext()

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    handleResponses(formData.message)
    setFormData({ message: '' }) 
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e)
    }
  }

  const handleOpen = () => {
    setOpenUpload(!openUpload)
  }

  return (
    <div className={`${style.chatBigInputContainer}`}>
      <div onClick={handleOpen} className={style.mobilePlus}>
        <PlusIcon />
      </div>
      <input
        className={style.chatBiginput}
        placeholder="Ask Chimp"
        value={formData.message || ''}
        name="message"
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
      {files.length > 0 && <div className={style.counter}>{files.length}</div>}
      <button className={style.send} onClick={handleSubmit}>
        <SendIcon />
      </button>
    </div>
  )
}

export default ChatInput
