import style from './Summary.module.css'
const Summary = () => {
  return (
    <div className={style.container}>
      <p className={style.title}>Summary</p>
      <div className={style.cardsContainer}>
        <div className={style.card}>
          <p className={style.cardTitle}>Summary</p>
          <p className={style.cardText}>341</p>
          <p className={style.cardSubText}>conversations had with Chimp.</p>
        </div>
        <div className={style.card}>
          <p className={style.cardTitle}>Advancements</p>
          <p className={style.cardText}>73%</p>
          <p className={style.cardSubText}>done with ongoing skill developments.</p>
        </div>
        <div className={style.card}>
          <p className={style.cardTitle}>Networking</p>
          <p className={style.cardText}>22</p>
          <p className={style.cardSubText}>contacts made through Chimp.</p>
        </div>
      </div>
    </div>
  )
}
export default Summary
