import React from "react";

function PlusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path stroke="#432C5B" strokeLinecap="round" d="M12 6v12M18 12H6"></path>
    </svg>
  );
}

export default PlusIcon;
