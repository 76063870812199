import React from 'react';
import { LoadScript } from '@react-google-maps/api';

const libraries = ['places'];

const GoogleMapsProvider = ({ children }) => {
  return (
    <LoadScript googleMapsApiKey="AIzaSyAY_acUQDuW7rIT5I8v1jPksNvZm4kITLY" libraries={libraries}>
      {children}
    </LoadScript>
  );
};

export default GoogleMapsProvider;
