import React from "react";

function ExpandIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M10 15L15 10L10 5" stroke="#432C5B" stroke-width="2"/>
    <path d="M5 15L10 10L5 5" stroke="#432C5B" stroke-width="2"/>
    </svg>
  );
}

export default ExpandIcon;