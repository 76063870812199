import React from 'react'

function TwitterIconGrad() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className="twitter-grad-svg"
    >
      <path
        d="M14.03 9.81034L22.0445 0.951135L22.6687 0.961977L14.2533 10.412L13.9825 10.7161L14.2254 11.0428L23.0051 22.8506H17.154L11.1114 14.8256L10.7459 14.3401L10.3399 14.7923L2.92389 23.0529H2.34878L10.1468 14.2558L10.415 13.9533L10.1753 13.6277L0.988978 1.14949H6.84687L13.258 9.77322L13.6214 10.2621L14.03 9.81034ZM6.82998 1.65428L6.68003 1.4528H6.42888H2.6801H1.682L2.27965 2.25219L17.2775 22.313L17.4275 22.5136H17.678H21.3593H22.3547L21.7604 21.7151L6.82998 1.65428Z"
        fill="black"
        stroke="url(#paint0_linear_1688_5588)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1688_5588"
          x1="12"
          y1="0.447266"
          x2="12"
          y2="23.5529"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8EBC5" />
          <stop offset="0.33" stopColor="#FAD1CB" />
          <stop offset="0.67" stopColor="#EDC4E4" />
          <stop offset="1" stopColor="#DDCAF1" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default TwitterIconGrad
