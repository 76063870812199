import { useState } from 'react'
import style from './Main.module.css'
import UserCard from './UserCard'
import user1 from '../../../../assets/images/User1.png'
import user2 from '../../../../assets/images/User2.png'
import Slider from './Slider'
const Connections = () => {
  const [selectedTab, setSelectedTab] = useState('Connect')
  const [selectedFilter, setFilter] = useState('All')
  const filters = ['All', 'Mentor', 'Personal']
  const toggleTab = (value) => {
    setSelectedTab(value)
  }
  const toggleFilter = (value) => {
    setFilter(value)
  }
  

  return (
    <div className={style.connectionsContainer}>
      <div className={style.options}>
        <div
          className={`${
            selectedTab === 'Connect' ? style.option : style.notSelected
          }`}
          onClick={() => toggleTab('Connect')}
        >
          Connect
        </div>
        <div
          className={`${
            selectedTab === 'Requests' ? style.option : style.notSelected
          }`}
          onClick={() => toggleTab('Requests')}
        >
          Connect Requests
        </div>
      </div>
      <div className={style.filterContainer} style={{marginTop:'0',marginBottom:'10px'}}>
        <div style={{display:'flex',gap:'10px'}}>
              {filters.map((filter, index) => (
          <div
            key={index}
            className={`${
              selectedFilter === filter ? style.selectedFilter : style.filter
            }`}
            onClick={() => toggleFilter(filter)}
          >
            {filter}
          </div>
        ))}
        </div>
    
      </div>
      <div style={{display:'flex', width:'100%', justifyContent:'center',position:'relative',height:'100%'}}><Slider selectedTab={selectedTab}  selectedFilter={selectedFilter}/></div>
    </div>
  )
}
export default Connections
