import ErrorIcon from '../../../assets/svgs/Error'
import style from './UploadModal.module.css'
const CVDetails = ({
  openCVDetails,
  errors,
  handleFormData,
  formData,
  setOpenCVDetails
}) => {
  return (
    <div
      className={`${style.eventTitleContainer} ${
        openCVDetails ? style.expanded : style.closed
      }`}
    >
      <>
        <p className={style.openTitle}>CV Overview</p>

        <div className={style.formContainer}>
          <p className={style.text}>Summary</p>
          <p className={style.subtext}>
            Provide a brief summary about yourself
          </p>
          <div style={{ position: 'relative', width: '93%' }}>
            {errors.summary && openCVDetails && (
              <div className={style.errorContainer}>
                <ErrorIcon />
              </div>
            )}
            <textarea
              onChange={handleFormData}
              value={formData.summary}
              name="summary"
              maxLength={300}
              placeholder="Summary"
              className={`${style.textarea} ${
                errors.summary ? style.error : null
              }`}
            />
          </div>

          <p className={style.text}>Experience</p>
          <p className={style.subtext}>List your relevant experience.</p>
          <div style={{ position: 'relative', width: '93%' }}>
            {errors.experience && openCVDetails && (
              <div className={style.errorContainer}>
                <ErrorIcon />
              </div>
            )}
            <textarea
              onChange={handleFormData}
              value={formData.experience}
              name="experience"
              placeholder="Experience"
              className={`${style.textarea} ${
                errors.experience ? style.error : null
              }`}
            />
          </div>
        </div>
      </>
    </div>
  )
}

export default CVDetails
