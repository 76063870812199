import { useState, useEffect } from 'react';

// Custom hook to get the current window width
const useWindowWidth = () => {
  // State to store the window's inner width
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Function to update the window width state
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array means this effect runs once after initial render

  // Return the current window width
  return innerWidth;
};

export default useWindowWidth;
